import React from 'react';
import FilterBox from '../filter/FilterBox'
import { FilterStatic } from '../filter/FilterStatic'
import '../../styles/filter/filter.css'
import * as actions from "../../actions";
import {connect} from "react-redux";
import InputRangeBox from '../../shared/filter/InputRange';
import AutoCompileCustom from "../filter/AutoCompileCustom";
import FilterRadio from '../filter/FilterRadio.js'
import FilterHashtag from "../filter/FilterHashtag";

class Filter extends React.Component{

    constructor(props){
        super(props);
        this.autocompileLuogoRef = React.createRef();
        this.autocompileDebRef = React.createRef();
        this.gbvRef = React.createRef();
        this.hashtagRef = React.createRef();
    }

    componentWillMount() {
        this.props.dispatch(actions.fetchFiltriPortValues());
    }

    componentDidUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): void {
        this.autocompileLuogoRef.current.reset();
        this.autocompileDebRef.current.reset();
        this.props.getFiltroRange(this.gbvRef.current);
        this.props.getFiltroHashtag(this.hashtagRef.current);
    }



    render(){
        return(
            <div id='filterContainer'>
                <div className='headerFilter'>
                    <h4 className='countAll'>Filter portfolios</h4>
                    {window.innerWidth <= 1024?<span className='close' onClick={this.props.showFilter}></span>:''}
                </div>

                <div className='container'>

                    <InputRangeBox ref={this.gbvRef} id="gbvValues" filtro={this.props.filtriPortValues.gbvValues} label="Portfolio GBV" handleInputRange={this.props.handleInputRange}/>

                    <FilterRadio id="onlyMonoposizione" label="Portfolio" handleRadio={this.props.handleRadio}/>

                    <FilterHashtag ref={this.hashtagRef} id="hashtags" filtro={this.props.filtriPortValues.hashtags} label="Name Hashtag" handleHashtagSelected={this.props.handleHashtagSelected}/>

                    <FilterBox id="tipoDiCreditoValues" filtro={this.props.filtriPortValues.tipoDiCreditoValues} label="Debt category" handleCheckElement={this.props.handleCheckElement} handleSoloElement={this.props.handleSoloElement}/>

                    <FilterBox id="controparteValues" filtro={this.props.filtriPortValues.controparteValues} label="Borrower type" handleCheckElement={this.props.handleCheckElement} handleSoloElement={this.props.handleSoloElement}/>

                    <FilterBox id="caratteristicheAssetValues" filtro={this.props.filtriPortValues.caratteristicheAssets} label="Asset type" handleCheckElement={this.props.handleCheckElement} handleSoloElement={this.props.handleSoloElement}/>

                    <AutoCompileCustom ref={this.autocompileLuogoRef} label ='Asset location' id="luogoAsset" handleInputAutocompile={this.props.handleInputAutocompile} filtro={this.props.filtriPortValues.luogoAsset} />

                    <AutoCompileCustom ref={this.autocompileDebRef} label ='Borrower location' id="residenzaDebitori" handleInputAutocompile={this.props.handleInputAutocompile} filtro={this.props.filtriPortValues.residenzaDebitori}/>

                    <FilterStatic id="procedimentiInCorsoValues" label="Current procedures" handleCheckElement={this.props.handleCheckElement} handleSoloElement={this.props.handleSoloElement}/>
                </div>

                <div className='resetAll' onClick={this.props.resetAll}>
                    <h5>Clear All</h5>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state){
    return{
        filtriPortValues: state.filtriValues.dataPort
    }
}

export default connect(mapStateToProps)(Filter)
