import React from 'react'

class FilterRadio extends React.Component{

    collapseBOX(event){
        if(event.target.id === "freccia"){
            event.target.classList.toggle('active');
        }else{
            event.target.children.freccia.classList.toggle('active');
        }
    }

    render() {
        return(
            <div className='filterBox'>
                <div className='boxCollapse'>
                    <h5 className='label'>{this.props.label}</h5>
                    <a onClick={this.collapseBOX} className="filterCollapse" data-toggle="collapse" href={`#collapse${this.props.id}`} aria-expanded="false" aria-controls={`#collapse${this.props.id}`}>
                        <div id='freccia'/>
                    </a>
                </div>

                <div className="collapse show" id={`collapse${this.props.id}`}>
                    <div className='d-flex check'>
                        <div className="radioFilter">
                            <div id='all' className='item select' onClick={this.props.handleRadio}>All</div>
                            <div id='single' className='item ' onClick={this.props.handleRadio}>Single-name</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FilterRadio
