import React, {Component} from "react";
import '../../styles/components/carrello.css'
export class PreferitiHeader extends Component{

render() {
    return(
        <div className="row col-12" >
            <div className="cart favorites">Favorites ({this.props.count === undefined ? 0 : this.props.count})</div>
        </div>

    )
}
}
