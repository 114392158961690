import React from 'react';
import { Tabs } from './Tabs'
import Filter from '../../shared/components/Filter'
import * as actions from "../../actions";
import {connect} from "react-redux";
import {Count} from "../../shared/components/Count";

import '../../styles/search/mainSearch.css'
import {ReactComponent as ISlider} from "../../styles/svg/sliders-h.svg";

class Search extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            gbvValues: [],
            tipoDiCreditoValues: [],
            hashtag: '',
            controparteValues: [],
            caratteristicheAssetValues: [],
            luogoAsset: '',
            residenzaDebitori: '',
            procedimentiInCorsoValues: [],
            sortBy: 'Date',
            sortOrder: 'Desc',
            onlyMonoposizione: false,
            counter:1,
        }
        this.resetFiltroRange = this.resetFiltroRange.bind(this)
    }


    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.location.pathname === this.props.location.pathname){
            const tab = nextProps.location.pathname.split("/");
            if(tab.includes("portfolios")){
                this.props.dispatch(actions.fetchPortafogliFiltrati(nextState,this.state.counter));
            }else if(tab.includes("positions")) {
                this.props.dispatch(actions.fetchPortafogliFiltrati(nextState,this.state.counter));
                this.props.dispatch(actions.fetchPosizioniFiltrati(nextState));
            }else if(tab.includes("map")){
                this.props.dispatch(actions.fetchPortafogliFiltrati(nextState,this.state.counter));
                // this.props.dispatch(actions.fetchMapFiltrati(nextState));
            }else if(tab.includes("strats")){
                this.props.dispatch(actions.fetchPortafogliFiltrati(nextState,this.state.counter));
                this.props.dispatch(actions.fetchPortafoglioStrats(nextState));
                this.props.dispatch(actions.fetchPortafoglioControparte(nextState));
                this.props.dispatch(actions.fetchPortafoglioDestinazioneUso(nextState));
                this.props.dispatch(actions.fetchPortafoglioAreaGeografica(nextState));
            }else{

            }
        }
    }

    handleInputRange = (value) =>{

        const values = [parseFloat(value.min), parseFloat(value.max)];
        this.setState({gbvValues: values, counter: this.state.counter+1});
    }

    handleRadio = (event) =>{
        document.getElementById('all').classList.toggle('select');
        document.getElementById('single').classList.toggle('select');
        if(event.target.id === 'single'){
            this.setState({onlyMonoposizione: true, counter: this.state.counter+1})
        }else{
            this.setState({onlyMonoposizione: false, counter: this.state.counter+1})
        }
    }

    handleSortBy = (event) => {
        const value = event.target.getAttribute('data-attr');
        const order = event.target.getAttribute('data-order');
        this.setState({sortBy: value, sortOrder: order, counter: this.state.counter+1});
    }

    handleSortOrder = (event) => {

        const value = event.target.parentElement.getAttribute('data-attr')
        this.setState({sortOrder: value, counter: this.state.counter+1});
    }

    handleInputAutocompile = (newValue,id) =>{
        this.setState({
            [id]: newValue, 
            counter: this.state.counter+1
        });
    }

    handleCheckElement = (event) => {
        const stateId = event.target.id;
        const value = {value: event.target.value, solo: false};
        let valueState = this.state[stateId];
        let indexSolo = valueState.findIndex(x => x.solo === true);
        let soli = event.target.parentNode.parentNode.getElementsByClassName('solo');
        let solo = event.target.parentNode.getElementsByClassName('solo').item(0);

        if(event.target.checked){
            if(indexSolo >= 0){
                valueState[indexSolo].solo = false;
                Array.from(soli).forEach((s) => {
                    s.classList.remove('select');
                });
            }
            valueState.push(value)
        }else{
            valueState = valueState.filter(val => val.value !== value.value)
            if(solo.classList.contains('select')){
                solo.classList.toggle('select');
            }

        }

        this.setState({ [stateId]: valueState, counter: this.state.counter+1})
    }

    handleSoloElement = (event) => {
        const stateId = event.target.id;
        const value = {value: event.target.getAttribute('data-filter'), solo: true};
        let valueState = this.state[stateId];
        let inputs = event.target.parentNode.parentNode.getElementsByTagName('input');
        let soli = event.target.parentNode.parentNode.getElementsByClassName('solo');
        if(!event.target.classList.contains("select")){
            if(value.value === 'Unsecured' || value.value === 'UnSecured'){
                this.handlerActiveAssets();
            }
            if(value.value === 'Secured'){
                this.handlerDisableAssets();
            }
            valueState = [];
            Array.from(inputs).forEach((input, index) => {
                soli[index].classList.remove('select');
                if(input.name !== value.value)
                    input.checked = false;
                else
                    input.checked = true;
            });
            valueState.push(value)
            event.target.classList.add("select");

        }else{
            if(value.value === 'Unsecured' || value.value === 'UnSecured'){
                this.handlerDisableAssets();
            }
            event.target.classList.remove("select");
            valueState = valueState.filter(val => val.value !== value.value)
            const valueChanged= {value: event.target.getAttribute('data-filter'), solo: false};
            valueState.push(valueChanged);
        }

        this.setState({ [stateId]: valueState, counter: this.state.counter+1})
    }

    handlerActiveAssets(){
        const collapse = document.getElementById('collapsecaratteristicheAssetValues');
        const autosuggest = document.getElementById('autosuggestluogoAsset');
        Array.from(collapse.getElementsByClassName('check')).forEach((el) => {
            el.getElementsByTagName('input').item(0).checked = false;
            el.getElementsByClassName('solo').item(0).classList.remove('select');
        });
        autosuggest.getElementsByTagName('input').item(0).value = ''
        this.setState({
            caratteristicheAssetValues: [],
            luogoAsset: '',
            counter: this.state.counter+1
        });
        collapse.firstElementChild.classList.add('show');
        autosuggest.firstElementChild.classList.add('show');
        collapse.parentNode.classList.add('disableCheck');
        autosuggest.classList.add('disableCheck');
    }
    handlerDisableAssets(){
        const collapse = document.getElementById('collapsecaratteristicheAssetValues');
        const autosuggest = document.getElementById('autosuggestluogoAsset');
        collapse.firstElementChild.classList.remove('show');
        autosuggest.firstElementChild.classList.remove('show');
        collapse.parentNode.classList.remove('disableCheck');
        autosuggest.classList.remove('disableCheck');
    }

    handleChangeTab = () => {
            return this.state;
    }

    handleHashtagSelected = (val) => {
        this.setState({hashtag: val, counter: this.state.counter+1});
    }

    resetFiltroCheck = (inputName) =>{
        Array.from(document.getElementsByTagName('input')).forEach((el) => {
            if(el.name === inputName){
                el.click();
            }
        });
    }

    getFiltroRange =(ref)=>{
        this.rangeRef = ref
    }

    getFiltroHashtag =(ref)=>{
        this.hashtagRef = ref
    }

    resetFiltroRange(){
        const min = document.getElementsByClassName('input-range__slider').item(0).getAttribute('aria-valuemin');
        const max = document.getElementsByClassName('input-range__slider').item(1).getAttribute('aria-valuemax')
        const valMinMax = {
            min: min,
            max: max
        }
        this.rangeRef.setState(() => {this.rangeRef.state.value = valMinMax});
        this.rangeRef.forceUpdate();
        const values = [];
        this.setState({gbvValues: values, counter: this.state.counter+1});
    }

    resetFiltroMonoPosition = () =>{
        document.getElementById('all').classList.toggle('select');
        document.getElementById('single').classList.toggle('select');
        this.setState({onlyMonoposizione : false, counter: this.state.counter+1});
    }

    resetFiltroLuogoAsset = () =>{
        this.setState({luogoAsset : '', counter: this.state.counter+1});
        document.getElementById('autosuggestluogoAsset').getElementsByTagName('input').item(0).value = "";

    }

    resetFiltroDebitori = () =>{
        this.setState({residenzaDebitori : '', counter: this.state.counter+1});
        document.getElementById('autosuggestresidenzaDebitori').getElementsByTagName('input').item(0).value = "";
    }

    resetFiltroHashtag = () => {
        this.hashtagRef.reset()
    }

    resetAll = () =>{
        this.props.dispatch(actions.fetchFiltriPortValues());
        Array.from(document.getElementsByTagName('input')).forEach((el) => {
            el.checked = false;
            if(el.type === 'text'){
                el.value = '';
            }
        });
        Array.from(document.getElementsByClassName('solo')).forEach((el) => {
            el.classList.remove('select')
        });

        document.getElementsByClassName('radioFilter').item(0).getElementsByClassName('item').item(0).classList.add('select');
        document.getElementsByClassName('radioFilter').item(0).getElementsByClassName('item').item(1).classList.remove('select');

        document.getElementById('autosuggestluogoAsset').getElementsByTagName('input').item(0).value = "";
        document.getElementById('autosuggestresidenzaDebitori').getElementsByTagName('input').item(0).value = "";

        this.setState({gbvValues : []});
        this.setState({tipoDiCreditoValues : []});
        this.setState({controparteValues : []});
        this.setState({caratteristicheAssetValues : []});
        this.setState({luogoAsset : ''});
        this.setState({residenzaDebitori : ''});
        this.setState({procedimentiInCorsoValues : []});
        this.setState({onlyMonoposizione : false, counter: this.state.counter+1});

    }

    showFilter() {
        document.getElementById('filterContainer').classList.toggle('show');
        document.getElementById('over').classList.toggle('show');
    }

    render() {
        return (
            <div id='search' className='row'>
                <div className='countPort'>
                    <h1>Search on <Count/> portfolios.</h1>
                    <h4>Use the filters to find your ideal portfolios</h4>
                </div>
                {
                    window.innerWidth <= 1024?
                    <div id='btnFiltroMobile'>
                        <div className="buttonFiltra" onClick={this.showFilter}>
                            <ISlider/>
                        </div>
                    </div>:
                    ''
                }
                <div className='row'>
                    <Filter getFiltroHashtag={this.getFiltroHashtag} getFiltroRange={this.getFiltroRange} resetAll={this.resetAll} handleCheckElement={this.handleCheckElement} handleSoloElement={this.handleSoloElement} handleInputAutocompile={this.handleInputAutocompile} handleRadio={this.handleRadio} handleInputRange={this.handleInputRange} showFilter={this.showFilter} handleHashtagSelected={this.handleHashtagSelected}/>
                    <Tabs resetFiltroHashtag={this.resetFiltroHashtag} resetFiltroDebitori={this.resetFiltroDebitori} resetFiltroLuogoAsset={this.resetFiltroLuogoAsset} resetFiltroMonoPosition={this.resetFiltroMonoPosition} resetFiltroRange={this.resetFiltroRange} resetFiltroCheck={this.resetFiltroCheck} props={this.props} handleChangeTab={this.handleChangeTab} handleSortBy={this.handleSortBy} handleSortOrder={this.handleSortOrder}/>
                </div>


            </div>
        );
    }
}

export default connect() (Search)
