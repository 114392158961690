import React from 'react';
import {Link} from "react-router-dom";
import { ReactComponent as IChevron } from '../../styles/svg/chevron-left.svg';

import '../../styles/components/errorPage.css';

export class ErrorPage extends React.Component{
    componentDidMount(){
        if(window.innerWidth > 500){
            if(window.innerHeight > document.getElementById('root').clientHeight ){
                document.getElementById('question').classList.remove('fixed')
            }else{
                document.getElementById('question').classList.add('fixed')
            }
        }
    }

    render(){
        return(
            <div id='errorPage'>
                <div className='text'>
                    <h1>Whoops! Something’s missing.</h1>
                    <h3>This page is missing or an incorrect link was typed.</h3>
                    <Link to='/home' className='btnBack'>
                        <h5><IChevron/>Back to home page</h5>
                    </Link>
                </div>
                <div className='img'>
                    <img src={require('../../styles/images/errorPage.png')}/>
                </div>
            </div>
        )
    }
}
