import React, {Component} from 'react';
import Position from "../../shared/Position";
import {connect} from "react-redux";
import * as actions from "../../actions";
import {Link} from "react-router-dom";
import Pagination from "rc-pagination";
import EmptyPage from "../../shared/components/EmptyPage";
import SearchItemsCount from "../../shared/components/SearchItemsCount";
import {ResultLoader} from "../../shared/components/ResultLoader";
import {TagList} from "../../shared/components/TagList";

class PositionListSerched extends Component{
    state = {
        current: 0
    };
    onChange = (page) => {
        const filtro = this.props.handleChangeTab();
        this.setState({current: page-1}, ()=> this.props.dispatch(actions.fetchPosizioniFiltrati(filtro, page-1, itemsPerPage)));
    };

    getLastPortId = '';

    setLastPortId(id){
        this.getLastPortId = id;
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        this.setLastPortId('')
    }

    initPosition(){
        if(this.props.positions.positions.length === 0){
            return this.props.positions.fetching ? <ResultLoader /> : <EmptyPage/>;
        }
        else if(window.innerWidth > 500){
            return this.props.positions.positions.map((pos, index) => {
                return (
                    <div className='card containerPosition'>
                        {
                            this.getLastPortId === '' || this.getLastPortId !== pos.portafoglioId?
                        <div>
                            <div className='row'>
                                <div className='d-flex justify-content-between align-items-center dettaglioPort'>
                                    <h2>{pos.portafoglioName}</h2>
                                    <Link to={`/portfolio-detail/${pos.portafoglioId}/positions`}>
                                        Portfolio Detail
                                    </Link>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="d-flex col-12 justify-content-between headerPos">
                                    <div div className='colPos small'>Id</div>
                                    <div div className='colPos small'>NDG</div>
                                    <div div className='colPos medium'>Debt Category</div>
                                    <div div className='colPos medium'>Borrower type</div>
                                    <div div className='colPos small'>Borrower's province</div>
                                    <div div className='colPos large'>Prevalent Loan Category</div>
                                    <div div className='colPos extraSmall'>Nr. <br/>Asset</div>
                                    <div div className='colPos small'>Prevalent mortages's degree</div>
                                    <div div className='colPos small'>Current procedures</div>
                                    <div div className='colPos extraLarge'>GBV</div>
                                    <div div className='colPos last'></div>
                                </div>
                            </div>
                        </div>
                                :
                                ''
                        }
                        {
                            this.setLastPortId(pos.portafoglioId)
                        }
                        <Position
                            key={index}
                            data={pos.posizione}
                            currentProceduresCount={pos.currentProceduresCount}
                            notMatchedDebtCategory={pos.notMatchedDebtCategory}
                            notMatchedBorrowerType={pos.notMatchedBorrowerType}
                            notMatchedCaratteristiche={pos.notMatchedCaratteristiche}
                            notMatchedProcedimenti={pos.notMatchedProcedimenti}
                            notMatchedAssetLocation={pos.notMatchedAssetLocation}
                            notMatchedBorrowerLocation={pos.notMatchedBorrowerLocation}
                            notMatchedHashtag={pos.notMatchedHashtag}
                        />

                    </div>

                    )
            });
        }else{
            return this.props.positions.positions.map((pos, index) => {
                return (
                    <div className='card containerPosition'>
                        {
                            this.getLastPortId === '' || this.getLastPortId !== pos.portafoglioId?
                                <div>
                                    <div className='row'>
                                        <div className='d-flex justify-content-between align-items-center dettaglioPort'>
                                            <h2>{pos.portafoglioName}</h2>
                                            <Link to={`/portfolio-detail/${pos.portafoglioId}/positions`}>
                                                Dettaglio
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                :
                                ''
                        }
                        {
                            this.setLastPortId(pos.portafoglioId)
                        }
                        <Position
                            key={index}
                            data={pos.posizione}
                            currentProceduresCount={pos.currentProceduresCount}
                            notMatchedDebtCategory={pos.notMatchedDebtCategory}
                            notMatchedBorrowerType={pos.notMatchedBorrowerType}
                            notMatchedCaratteristiche={pos.notMatchedCaratteristiche}
                            notMatchedProcedimenti={pos.notMatchedProcedimenti}
                            notMatchedAssetLocation={pos.notMatchedAssetLocation}
                            notMatchedBorrowerLocation={pos.notMatchedBorrowerLocation}
                            notMatchedHashtag={pos.notMatchedHashtag}
                        />

                    </div>

                )
            });
        }
    }

    componentWillMount() {
        const filtro = this.props.handleChangeTab();
        this.props.dispatch(actions.fetchPosizioniFiltrati(filtro, activePage, itemsPerPage));
        this.props.dispatch(actions.fetchPortafogliFiltrati(filtro));
    }

    render(){
        return (
            <div>
                <section id='positionList'>
                    <div className='row'>
                        {
                            <SearchItemsCount currentItems={this.props.packs.length}/>
                        }
                    </div>
                    {
                        <TagList
                            resetFiltroDebitori={this.props.resetFiltroDebitori}
                            resetFiltroLuogoAsset={this.props.resetFiltroLuogoAsset}
                            resetFiltroMonoPosition={this.props.resetFiltroMonoPosition}
                            resetFiltroRange={this.props.resetFiltroRange}
                            resetFiltroCheck={this.props.resetFiltroCheck}
                            resetFiltroHashtag={this.props.resetFiltroHashtag}
                            handleChangeTab={this.props.handleChangeTab} />
                    }
                    <div className='row'>
                        <div className='col-12'>
                            {this.initPosition()}
                        </div>

                    </div>
                </section>
                <div className="d-flex justify-content-end">
                    <Pagination
                        onChange={this.onChange}
                        current={this.state.current+1}
                        total={this.props.positions.positionCount}
                        style={{ marginBottom: '2rem' ,marginTop: '2rem'}}
                        showTitle={false}
                        showQuickJumper={false}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state){
    return{
        positions: state.positions.dataPort,
        packs: state.packs.data
    }
}


export default connect(mapStateToProps)(PositionListSerched)

let activePage= 0;
export const itemsPerPage= 10;
