import React from 'react';
import CookiePolicy from './CookiePolicy';
import PrivacyPolicy from './PrivacyPolicy';

export function PrivacyCookiePolicy(props){
    return(
        <React.Fragment>
            <PrivacyPolicy small={props.small}/>&nbsp;&amp;&nbsp;<CookiePolicy small={props.small}/>
        </React.Fragment>                        
    );
}
