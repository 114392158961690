import React, {Component} from "react";
import * as actions from "../../actions";
import {connect} from "react-redux";
import PackConversation from "../packs/PackConversation";


class ViewConversations extends Component {
    componentWillMount() {
        const match = this.props.match.params.id;
        this.props.dispatch(actions.fetchMessaggiById(match))
    }

    initView() {
        return <PackConversation all={this.props.conversazione} convId={this.props.match.params.id}/>
    }

    render() {
        return (
            this.initView()
        );

    }

}

function mapStateToProps(state) {

    return {

        conversazione: state.conversations.messaggi
    }
}

export default connect(mapStateToProps)(ViewConversations)
