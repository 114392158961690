import React,{Component} from 'react';
import { FaStar, FaRegStar } from 'react-icons/fa';
import * as actions from "../../actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import 'toasted-notes/src/styles.css';
import Hashtag from "../components/Hashtag";

class Pack extends Component {
    constructor(props){
        super(props);
        this.state = {
            addedToPref : false};
    }

    addPref(id, handleChangeTab) {
        let filters;
        if(handleChangeTab === undefined){
            filters = null;
        }else{
            filters = handleChangeTab();
        }
        this.props.dispatch(actions.addPreferiti(id, filters))
    }

    addVisti(id){
        this.props.dispatch(actions.addVisti(id));
    }

    render() {
        const mystyle = {
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-line-clamp": "2",
            "-webkit-box-orient": "vertical"
        };
        return (
            <div className={this.props.colNum}>
                <div className='card containerPack'>
                    <div className='stato'>
                        <div className='statoData'>
                            <h6 className={`tipo ${(this.props.data.stato==='Offer in evaluation' || this.props.data.stato==='On sale') ? 'onSale':''}`}><span className='point'/>{this.props.data.stato}</h6>
                        </div>
                        <div className={`star ${!this.props.data.actionsEnabled? 'disable': ''}`}
                             onClick={this.props.data.actionsEnabled? this.addPref.bind(this, this.props.data.id, this.props.handleChangeTab):''}>{this.props.data.preferred ? <FaStar/> :
                            <FaRegStar/>}
                        </div>
                    </div>
                    <Link onClick={this.addVisti.bind(this, this.props.data.id)} to={`/portfolio-detail/${this.props.data.id}/positions`} style={{ textDecoration: 'none' , color: 'black'}}>
                        <div className='titolo'>
                            <h2 style={mystyle}>{this.props.data.denominazione}</h2>
                            <div className='desc'>
                                <span className='data'>
                                    {
                                        this.props.data.dataInizioValid!=='' && this.props.data.dataFineValid!==''?
                                            `Available from ${this.props.data.dataInizioValid} to ${this.props.data.dataFineValid}`:
                                            this.props.data.dataInizioValid!==''?`Available from ${this.props.data.dataInizioValid}`:
                                                this.props.data.dataFineValid!==''?`Expires in ${this.props.data.dataFineValid}`:''
                                    }
                                </span>
                                {
                                    this.props.data.mandante &&
                                    <h6>By {this.props.data.mandante}</h6>
                                }

                                    <h6 style={mystyle}>{this.props.data.descrizione}</h6>
                                </div>
                            </div>
                        <div className='gbv'>
                            <h3>{(parseFloat(this.props.data.gbv)/1000000).toFixed(1)}</h3><h6>Mln € GBV</h6>
                        </div>
                        <div className='categoria'>
                            {
                                (this.props.data.nSecured>0?
                                        this.props.data.nSecured===1 && this.props.data.nUnsecured===0?<h5 className='secured'><span>Secured</span><span>SINGLE-NAME</span></h5>:
                                            <h5 className='secured'><span>Secured</span><span>{this.props.data.nSecured}</span></h5>
                                        :''
                                )
                            }
                            {
                                (this.props.data.nUnsecured>0?
                                        this.props.data.nUnsecured===1 && this.props.data.nSecured===0?<h5 className='unsecured'><span>Unsecured</span><span>SINGLE-NAME</span></h5>:
                                            <h5 className='unsecured'><span>Unsecured</span><span>{this.props.data.nUnsecured}</span></h5>
                                        :''
                                )
                            }

                        </div>
                    </Link>
                    {
                        this.props.data.hashtag && <Hashtag hashtags={this.props.data.hashtags} hashtag={this.props.data.hashtag}/>
                    }

                </div>

            </div>
        );
    }
}



export default connect ()(Pack)
