import React from "react";

export class Hashtag extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showList: false
        }
    }

    setShowList = () => {
        this.setState({showList: !this.state.showList});
    }

    capitalize = (str) => {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    
    render() {
        const hashtag = this.props.hashtag
        const hashtags = this.props.hashtags
        return (
        <div className="hashtag">
            <div className="hashtag_first">{this.capitalize(hashtag.descrizione)}</div>
            {
                hashtags.length > 0 &&
                <div className={`list_size ${this.state.showList?'show':''}`} onClick={this.setShowList}>
                    <span className="text">+{hashtags.length}</span>
                    <img src={require("../../styles/images/closeHashtag.png")} width="16"/>
                    <div className={`list ${this.state.showList ? 'show' : ''}`}>
                        {
                            hashtags.map(tag => {
                                return (
                                    <div className="hashtag_item">
                                        {this.capitalize(tag.descrizione)}
                                    </div>)
                            })
                        }
                    </div>
                </div>
            }

        </div>
        );
    }
}

export default Hashtag;
