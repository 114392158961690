import React from 'react';
import {Link} from "react-router-dom";
import { ReactComponent as ILogin } from '../../styles/svg/external-link-square.svg';
import { ReactComponent as IPlus } from '../../styles/svg/plus.svg';

import '../../styles/areaPubblica/buyer.css';
import { Footer } from './Footer';

class Faq extends React.Component {

    componentDidMount() {
        document.getElementById('body').classList.add('big');
        if(window.innerWidth < 500){
            document.getElementById('body').classList.add('public');
        }
        document.getElementById('marketPlace').classList.add('hide');
        var acc = document.getElementsByClassName("item");
        var i;
        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function() {
                this.parentNode.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            });
        }
    }

    componentWillUnmount() {
        document.getElementById('body').classList.remove('big');
        if(window.innerWidth < 500){
            document.getElementById('body').classList.remove('public');
        }
        document.getElementById('marketPlace').classList.remove('hide');
    }

    writeMail() {
        window.location.href = "mailto:"+process.env.REACT_APP_MAILTO;
    }

    render() {
        return (
            <div id='faq'>
                <div className='fasciaHeader'>
                    <div className='header'>
                        <div className='logo'>
                            <Link to='/'><img src={require('../../styles/images/header.png')}/></Link>
                        </div>
                        <div className='linkHeader'>
                            <Link to='/for-buyer'><h5>For Buyer</h5></Link>
                            <Link to='/for-seller'><h5>For Seller</h5></Link>
                            <Link to='/about'><h5>About Us</h5></Link>
                            {/*<Link to='/faq'><h5 className='active'>FAQ</h5></Link>*/}
                            <a href={process.env.REACT_APP_CAS_LOGIN_URL + window.location.origin + '/home'}>
                                <div className='login'>
                                    <ILogin width='14px' fill='#008996'/><h5>Log In</h5>
                                </div>
                            </a>
                            <Link to='/signup'>
                                <div className='signIn'>
                                    <h5>Sign Up</h5>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='description'>
                        <span className='type'>Need Help?</span>
                        <span className='title'>Frequently asked question</span>
                    </div>
                </div>
                <div className='ask'>
                    <div className='title'>
                        <h2>Topic</h2>
                    </div>
                    <div className='items'>
                        <div className='containerItem'>
                            <div className="item">
                                <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                <div className='plus'>
                                    <IPlus fill='#008996' width='16px'/>
                                </div>
                            </div>
                            <div className="panel">
                                <div className='testo'>
                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                </div>
                                <div className='testo'>
                                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
                                </div>
                                <div className='testo'>
                                    Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
                                </div>
                            </div>
                        </div>
                        <div className='containerItem'>
                            <div className="item">
                                <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                <div className='plus'>
                                    <IPlus fill='#008996' width='16px'/>
                                </div>
                            </div>
                            <div className="panel">
                                <div className='testo'>
                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                </div>
                                <div className='testo'>
                                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
                                </div>
                                <div className='testo'>
                                    Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
                                </div>
                            </div>
                        </div>
                        <div className='containerItem'>
                            <div className="item">
                                <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                <div className='plus'>
                                    <IPlus fill='#008996' width='16px'/>
                                </div>
                            </div>
                            <div className="panel">
                                <div className='testo'>
                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                </div>
                                <div className='testo'>
                                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
                                </div>
                                <div className='testo'>
                                    Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ask'>
                    <div className='title'>
                        <h2>Topic</h2>
                    </div>
                    <div className='items'>
                        <div className='containerItem'>
                            <div className="item">
                                <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                <div className='plus'>
                                    <IPlus fill='#008996' width='16px'/>
                                </div>
                            </div>
                            <div className="panel">
                                <div className='testo'>
                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                </div>
                                <div className='testo'>
                                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
                                </div>
                                <div className='testo'>
                                    Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
                                </div>
                            </div>
                        </div>
                        <div className='containerItem'>
                            <div className="item">
                                <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                <div className='plus'>
                                    <IPlus fill='#008996' width='16px'/>
                                </div>
                            </div>
                            <div className="panel">
                                <div className='testo'>
                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                </div>
                                <div className='testo'>
                                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
                                </div>
                                <div className='testo'>
                                    Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
                                </div>
                            </div>
                        </div>
                        <div className='containerItem'>
                            <div className="item">
                                <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                <div className='plus'>
                                    <IPlus fill='#008996' width='16px'/>
                                </div>
                            </div>
                            <div className="panel">
                                <div className='testo'>
                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                </div>
                                <div className='testo'>
                                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
                                </div>
                                <div className='testo'>
                                    Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ask'>
                    <div className='title'>
                        <h2>Topic</h2>
                    </div>
                    <div className='items'>
                        <div className='containerItem'>
                            <div className="item">
                                <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                <div className='plus'>
                                    <IPlus fill='#008996' width='16px'/>
                                </div>
                            </div>
                            <div className="panel">
                                <div className='testo'>
                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                </div>
                                <div className='testo'>
                                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
                                </div>
                                <div className='testo'>
                                    Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
                                </div>
                            </div>
                        </div>
                        <div className='containerItem'>
                            <div className="item">
                                <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                <div className='plus'>
                                    <IPlus fill='#008996' width='16px'/>
                                </div>
                            </div>
                            <div className="panel">
                                <div className='testo'>
                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                </div>
                                <div className='testo'>
                                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
                                </div>
                                <div className='testo'>
                                    Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
                                </div>
                            </div>
                        </div>
                        <div className='containerItem'>
                            <div className="item">
                                <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                <div className='plus'>
                                    <IPlus fill='#008996' width='16px'/>
                                </div>
                            </div>
                            <div className="panel">
                                <div className='testo'>
                                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                </div>
                                <div className='testo'>
                                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
                                </div>
                                <div className='testo'>
                                    Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='fasciaReady'>
                    <div className='description'>
                        <span className='title'>Are you ready to start?</span>
                        <span className='subtitle'>Request your login credential, enter your personal area and start browsing over 12 portfolios. </span>
                        <Link to='/signup'><span className='getStart'>Get started!</span></Link>
                    </div>
                </div>
                <div className='fasciaHelp'>
                    <div className='description'>
                        <div className='question'>
                            Do you need help?
                        </div>
                        <div className='testo' onClick={this.writeMail}>
                            <span>Write Us an email</span>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Faq
