import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RoutedTabs, NavTab } from 'react-router-tabs';
import PackListSearched from './PackListSearched';
import PositionListSearched from './PositionListSerched';
import Map from './Map';
import Strats  from './Strats';
import { FaList, FaMapMarkerAlt, FaSignal } from 'react-icons/fa';
import {Registrazione} from "../registrazione/Registrazione";


export const Tabs = ({
                         props,
                         handleChangeTab,
                         handleSortBy,
                         handleSortOrder,
                         resetFiltroCheck,
                         resetFiltroRange,
                         resetFiltroMonoPosition,
                         resetFiltroLuogoAsset,
                         resetFiltroDebitori,
                         resetFiltroHashtag}) => {
    const match = props.match.path;
    return (
        <div id='lista'>
            <RoutedTabs startPathWith={match} className='tab d-flex justify-content-center align-items-center'>
                <NavTab to='/portfolios' className='flex-fill nav-tab'><FaList/> Portfolios</NavTab>
                <NavTab to='/positions' className='flex-fill nav-tab'><FaList/> Borrowers</NavTab>
                <NavTab to='/map' className='flex-fill nav-tab'><FaMapMarkerAlt/> Map</NavTab>
                <NavTab to='/strats' className='flex-fill nav-tab'><FaSignal/> Strats </NavTab>
            </RoutedTabs>

            <Switch>
                <Route exact path={match} render={() => <Redirect replace to={match+'/portfolios'} />} />
                <Route path={match+'/portfolios'} render={() =>
                    <PackListSearched
                        resetFiltroDebitori={resetFiltroDebitori}
                        resetFiltroLuogoAsset={resetFiltroLuogoAsset}
                        resetFiltroMonoPosition={resetFiltroMonoPosition}
                        resetFiltroRange={resetFiltroRange}
                        resetFiltroCheck={resetFiltroCheck}
                        resetFiltroHashtag={resetFiltroHashtag}
                        handleChangeTab={handleChangeTab}
                        handleSortBy={handleSortBy}
                        handleSortOrder={handleSortOrder}
                    />}
                />
                <Route path={match+'/positions'} render={() =>
                    <PositionListSearched
                        resetFiltroDebitori={resetFiltroDebitori}
                        resetFiltroLuogoAsset={resetFiltroLuogoAsset}
                        resetFiltroMonoPosition={resetFiltroMonoPosition}
                        resetFiltroRange={resetFiltroRange}
                        resetFiltroCheck={resetFiltroCheck}
                        resetFiltroHashtag={resetFiltroHashtag}
                        handleChangeTab={handleChangeTab}
                    />}
                />
                <Route path={match+'/map'} component={() =>
                    <Map
                        resetFiltroDebitori={resetFiltroDebitori}
                        resetFiltroLuogoAsset={resetFiltroLuogoAsset}
                        resetFiltroMonoPosition={resetFiltroMonoPosition}
                        resetFiltroRange={resetFiltroRange}
                        resetFiltroCheck={resetFiltroCheck}
                        resetFiltroHashtag={resetFiltroHashtag}
                        handleChangeTab={handleChangeTab}
                    />}
                />
                <Route path={match+'/strats'} render={() =>
                    <Strats
                        resetFiltroDebitori={resetFiltroDebitori}
                        resetFiltroLuogoAsset={resetFiltroLuogoAsset}
                        resetFiltroMonoPosition={resetFiltroMonoPosition}
                        resetFiltroRange={resetFiltroRange}
                        resetFiltroCheck={resetFiltroCheck}
                        resetFiltroHashtag={resetFiltroHashtag}
                        handleChangeTab={handleChangeTab}/>}
                />

            </Switch>
        </div>
    );
};


