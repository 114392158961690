import React, {Component} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {connect} from "react-redux";
import Pack  from "./Pack";
import classNames from "classnames";
import * as actions from "../../actions";
import {Link} from "react-router-dom";

class PackCarousel extends Component{

    initCarousel(){
        return this.props.all.packs.map((pack, index) => {
            return <div><Pack key={index} colNum='group-12' data={pack}/></div>
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.Carousel.state.totalItems = nextProps.all.packs.length;
        if(this.props.all.packs.length < nextProps.all.packs.length)
            this.Carousel.next();
    }
    clearAllViewed(){
        this.props.dispatch(actions.clearAllViewed());
    }

    render() {
        const CustomDot = ({index, onClick, active, carouselState: { currentSlide }}) => {
            if(window.innerWidth > 1024){
                if( index % 4 === 0){
                    return(<div onClick={e => {
                        onClick();
                        e.preventDefault();
                    }}
                                className={classNames("dot", {
                                    "dot active": active
                                })}
                    >
                    </div>)
                }else {
                    return false
                }
            }else if(window.innerWidth <= 1024 && window.innerWidth > 768){
                if( index % 3 === 0){
                    return(<div onClick={e => {
                        onClick();
                        e.preventDefault();
                    }}
                                className={classNames("dot", {
                                    "dot active": active
                                })}
                    >
                    </div>)
                }else{
                    return false
                }
            }else if(window.innerWidth <= 768 && window.innerWidth > 500){
                if( index % 2 === 0){
                    return(<div onClick={e => {
                        onClick();
                        e.preventDefault();
                    }}
                                className={classNames("dot", {
                                    "dot active": active
                                })}
                    >
                    </div>)
                }else{
                    return false
                }
            }else {
                return(<div onClick={e => {
                    onClick();
                    e.preventDefault();
                }}
                            className={classNames("dot", {
                                "dot active": active
                            })}
                ></div>)
            }
        };
        const CustomRightArrow = ({ onClick, ...rest }) => {
            return <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right" onClick={e => {
                const nextPrev = window.innerWidth<500?1:window.innerWidth<=768?2:window.innerWidth<=1024?3:4
                this.Carousel.goToSlide(this.Carousel.state.currentSlide +nextPrev);
                }}
            />;
        };
        const CustomLeftArrow = ({ onClick, ...rest }) => {
            return <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" onClick={e => {
                const nextPrev = window.innerWidth<500?1:window.innerWidth<=768?2:window.innerWidth<=1024?3:4
                this.Carousel.goToSlide(this.Carousel.state.currentSlide -nextPrev);
            }} />;
        };
        return (
            <section id='portCarousel' className={this.props.all.type}>
                {(this.props.bkg?<div className='bkg'/>:'' )}
                <div className='row justify-content-between align-items-center'>
                    <h2 className='page-title'>{this.props.all.title}</h2>
                    <div className='operation'>{this.props.all.operation === 'Clear all' ? <div onClick={this.clearAllViewed.bind(this)}>Clear all</div> : <Link to={"/search/portfolios"} style={{ textDecoration: 'none', color: 'inherit'}} >{this.props.all.operation}</Link> }</div>
                </div>
                <Carousel
                    ref={(el) => (this.Carousel = el)}
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={false}
                    customDot={<CustomDot {...this.props}/>}
                    customLeftArrow={<CustomLeftArrow />}
                    customRightArrow={<CustomRightArrow />}
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    partialVisbile={true}
                    removeArrowOnDeviceType={['mobile']}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 4,
                            paritialVisibilityGutter: 0
                        },
                        mobile: {
                            breakpoint: {
                                max: 500,
                                min: 0
                            },
                            items: 1,
                            paritialVisibilityGutter: window.innerWidth - 290
                        },
                        tablet: {
                            breakpoint: {
                                max: 768,
                                min: 500
                            },
                            items: 2,
                            paritialVisibilityGutter: 60
                        },
                        tablet_landscape: {
                            breakpoint: {
                                max: 1024,
                                min: 769
                            },
                            items: 3,
                            paritialVisibilityGutter: 0
                        }
                    }}
                    showDots
                    sliderClass=""
                    slidesToSlide={window.innerWidth<500?1:window.innerWidth<=768?2:window.innerWidth<=1024?3:4}
                    swipeable
                >

                    {this.initCarousel()}

                </Carousel>
            </section>
        );
    }
}

export default connect()(PackCarousel)



