import React from 'react';
import axios from "axios";
import {TagList} from "./TagList";
import {ReactComponent as ISlider} from "../../styles/svg/sliders-h.svg";

export class CountPos extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            value: 0,
            valuePos: 0
        }
    }

    componentWillMount() {
        axios.get(`/api-mkt/position/countAllById/${this.props.idPort}`).then(res => {
            this.setState({value: res.data});
        })
    }

    setValuePos(value){
        this.setState({valuePos: value})
    }

    render(){
        const filtro = this.props.filtered
        if(filtro.gbvValues.length > 0 || filtro.tipoDiCreditoValues.length > 0 || filtro.controparteValues.length > 0 ||
            filtro.caratteristicheAssetValues.length > 0 || filtro.luogoAsset.length > 0 || filtro.residenzaDebitori.length > 0 ||
            filtro.procedimentiInCorsoValues.length > 0){
            return (
                <div className='countPosByPort'>
                    <div className='countFiltered'>
                        <span>{this.state.valuePos}</span> of <span>{this.state.value} borrowers</span> match your filters:
                    </div>
                    {
                        window.innerWidth <= 1024?
                            <div className="buttonFiltra" onClick={this.props.showFilter}>
                                <ISlider/><h4></h4>
                            </div> :''
                    }

                    {
                        <TagList
                            resetFiltroDebitori={this.props.resetFiltroDebitori}
                            resetFiltroLuogoAsset={this.props.resetFiltroLuogoAsset}
                            resetFiltroMonoPosition={this.props.resetFiltroMonoPosition}
                            resetFiltroRange={this.props.resetFiltroRange}
                            resetFiltroCheck={this.props.resetFiltroCheck}
                            handleChangeTab={this.props.handleChangeTab}
                        />
                    }
                </div>
            );
        }else{
            return (
                <div className='countPosByPort'>
                    <div className='countFiltered'>
                        <span>{this.state.value} borrowers</span>
                    </div>
                {window.innerWidth <= 1024?
                    <div className="buttonFiltra" onClick={this.props.showFilter}>
                        <ISlider/><h4></h4>
                    </div> :''
                }
                </div>
            )
        }
    }
}

