import React, {Component} from 'react';
import Pack from "./Pack";
import {Link} from "react-router-dom";
import * as actions from "../../actions";


export class PackList extends Component{

    initPacks(){
        const p = (this.props.all.forceList)? this.props.all.packs.slice(0,4) : this.props.all.packs;
        return p.map((pack, index) => {
           return <Pack key={index} colNum='group-4' data={pack} type={pack.type}/>
        });
    }

    clearAllViewed(){
        this.props.all.dispatch(actions.clearAllViewed());
    }

    render(){
        return(
            <section id='portList' className={this.props.all.type}>
                {(this.props.bkg?<div className='bkg'/>:'' )}
                <div className='row justify-content-between align-items-center'>
                    <h2 className='page-title'>{this.props.all.title}</h2>
                    <div  className='operation'>{this.props.all.operation === 'Clear all' ? <div onClick={this.clearAllViewed.bind(this)}>Clear all</div> : <Link to={"/favorites"} style={{ textDecoration: 'none', color: 'inherit'}}>{this.props.all.operation}</Link> }</div>
                </div>
                <div className='row containerList'>
                    {this.initPacks()}
                </div>
            </section>
        );
    }
}
