import React from 'react';
import {Link} from "react-router-dom";
import { ReactComponent as ILogin } from '../../styles/svg/external-link-square.svg';
import '../../styles/areaPubblica/home.css'
import { Footer } from './Footer';


class Home extends React.Component{

    componentDidMount() {
        document.getElementById('body').classList.add('big');
        if(window.innerWidth < 500){
            document.getElementById('body').classList.add('public');
        }
        document.getElementById('marketPlace').classList.add('hide');
    }
    componentWillUnmount() {
        document.getElementById('body').classList.remove('big');
        if(window.innerWidth < 500){
            document.getElementById('body').classList.remove('public');
        }
        document.getElementById('marketPlace').classList.remove('hide');
    }

    openMenu() {
        document.getElementById('headerPublic').classList.toggle('white');
        document.getElementById('openPublic').classList.toggle('open');
    }

    writeMail() {
        window.location.href = "mailto:"+process.env.REACT_APP_MAILTO;
    }

    render() {
        return(
            <div id='areaPubblica'>
                <div className='fasciaHeader'>
                    <div id='headerPublic'>
                        {window.innerWidth > 500?
                            <div className='logo'>
                                <Link to='/'><img /></Link>
                            </div>
                            :
                            <div className='menuMobile'>
                                <div className='logo'>
                                    <Link to='/'><img /></Link>
                                </div>
                                <div className='btnMenu' onClick={this.openMenu}>
                                    <img src={require('../../styles/images/openMenu_white.png')}/>
                                </div>
                            </div>
                        }
                        {window.innerWidth > 500?
                            <div id='openPublic' className='linkHeader'>
                                <Link to='/for-buyer'><h5>For Buyer</h5></Link>
                                <Link to='/for-seller'><h5>For Seller</h5></Link>
                                <Link to='/about'><h5>About Us</h5></Link>
                                {/*<Link to='/faq'><h5>FAQ</h5></Link>*/}
                                <a href={process.env.REACT_APP_CAS_LOGIN_URL + window.location.origin + "/home"}>
                                    <div className='login'>
                                        <ILogin width='14px' fill='#FFF'/><h5>Log In</h5>
                                    </div>
                                </a>
                                <Link to='/signup'>
                                    <div className='signIn'>
                                        <h5>Sign Up</h5>
                                    </div>
                                </Link>
                            </div>
                            :
                            <div id='openPublic' className='linkHeader'>
                                <div className='linkMenu'>
                                    <Link to='/for-buyer'><h5>For Buyer</h5></Link>
                                    <Link to='/for-seller'><h5>For Seller</h5></Link>
                                    <Link to='/about'><h5>About Us</h5></Link>
                                    {/*<Link to='/faq'><h5>FAQ</h5></Link>*/}
                                </div>
                                <div className='buttonMenu'>
                                    <a href={process.env.REACT_APP_CAS_LOGIN_URL + window.location.origin + '/home'}>
                                        <div className='login'>
                                            <ILogin width='14px' fill='#008996'/><h5>Log In</h5>
                                        </div>
                                    </a>
                                    <Link to='/signup'>
                                        <div className='signIn'>
                                            <h5>Sign Up</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='description'>
                        <span className='title'>The secure marketplace for buying and selling loans portfolios.</span>
                        <span className='subtitle'>Cerved enables sellers and buyers to maximize performance of their debt portfolio by using industry expertise teams and advanced technology solutions.</span>
                        <span className='subsubtitle'>DISCOVER THE CERVED DATA DRIVEN MARKETPLACE PLATFORM</span>
                        <img src={require('../../styles/images/desktop.png')}/>
                    </div>
                </div>
                {window.innerWidth > 500?
                    <div className='fasciaBuyer'>
                        <div className='description'>
                            <div className='title'>
                                FOR BUYER
                            </div>
                            <div className='question'>
                                Why use Cerved Markagain?
                            </div>
                            <div className='testo'>
                                Cerved Markagain permits investors to match their buying objectives within a wide variety of complete and up-to-date portfolios.
                            </div>
                            <Link to='/for-buyer'>
                                <div className='btn'>
                                    Find out more
                                </div>
                            </Link>
                        </div>
                        <div className='img'>
                            <img src={require('../../styles/images/forBuyer.png')}/>
                        </div>
                    </div>
                    :
                    <div className='fasciaBuyer'>
                        <div className='img'>
                            <img src={require('../../styles/images/forBuyer.png')}/>
                        </div>
                        <div className='description'>
                            <div className='title'>
                                FOR BUYER
                            </div>
                            <div className='question'>
                                Why use Cerved Markagain?
                            </div>
                            <div className='testo'>
                                Cerved Markagain permits investors to match their buying objectives within a wide variety of complete and up-to-date portfolios.
                            </div>
                            <Link to='/for-buyer'>
                                <div className='btn'>
                                    Find out more
                                </div>
                            </Link>
                        </div>
                    </div>
                }

                <div className='fasciaSeller'>
                    <div className='img'>
                        <img src={require('../../styles/images/forSeller.png')}/>
                    </div>
                    <div className='description'>
                        <div className='title'>
                            FOR SELLER
                        </div>
                        <div className='question'>
                            Why assign your portfolio to Cerved?
                        </div>
                        <div className='testo'>
                            Our team of specialists works on Data remediation and maintenance of the data tape provided by the seller.  It ensures a complete and above all up-to-date information to Investors for the actual valuation of portfolios.
                        </div>
                        <Link to='/for-seller'>
                            <div className='btn'>
                                Find out more
                            </div>
                        </Link>
                    </div>
                </div>
                {window.innerWidth>500?
                    <div className='fasciaAbout'>
                        <div className='description'>
                            <div className='title'>
                                ABOUT US
                            </div>
                            <div className='question'>
                            	Who we are?
                            </div>
                            <div className='testo'>
                                Cerved is a leading Information Provider in Italy and one of the major rating agencies in Europe. Through its subsidiaries, Cerved is the leading independent market player, offering credit management services based on certified information and quantitative data.
                            </div>
                            <Link to='/about'>
                                <div className='btn'>
                                    Find out more
                                </div>
                            </Link>
                        </div>
                        <div className='img'>
                            <img src={require('../../styles/images/about.png')}/>
                        </div>
                    </div>
                    :
                    <div className='fasciaAbout'>
                        <div className='img'>
                            <img src={require('../../styles/images/about.png')}/>
                            <div className='gradient'></div>
                        </div>
                        <div className='description'>
                            <div className='title'>
                                ABOUT US
                            </div>
                            <div className='question'>
                                Who we are?
                            </div>
                            <div className='testo'>
                                Cerved is a leading Information Provider in Italy and one of the major rating agencies in Europe. Through its subsidiaries, Cerved is the leading independent market player, offering credit management services based on certified information and quantitative data.
                            </div>
                            <Link to='/about'>
                                <div className='btn'>
                                    Find out more
                                </div>
                            </Link>
                        </div>
                    </div>
                }
                <div className='fasciaLux'>
                    <div className='img'>
                        <img src={require('../../styles/images/lux.png')}/>
                    </div>
                    <div className='description'>
                        <div className='title'>
                            LOOKING FOR MORE?
                        </div>
                        <div className='question'>
                            Discover our website dedicated to luxury products?
                        </div>
                        <div className='testo'>
                            lux.markagain.it is the first platform for the management and resale of pre-owned luxury assets
                        </div>
                        <a href={process.env.REACT_APP_LUX_URL} target="_blank">
                            <div className='btn'>
                                Find out more
                            </div>
                        </a>
                    </div>
                </div>
                <div className='fasciaReady'>
                    <div className='description'>
                        <span className='title'>Are you ready to start?</span>
                        <span className='subtitle'>Request your login credential, enter your personal area and start browsing our portfolios. </span>
                        <Link to='/signup'><span className='getStart'>Get started!</span></Link>
                    </div>
                </div>
                <div className='fasciaHelp'>
                    <div className='description'>
                        <div className='question'>
                            Do you need help?
                        </div>
                        {window.innerWidth>500?
                            <div className='testo' onClick={this.writeMail}>
                                <span>Write Us an email</span>
                            </div>
                            :
                            <div className='testo' onClick={this.writeMail}>
                               <span>Write Us an email</span>
                            </div>
                        }
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Home
