import React, { Component } from 'react'
import  CheckBox  from './CheckBox'
import {FaChevronDown} from 'react-icons/fa'

class FilterBox extends Component {

    addCheckBox(){
        return this.props.filtro.map((value, index) => {
            if(value != "" && value != null){
                return <CheckBox key={index} value={value} {...this.props} />
            }
        })

    }

    collapseBOX(event){
        if(event.target.id === "freccia"){
            event.target.classList.toggle('active');
        }else{
            event.target.children.freccia.classList.toggle('active');
        }

    }

    render() {
        if(this.props.filtro === undefined){
            return false
        }else{
            return (
                <div className='filterBox'>
                    <div className='boxCollapse'>
                        <h5 className='label'>{this.props.label}</h5>
                        <a onClick={this.collapseBOX} className="filterCollapse" data-toggle="collapse" href={`#collapse${this.props.id}`} aria-expanded="false" aria-controls={`#collapse${this.props.id}`}>
                            <div id='freccia'/>
                        </a>
                    </div>
                    <div className="collapse show" id={`collapse${this.props.id}`}>
                        <div className='disable'/>
                        {this.addCheckBox()}
                    </div>
                </div>
            );
        }
    }
}



export default FilterBox