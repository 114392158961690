import React from 'react';
import Loader from 'react-loader-spinner'

class Spinner extends React.Component {
    render() {
        return(
            <Loader
                type="Oval"
                color="#008996"
                height={60}
                width={60}
            />
        );
    }
}
export default Spinner;
