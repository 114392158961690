import React from "react";
import 'toasted-notes/src/styles.css';
import {ReactComponent as IClose} from '../../styles/svg/window-close.svg';


export class Tag extends React.Component {

    capitalize = (str) => {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {
        return (
            <div className='tag'>

                {this.props.type === 'debtCategory' ?
                    <div onClick={this.props.resetFiltroCheck.bind(this,this.props.value)}>
                        <IClose fill='#7d299f' width='9px'/>
                        <span>Debt Category: </span>{this.props.value}
                    </div>
                    :''
                }
                {this.props.type === 'borrowerType' ?
                    <div onClick={this.props.resetFiltroCheck.bind(this,this.props.value)}>
                        <IClose fill='#7d299f' width='9px'/>
                        <span>Borrower Type: </span>{this.props.value}
                    </div> : ''
                }
                {this.props.type === 'assetType' ?
                    <div onClick={this.props.resetFiltroCheck.bind(this,this.props.value)}>
                        <IClose fill='#7d299f' width='9px'/>
                        <span>Asset Type: </span>{this.props.value}
                    </div> : ''
                }
                {this.props.type === 'hashtag' ?
                    <div onClick={this.props.resetFiltroHashtag}>
                        <IClose fill='#7d299f' width='9px'/>
                        <span>Name Hashtag: </span>{this.capitalize(this.props.value)}
                    </div> : ''
                }
                {this.props.type === 'gbvValues' ?
                    <div onClick={this.props.resetFiltroRange}>
                        <IClose fill='#7d299f' width='9px'/>
                        <span>GBV: </span>{`${this.props.value.min}Mln€ - ${this.props.value.max}Mln€`}
                    </div> : ''
                }
                {this.props.type === 'onlyMonoposizione' ?
                    <div onClick={this.props.resetFiltroMonoPosition}>
                        <IClose fill='#7d299f' width='9px'/>
                        <span>Portfolio: </span>{this.props.value}
                    </div> : ''
                }
                {this.props.type === 'luogoAsset' ?
                    <div onClick={this.props.resetFiltroLuogoAsset}>
                        <IClose fill='#7d299f' width='9px'/>
                        <span>Asset location: </span>{this.props.value}
                    </div> : ''
                }
                {this.props.type === 'residenzaDebitori' ?
                    <div onClick={this.props.resetFiltroDebitori}>
                        <IClose fill='#7d299f' width='9px'/>
                        <span>Borrower location: </span>{this.props.value}
                    </div> : ''
                }
                {this.props.type === 'procedimentiInCorsoValues' ?
                    <div onClick={this.props.resetFiltroCheck.bind(this,this.props.inputName)}>
                        <IClose fill='#7d299f' width='9px'/>
                        <span>Current Procedure: </span>{this.props.value}
                    </div> : ''
                }
            </div>
        )
    }
}
