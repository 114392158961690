import React from 'react';
import { ReactComponent as IList } from '../../styles/svg/list-ul.svg';
import { ReactComponent as IMap } from '../../styles/svg/map-marker-alt.svg';
import { ReactComponent as ISignal } from '../../styles/svg/signal-4.svg';
import { ReactComponent as ITh } from '../../styles/svg/th.svg';
import { ReactComponent as IExclamation } from '../../styles/svg/exclamation-circle.svg';
import {Link} from 'react-router-dom';

import '../../styles/components/guida.css'


class Guida extends React.Component{
    componentDidMount() {
        document.getElementById('body').classList.add('big');
        if (window.innerWidth > 500){
            document.getElementById('question').style.display = 'none'
        }else{
            document.getElementById('body').classList.add('public');
        }
        var acc = document.getElementsByClassName("item");
        var i;
        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function() {
                this.parentNode.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            });
        }
    }

    componentWillUnmount() {
        document.getElementById('body').classList.remove('big');
        if (window.innerWidth > 500){
            document.getElementById('question').style.display = 'block';
        }else{
            document.getElementById('body').classList.add('public');
        }
    }

    collapseBOX(event){
        Array.from(document.getElementsByClassName('itemCollapse')).forEach((link) => {
            link.classList.remove('blu');
        });
        event.target.classList.add('blu')

    }

    render() {
    return (
        <div id='guida'>
            <div className='fasciaBanner'>
                <div className='des'>
                    <div className='ti'>
                        How to use Cerved <br/>
                        Markagain platform.
                    </div>
                    <div className='text'>
                        Everything you need to know about using Cerved Markagain.
                    </div>
                </div>
                <div className='image'>
                    <img src={require('../../styles/images/home.png')}/>
                </div>
            </div>
            <div className='fasciaDesc'>
                <div className='des'>
                    <div className='text'>
                        Cerved Markagain provide a <b>wide variety of selection</b> of portfolios. Portfolios have been published after a process of Data remediation of data tape provided by the seller. This offers to you a <b>complete and up-to-date information</b> for the proper valuation of portfolios.
                    </div>
                    <div className='text'>
                        Portfolios can be a <b>single name</b>, composed by only one borrower, or a cluster of borrowers.
                    </div>
                </div>
            </div>
            <div className='fasciaBrowse'>
                {window.innerWidth < 500 ?
                    <div className='des'>
                        <div className='ti'>
                            Browse and filter portfolios.
                        </div>
                    </div> : ''
                }
                {window.innerWidth < 500 || window.innerWidth > 1024?
                    <div className='image'>
                        <img src={require('../../styles/images/portfolioFilter.png')}/>
                    </div>:''
                }
                <div className='des'>
                    {window.innerWidth > 500?
                        <div className='ti'>
                            Browse and filter portfolios.
                        </div>:''
                    }
                    <div className='text'>
                        {window.innerWidth > 500 && window.innerWidth <= 1024?
                            <div className='image'>
                                <img src={require('../../styles/images/portfolioFilter.png')}/>
                            </div>:''
                        }
                        You can find the portfolio of your interest using advanced search filters that permit to carry out targeted searches.<br/>
                        In addition to standard filters such as GBV, you can restrict the research results using characteristics of the debts composing portfolios, such as debt or borrower category, borrower or assets location and current procedures.<br/>
                        By using these filters you can extract portfolios in which at least one of the borrowers matches the research parameters. Furthermore, using the “Only” filter you can extract the portfolios that are composed only by borrowers that match all the search filter.<br/>
                        For example, if you are searching a portfolio that contains at least one secured borrower, select the “Secured” filter. On the other hand, if you are looking for a portfolio composed only by secured borrowers, you need to select the “Secured filter” and activate the “Only” filter.<br/>

                    </div>
                    <div className='link'>
                        <Link to='/search/portfolios'>Start exploring our portfolios</Link>
                    </div>
                </div>
            </div>
            <div className='fasciaExplore'>
                <div className='des'>
                    <div className='ti'>
                        4 different ways to explore.
                    </div>
                    <div className='text'>
                        {window.innerWidth > 500 && window.innerWidth <= 1024?
                            <div className='image'>
                                <img src={require('../../styles/images/positions.png')}/>
                            </div>:''
                        }
                        Cerved Markagain provides different views of each portfolio using the data set of the borrowers that set up them. These features allow you to analyze and evaluate each portfolio according to different perspectives.
                    </div>
                    {window.innerWidth < 500?
                        <div className='image'>
                            <img src={require('../../styles/images/positions.png')}/>
                        </div>:''
                    }
                    <div className='ways' id='expWays'>
                        <div className='type' id='expCollPort'>
                            <div className='exploreCollapse' id='expCollPort'>
                                <a onClick={this.collapseBOX} className="itemCollapse" data-toggle="collapse" href='#collapsePort' aria-expanded="true" aria-controls='collapsePort'>
                                    <ITh fill='#fefefe' width='16px'/> Portfolios
                                </a>
                            </div>
                            <div className="collapse testo" id='collapsePort' aria-labelledby="expCollPort" data-parent="#expWays">
                                In the portfolio view are displayed the summary data of portfolios
                            </div>
                        </div>
                        <div className='type' id='expCollPos'>
                            <div className='exploreCollapse'>
                                <a onClick={this.collapseBOX} className="itemCollapse" data-toggle="collapse" href='#collapsePos' aria-expanded="true" aria-controls='collapsePos'>
                                    <IList fill='#fefefe' width='16px'/> Borrowers
                                </a>
                            </div>
                            <div className="collapse testo" id='collapsePos' aria-labelledby="expCollPos" data-parent="#expWays">
                                In the “Borrowers” view is provided a summary data of all the borrowers. For each borrower you can review online details
                            </div>
                        </div>
                        <div className='type' id='expCollMap'>
                            <div className='exploreCollapse'>
                                <a onClick={this.collapseBOX} className="itemCollapse" data-toggle="collapse" href='#collapseMap' aria-expanded="true" aria-controls='collapseMap'>
                                    <IMap fill='#fefefe' width='16px'/> Map
                                </a>
                            </div>
                            <div className="collapse testo" id='collapseMap' aria-labelledby="expCollMap" data-parent="#expWays">
                                In the “Map” view you can see the geolocation of the borrower and of the assets of all the borrowers
                            </div>
                        </div>
                        <div className='type' id='expCollStrats'>
                            <div className='exploreCollapse'>
                                <a onClick={this.collapseBOX} className="itemCollapse" data-toggle="collapse" href='#collapseStrats' aria-expanded="true" aria-controls='collapseStrats'>
                                    <ISignal fill='#fefefe' width='16px'/> Strats
                                </a>
                            </div>
                            <div className="collapse testo" id='collapseStrats' aria-labelledby="expCollStrats" data-parent="#expWays">
                                In the “Strats” view data aggregation is graphically represented by using specific characteristics of the debts such as debt category, asset type, asset location
                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 1024?
                    <div className='image'>
                        <img src={require('../../styles/images/positions.png')}/>
                    </div>:''
                }
            </div>
            <div className='fasciaReview'>
                {window.innerWidth > 500?
                    <div className='image'>
                        <img src={require('../../styles/images/portafoglio.png')}/>
                    </div>:''
                }
                <div className='des'>
                    <div className='ti'>
                        Review credit.
                    </div>
                    <div className='text'>
                        Cerved Markagain allows you to review in real time the <b>complete and above all up-to-date information</b> of each portfolio and borrower.
                        {window.innerWidth < 500?
                            <div className='image'>
                                <img src={require('../../styles/images/portafoglio.png')}/>
                            </div>:''
                        }
                    </div>
                    <div className='ways' id='expReview'>
                        <div className='type' id='expCollPortRev'>
                            <div className='exploreCollapse'>
                                <a onClick={this.collapseBOX} className="itemCollapse" data-toggle="collapse" href='#collapsPortRev' aria-expanded="true" aria-controls='collapseMap'>
                                    <ITh fill='#000' width='16px'/> Portfolio details
                                </a>
                            </div>
                            <div className="collapse testo" id='collapsPortRev' aria-labelledby="expCollPortRev" data-parent="#expReview">
                                <p>In the portfolio page it is possible to:</p>
                                <p>review the summary data of the selected portfolio (status, type,  overall GBV, number of borrowers, etc.)</p>
                                <p>request generic information or portfolio data tape</p>
                                <p>add the portfolio to your “Indications of interest”</p>
                                <p>view the borrowers composing the portfolio (tab “Borrowers”)</p>
                                <p>geolocalize the borrower and the assets of the borrowers (tab “Map”)</p>
                                <p>review data based on different parameters (tab “Strats”)</p>
                                <p>Select the portfolio card in the Home Page or in the "Portfolios" to review the portfolio data</p>
                            </div>
                        </div>
                        <div className='type' id='expCollPosRev'>
                            <div className='exploreCollapse'>
                                <a onClick={this.collapseBOX} className="itemCollapse" data-toggle="collapse" href='#collapsePosRev' aria-expanded="true" aria-controls='collapseStrats'>
                                    <IList fill='#000' width='16px'/> Borrower details
                                </a>
                            </div>
                            <div className="collapse testo" id='collapsePosRev' aria-labelledby="expCollPosRev" data-parent="#expReview">
                                <p>In the borrower sheet you can view detailed data of the selected debt case and request further information to the team of specialists at your disposal.</p>
                                <p>To view the details of a borrower, you have to access the portfolio of your interest and select or the specific borrower in the “Borrowers” view or press the link in a pin of the “Map” view.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='fasciaRequest'>
                {window.innerWidth<500?
                    <div className='image'>
                        <img src={require('../../styles/images/messaggi_mobile.png')}/>
                    </div>:''
                }
                <div className='des'>
                    <div className='ti'>
                        Request more info and Data Tape.
                    </div>
                    <div className='text'>
                        <p>You can require data tape to review portfolios valuation analysis performed by Cerved team of specialists or you can request information. Each portfolio has its own Cerved reference that will take in charge your request and recontact you as soon as possible.</p>
                        <p>To require a portfolio data tape, press the “Request Data Tape” button in the portfolio page.</p>
                        <p>To request information on a portfolio press the “Request info” button in the portfolio page. To require information on a specific borrower, press the “Request info” button in the borrower sheet .</p>
                        <p>The requests submitted to Cerved will be shown in the “Messages” section. At the end of each communication a document of the communication thread is created. This document is notarized in blockchain.</p>
                    </div>
                    <div className='link'>
                        <Link to='/messages'>Go to Messagges</Link>
                    </div>
                    <div className='box'>
                        <div className='content'>
                            <IExclamation fill='#FFF' width='80px'/>
                            <div className='info'>
                                <div>Notarization (or "legalization") of a document is the procedure that attested the originality and existence at a certain date and time of the document itself.</div>
                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth>500?
                    <div className='image'>
                        <img src={require('../../styles/images/messaggi.png')}/>
                    </div>:''
                }
            </div>

            {window.innerWidth > 1024 ?
                <div className='fasciaMark'>
                    <div className='image'>
                        <img src={require('../../styles/images/carrello.png')}/>
                    </div>
                    <div className='des'>
                        <div className='ti'>
                            Mark for interest.
                        </div>
                        <div className='text'>
                            <p>If you are interested to one or more portfolio you can send to Cerved an “Indications of
                                interest” and you will be contacted by our specialists.</p>
                            <p>Press the "Add to your indications of interest“ button in the portfolio page to insert a
                                portfolio in your indications of interest.</p>
                            <p>To send your indications of interest you must access the "My indications of interest"
                                section (header page) and press the "Send" button.</p>
                            <p>If you want to remove a portfolio from your manifestations of interest, press “Remove" on
                                the portfolio card you want to remove.</p>
                        </div>
                        <div className='link'>
                            <Link to='/indications-of-interest'>Go to My Indications of interest</Link>
                        </div>
                    </div>
                </div>

                :
                <div className='fasciaMark'>
                    <div className='des'>
                        <div className='ti'>
                            Mark for interest.
                        </div>
                    </div>
                    <div className='image'>
                        <img src={require('../../styles/images/carrello.png')}/>
                    </div>
                    <div className='des'>
                        <div className='text'>
                            <p>If you are interested to one or more portfolio you can send to Cerved an “Indications of
                                interest” and you will be contacted by our specialists.</p>
                            <p>Press the "Add to your indications of interest“ button in the portfolio page to insert a
                                portfolio in your indications of interest.</p>
                            <p>To send your indications of interest you must access the "My indications of interest"
                                section (header page) and press the "Send" button.</p>
                            <p>If you want to remove a portfolio from your manifestations of interest, press “Remove" on
                                the portfolio card you want to remove.</p>
                        </div>
                        <div className='link'>
                            <Link to='/indications-of-interest'>Go to My Indications of interest</Link>
                        </div>
                    </div>
                </div>
            }

            {/*<div className='fasciaFrequently'>*/}
            {/*    <div className='ask'>*/}
            {/*        <div className='title'>*/}
            {/*           Frequently asked question*/}
            {/*        </div>*/}
            {/*        <div className='items'>*/}
            {/*            <div className='containerItem'>*/}
            {/*                <div className="item">*/}
            {/*                    <div className='testo'>Lorem ipsum dolor sit amet?</div>*/}
            {/*                    <div className='plus'>*/}
            {/*                        <IPlus fill='#008996' width='16px'/>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="panel">*/}
            {/*                    <div className='testo'>*/}
            {/*                        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.*/}
            {/*                    </div>*/}
            {/*                    <div className='testo'>*/}
            {/*                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?*/}
            {/*                    </div>*/}
            {/*                    <div className='testo'>*/}
            {/*                        Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className='containerItem'>*/}
            {/*                <div className="item">*/}
            {/*                    <div className='testo'>Lorem ipsum dolor sit amet?</div>*/}
            {/*                    <div className='plus'>*/}
            {/*                        <IPlus fill='#008996' width='16px'/>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="panel">*/}
            {/*                    <div className='testo'>*/}
            {/*                        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.*/}
            {/*                    </div>*/}
            {/*                    <div className='testo'>*/}
            {/*                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?*/}
            {/*                    </div>*/}
            {/*                    <div className='testo'>*/}
            {/*                        Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className='containerItem'>*/}
            {/*                <div className="item">*/}
            {/*                    <div className='testo'>Lorem ipsum dolor sit amet?</div>*/}
            {/*                    <div className='plus'>*/}
            {/*                        <IPlus fill='#008996' width='16px'/>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="panel">*/}
            {/*                    <div className='testo'>*/}
            {/*                        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.*/}
            {/*                    </div>*/}
            {/*                    <div className='testo'>*/}
            {/*                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?*/}
            {/*                    </div>*/}
            {/*                    <div className='testo'>*/}
            {/*                        Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className='containerItem'>*/}
            {/*                <div className="item">*/}
            {/*                    <div className='testo'>Lorem ipsum dolor sit amet?</div>*/}
            {/*                    <div className='plus'>*/}
            {/*                        <IPlus fill='#008996' width='16px'/>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="panel">*/}
            {/*                    <div className='testo'>*/}
            {/*                        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.*/}
            {/*                    </div>*/}
            {/*                    <div className='testo'>*/}
            {/*                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?*/}
            {/*                    </div>*/}
            {/*                    <div className='testo'>*/}
            {/*                        Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className='end'>
                <div className='ancora'>
                    Couldn’t find what you were looking for?
                </div>
                <div className='assistenza'>
                    Request assistance
                </div>
            </div>
        </div>
    );
    }
}
export default Guida
