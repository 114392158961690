import React, {Component} from 'react';
import {Count} from "./Count";
import * as actions from "../../actions";
import {connect} from "react-redux";

class SearchItemsCount extends Component {
    constructor(props){
        super(props)
    }

    searchCount(){
        return (
            <div className='searchItemsCount'>
                <span>{this.props.packs.length}</span> of <span><Count /> portfolios</span>  match your filters:
            </div>
        );
    }

    render() {
        return (
           <span>{this.searchCount()}</span>
        )
    }
}

function mapStateToProps(state){
    return{
        packs: state.packs.data
    }
}

export default connect(mapStateToProps)(SearchItemsCount);

