import React, { Component } from 'react';
import {PackList} from "../packs/PackList";
import PackCarousel from "../packs/PackCarousel";
import * as actions from "../../actions";
import {connect} from "react-redux";

class ViewPrefs extends Component{

    initViewPrefs(){
        if(this.props.packs.length === 0){
            return <section/>
        }else if(window.innerWidth < 500){
            if(this.props.packs.length > 1){
                return <PackCarousel all={this.props} bkg={this.props.bkg} toLink='/favorites'/>
            }else{
                return <PackList all={this.props} bkg={this.props.bkg} toLink='/favorites'/>
            }
        }else if(window.innerWidth >= 768 && window.innerWidth <= 1024){
            if(this.props.packs.length > 2){
                return <PackCarousel all={this.props} bkg={this.props.bkg} toLink='/favorites'/>
            }else{
                return <PackList all={this.props} bkg={this.props.bkg} toLink='/favorites'/>
            }
        }else if(this.props.packs.length <= 4){
            return <PackList all={this.props} bkg={this.props.bkg} toLink='/favorites'/>
        }else{
            return <PackCarousel all={this.props} bkg={this.props.bkg} toLink='/favorites'/>
        }
    }

    componentWillMount() {
        this.props.dispatch(actions.fetchPreferiti());
    }

    render(){
        return(
            this.initViewPrefs()
        );

    }
}

function mapStateToProps(state){

    return{
        packs: state.packs.preferiti
    }
}

export default connect(mapStateToProps)(ViewPrefs)