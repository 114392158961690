import {
    conversazioniReducer,
    packReducer,
    positionReducer,
    selectedPackReducer,
    assetReducer,
    stratsReducer,
    filtriReducer, headerReducer
} from "./pack-reducer";
import thunk from 'redux-thunk';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {sessionReducer}  from 'redux-react-session';

export const init = () => {

    const reducer = combineReducers({
        packs: packReducer,
        pack: selectedPackReducer,
        positions: positionReducer,
        conversations: conversazioniReducer,
        assets: assetReducer,
        strats : stratsReducer,
        session: sessionReducer,
        filtriValues: filtriReducer,
        count: headerReducer,

    });

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

}
