import React from 'react'

class HashTagInput extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('filtroHome') && this.props.matchUrlParameter) {
            const filtroValue = urlParams.get('filtroHome').split('-');
            if (filtroValue[0] === 'hashtag') {
                if(this.refs.hashtag.getAttribute('data-descr') === filtroValue[1]) {
                    this.props.setMatchUrlParameter()
                    this.refs.hashtag.click();
                }
            }
        }
    }

    capitalize = (str) => {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {
        return (
            <div className="radioHashtag">
                <div ref="hashtag"
                     data-descr={this.props.hash.descrizione}
                     className={`circleHashtag ${this.props.selected === this.props.hash.id ? 'selected' : ''}`}
                     onClick={e => this.props.selectHashtag(e, this.props.hash)}></div>
                <h6>{this.capitalize(this.props.hash.descrizione)}</h6>
            </div>
        );
    }
}

export default HashTagInput;
