import React from 'react'
import InputRange from 'react-input-range';

import 'react-input-range/lib/css/index.css';

class InputRangeBox extends React.Component{
    constructor(props) {
        super(props);
        const min = (this.props.filtro!==undefined?this.props.filtro[0]:0);
        const max = (this.props.filtro!==undefined?this.props.filtro[1]:500);
        this.state = {
            value: {
                min: min,
                max: max
            },
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const valMinMax = {
            min: nextProps.filtro[0],
            max: nextProps.filtro[1]
        }
        this.setState(() => {this.state.value = valMinMax});
    }

    refresh = () =>{
        const valMinMax = {
            min: 0,
            max: 500000
        }
        this.setState(() => {this.state.value = valMinMax});
    }

    render() {
        if(this.props.filtro !== undefined){
            const min = this.props.filtro[0];
            const max = this.props.filtro[1];
            return (
                <div className='filterBox gbv'>
                    <h5 className='label'>{this.props.label}</h5>
                    <div className='d-flex check'>
                        <InputRange
                            step={100}
                            maxValue={max}
                            minValue={min}
                            value={this.state.value}
                            formatLabel={value => `${(parseFloat(value)/1000000).toFixed(1)} MLN`}
                            onChange={value => this.setState({ value })}
                            onChangeComplete={value => this.props.handleInputRange(value)}
                        />
                    </div>
                </div>
            )
        }else {
            return false;
        }
    }
}

export default InputRangeBox
