import React, {Component} from "react";
import {MessaggioDettaglio} from "../../pages/messaggi/MessaggioDettaglio";
import axios from "axios";
import {fetchMessaggiById} from "../../actions";
import {connect} from "react-redux";
import {ReactComponent as Attach} from "../../styles/svg/paperclip.svg";
import TextareaAutosize from 'react-autosize-textarea';
import {Get, Map} from "react-lodash";
import {ReactComponent as Close} from "../../styles/svg/window-close.svg";
import {ReactComponent as Alert} from "../../styles/svg/exclamation-circle.svg";

class PackConversation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: '',
            attachments: [],
            allegati: true,
            portfolioName: '',
            positionId: '',
            showError: false,
            errMesg: ""
        };
    }

    componentDidMount() {
        document.getElementById('marketPlace').classList.add('grigio');
    }

    componentWillUnmount() {
        document.getElementById('marketPlace').classList.remove('grigio');
    }

    onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value, errmess: "", showError: false})
    };

    onChangeHandler = event => {

        const target = event.target;
        const name = target.name;
        const file = event.target.files;
        this.setState({[name]: file});
        this.setState(state => ({allegati: !state.allegati}));

    };

    removeAllegato = event => {
        const name = event.currentTarget.parentElement.parentElement.id;
        this.setState({attachments: Array.from(this.state.attachments).filter(item => item.name !== name)});
        this.setState(state => ({allegati: !state.allegati}));
    }

    handleSubmit = (e) => {

        e.preventDefault();
        const messaggio = this.state.payload;
        const conversazioneId = this.props.convId;
        const msgErrorMexMax = "The message field must not be empty or exceed 255 characters";

        if(messaggio.length > 255){
            this.setState({ showError: true, errmess: msgErrorMexMax});
        } else {
            const formData = new FormData();
            formData.append("payload", JSON.stringify({conversazioneId, messaggio}));
            for (var i = 0; i < this.state.attachments.length; i++) {
                formData.append("attachments", this.state.attachments[i]);
            }
            axios.post('/api-mkt/messages/saveMessage', formData).then(() => this.props.dispatch(fetchMessaggiById(this.props.convId)));
            this.setState({payload: () => ''});
            this.setState({attachments: []})
        }

    };

    initPacks() {
        const p = this.props.all;
        return p.map((pack, index) => {
            return <MessaggioDettaglio key={index} colNum='col-md-3 col-xs-6' data={pack} type={pack.type}/>
        });
    }

    render() {

        return (
            <div id="center">
                <div className="col-sm-10 message_section">
                    <div className="row">
                        <div className="new_message_head">
                            <div className="chat_head">
                                {this.props.all && this.props.all.length > 0 ? <span>Requested information for <b>{this.props.all[0].portafoglioDenominazione}</b></span> : ''}
                                {this.props.all && this.props.all.length > 0 && this.props.all[0].posizioneId !== undefined ? <span> and Position <b>{this.props.all[0].posizioneId}</b></span> : ''}
                            </div>
                            <div className='container'>
                                <div id="singlemess" className="d-flex flex-column">
                                    {this.initPacks()}
                                </div>
                            </div>
                        </div>
                        {this.props.all && this.props.all.length > 0 && this.props.all[0].statoConversazione === 'APERTA' ?
                            <div className="message_write">
                                <div id="dataTHelp" style={{ marginTop: '5px' }}>
                                    <span id="alertIcon"> <Alert fill="#008996" width="20px"/></span>
                                    <p id="description">The maximum message length is 255 characters</p>
                                </div>

                                <div className="textandinput">
                                    <TextareaAutosize type="textarea" name="payload" id="textpayloadarea"
                                                      value={this.state.payload || ''} className="form-control"
                                                      onChange={this.onChange} placeholder="Start writing..."/>
                                    <div className="p-2">
                                        {this.state.attachments ?
                                            <div id="containerAllegati"><Map collection={this.state.attachments}
                                                                             iteratee={name => <li id={name.name}><Get
                                                                                 object={name} path={'name'}></Get><span
                                                                                 id="allegatoSize"><Get object={name}
                                                                                                        path={'size'}>{value => (parseFloat(value) / 1000000).toFixed(2)}</Get>MB <Close
                                                                                 onClick={this.removeAllegato.bind(name.id)}
                                                                                 fill="#979da5" width="9px"/> </span>
                                                                             </li>}/></div> : <div></div>}
                                    </div>
                                    <div className="borderUpload">
                                        <input className="file" id="file" multiple name="attachments"
                                               onChange={this.onChangeHandler} onClick={this.submit} type="file"/>
                                    </div>
                                    <label id="uploadButton" type="button btn" name="attachments" htmlFor="file"><Attach
                                        id="icon" height="21px" width="14px"/>Attach files</label>
                                    <div className={`counter ${this.state.payload.length > 255 ? 'error': ''}`}>{this.state.payload.length} / 255</div>
                                </div>
                                <div className="clearfix"></div>

                                {this.state.showError && (
                                    <div className="error">
                                        {this.state.errmess}
                                        <span id="close" className="h4 mb-4" style={{ margin: '0 10px' }}></span>
                                    </div>)
                                }

                                <div className="chat_bottom">
                                    <div className="chatbutton">
                                        <div>
                                            <button disabled={!this.state.payload}
                                                    onClick={this.handleSubmit.bind(this)}>Send
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''
                            
                        }
                    </div>
                </div>
            </div>

        );
    }
}

export default connect()(PackConversation)
