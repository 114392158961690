import React from 'react';
import FiltriBoxHome from './FiltroBoxHome'
import axios from "axios";

class FiltriHome extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            groupedByTipoDiPortafoglioList: [],
            groupedByTipoDiAssetList: []
        }
    }


    componentDidMount() {
        axios.get('/api-mkt/home-boxes/findAll').then(res => {
            this.setState({groupedByTipoDiPortafoglioList:res.data.groupedByTipoDiPortafoglioList,groupedByTipoDiAssetList:res.data.groupedByTipoDiAssetList})
        })
    }

    render() {
        // const filtri = [
        //     {prima:[{count: 13, label: "Portafogli", filtro:"Secured"},
        //             {count: 7, label: "Portafogli", filtro:"Unsecured"},
        //             {count: 20, label: "Portafogli", filtro:"Secured + Unsecured"}]},
        //     {seconda: [
        //             {count: 9, label: "Portafogli con", filtro:"Asset Residenziale"},
        //             {count: 7, label: "Portafogli con", filtro:"Asset Commerciale"},
        //             {count: 12, label: "Portafogli con", filtro:"Asset Industriale"},
        //             {count: 4, label: "Portafogli con", filtro:"Asset Terreno"}
        //         ]}];
        return(
            <section id='filtriHome'>
                <div className='row justify-content-between align-items-center'>
                    <h2 className='page-title'>Start searching by <span className='font-weight-bold'>Debt Category</span>:</h2>
                </div>
                <FiltriBoxHome fascia={this.state.groupedByTipoDiPortafoglioList} filtro='tipoDiCreditoValues'/>
                <div className='row justify-content-between align-items-center'>
                    <h2 className='page-title'>Search by <span className='font-weight-bold'>Asset Type</span>:</h2>
                </div>
                <FiltriBoxHome fascia={this.state.groupedByTipoDiAssetList}  filtro='caratteristicheAssetValues'/>
            </section>
        );
    }
}

export default FiltriHome
