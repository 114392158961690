import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {FaSignOutAlt} from 'react-icons/fa';
import {ReactComponent as IHome} from '../../styles/svg/home.svg';
import {ReactComponent as ITh} from '../../styles/svg/th.svg';
import {ReactComponent as IInfoCircle} from '../../styles/svg/info-circle.svg';
import {ReactComponent as IEnvelope} from '../../styles/svg/envelope.svg';
import {ReactComponent as IUser} from '../../styles/svg/user.svg';
import {ReactComponent as IStar} from '../../styles/svg/star.svg';
import {ReactComponent as IStarSolid} from '../../styles/svg/starSolid.svg';

import 'jquery/dist/jquery.min.js';
import '../../../node_modules/bootstrap/dist/js/bootstrap.min.js';
import {connect} from "react-redux";
import * as actions from "../../actions";
import {confirmAlert} from "react-confirm-alert";


class Header extends Component {

    constructor() {
        super()
    }

    componentWillUnmount() {
        this.unlisten();
    }

    removeCookies() {
        localStorage.clear();

        window.location.replace(
            process.env.REACT_APP_CAS_LOGOUT_URL + window.location.origin
        )
    };


    setActiveLink(event) {
        if (window.innerWidth < 500) {
            document.getElementById('open').classList.toggle('open');
        }
    }

    checkUrl(path) {
        Array.from(document.getElementsByClassName('nav-link')).forEach((el) => {
            el.classList.remove('active');
        });
        if (path.indexOf('search') == 1 || path.indexOf('portfolio-detail') == 1 || path.indexOf('position') == 1) {
            document.getElementById('portfolioHeader').classList.add('active');
        } else if (path.indexOf('site-guide') == 1) {
            document.getElementById('guidaHeader').classList.add('active');
        } else if (path.indexOf('messages') == 1) {
            document.getElementById('messageHeader').classList.add('active');
        } else if (path.indexOf('favorites') == 1) {
            document.getElementById('favoritesHeader').classList.add('active');
        } else if (path.indexOf('indications-of-interest') == 1) {
            document.getElementById('cartHeader').classList.add('active');
        } else if(path.indexOf('home')==1){
            document.getElementById('homeHeader').classList.add('active');
        }
        if(window.location.hash.includes('#debGuar') ||
            window.location.hash.includes('#loans') ||
            window.location.hash.includes('#warranties') ||
            window.location.hash.includes('#procedures') ||
            window.location.hash.includes('#realEstate') ||
            window.location.hash.includes('#documents')){
            return false;
        }else{
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    componentDidMount() {
        this.checkUrl(this.props.location.pathname);
        this.unlisten = this.props.history.listen((location, action) => {
            this.checkUrl(location.pathname);
        });
        this.props.dispatch(actions.messagesPresent());
        this.props.dispatch(actions.countIndicationOfInterest());
        this.props.dispatch(actions.favoritesPresent());
    }

    hide = () => this.setState({open: false, callback: null});


    submit = () => {
        confirmAlert({

            title: <div>
                <div id="image"></div>
                <br/>Are you sure you want to quit?</div>,
            message: '',
            buttons: [
                {
                    label: 'Log Out',
                    onClick: () => this.removeCookies()
                },
                {
                    label: 'Cancel',
                    onClick: () => this.hide()
                }
            ]
        });
    };

    openMenu() {
        document.getElementById('open').classList.toggle('open');
    }

    render() {
        if (window.innerWidth > 500) {
            return (
                <nav id='header' className='navbar'>
                    <div className='container'>
                        <div className='' id='navbarSupportedContent'>
                            <ul className='navbar-nav'>
                                <li className='nav-item'>
                                    <Link onClick={this.setActiveLink} className='navbar-brand' to='/home'><img
                                        src={require('../../styles/images/header.png')}/></Link>
                                </li>
                                <span className='separate'/>
                            </ul>
                            <ul className='navbar-nav'>
                                <li className='nav-item'>
                                    <Link onClick={this.setActiveLink} id='homeHeader' className='nav-link active'
                                          to='/home'><IHome
                                        fill="#979da5"/> Home</Link>
                                </li>
                                <li className='nav-item'>
                                    <Link onClick={this.setActiveLink} id='portfolioHeader' className='nav-link'
                                          to='/search/portfolios'><ITh
                                        fill="#979da5"/> Portfolio </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link onClick={this.setActiveLink} id='guidaHeader' className='nav-link'
                                          to='/site-guide'><IInfoCircle fill="#979da5"/> Site Guide</Link>
                                </li>
                                <li className='nav-item'>
                                    <Link onClick={this.setActiveLink} id='messageHeader' className='nav-link'
                                          to='/messages'><IEnvelope
                                        fill="#979da5"/> Messagges{this.props.messagesPresent ?
                                        <span className='newMex'></span> : ''}</Link>
                                </li>
                            </ul>
                            <ul className='navbar-nav'>
                                <li className='dropdown nav-item user'>
                                    <span className='separate'/>
                                    <a className='dropdown-toggle nav-link' data-toggle="dropdown" href='#'><IUser
                                        fill="#979da5"/> Profile</a>
                                    <ul className="dropdown-menu">
                                        <li><a href='#' onClick={this.submit} className="dropdown-item"><FaSignOutAlt
                                            fill="#979da5"/>Sign Out</a></li>
                                    </ul>
                                </li>
                                <li className='nav-item'>
                                    <Link onClick={this.setActiveLink} id='favoritesHeader' className='nav-link' to='/favorites'>
                                        {this.props.favoritesPresent ? <IStarSolid fill="#007e8a"/> :
                                            <IStar fill="#007e8a"/>}Favorites</Link>
                                </li>
                                <li className='nav-item carrello'>
                                    <Link onClick={this.setActiveLink} id='cartHeader' className='nav-link'
                                          to='/indications-of-interest'>
                                        <div className='circleCount'>{this.props.countInterest}</div>
                                        My indications of interest</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            );
        } else {
            return (
                <nav id='headerMobile'>
                    <div className='fixedMenu'>
                        <div className='nav-item btnMenu' onClick={this.openMenu}>
                            {this.props.countMessages ?<span className='newMex'></span> : ''}
                            <img src={require('../../styles/images/openMenu.png')}/>
                        </div>
                        <div className='nav-item'>
                            <Link className='navbar-brand' to='/home'><img
                                src={require('../../styles/images/header.png')}/><span></span></Link>
                        </div>
                        <div className='prefCar'>
                            <div className='nav-item'>
                                <Link id='favoritesHeader' className='nav-link' to='/favorites'>{this.props.favoritesPresent ? <IStarSolid fill="#007e8a"/> :
                                    <IStar fill="#007e8a"/>}</Link>
                            </div>
                            <div className='nav-item carrello'>
                                <Link id='cartHeader' className='nav-link' to='/indications-of-interest'>
                                    <div className='circleCount'>{this.props.countInterest}</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div id='open' className='expand'>
                        <div className='nav-item'>
                            <Link onClick={this.setActiveLink} id='homeHeader' className='nav-link active' to='/home'><IHome
                                fill="#979da5"/><span>Home</span></Link>
                        </div>
                        <div className='nav-item'>
                            <Link onClick={this.setActiveLink} id='portfolioHeader' className='nav-link' to='/search/portfolios'><ITh
                                fill="#979da5"/><span>Portfolio</span></Link>
                        </div>
                        <div className='nav-item'>
                            <Link onClick={this.setActiveLink} id='guidaHeader' className='nav-link' to='/site-guide'><IInfoCircle
                                fill="#979da5"/><span>Site Guide</span></Link>
                        </div>
                        <div className='nav-item'>
                            <Link onClick={this.setActiveLink} id='messageHeader' className='nav-link' to='/messages'><IEnvelope
                                fill="#979da5"/><span>Messagges</span></Link>
                        </div>
                        <div className='help'>
                            <span>Do you need help?</span>
                        </div>
                        <div className='logout' onClick={this.submit}>
                            <span>Logout</span>
                        </div>
                    </div>
                </nav>
            )
        }
    }

}

function mapStateToProps(state) {

    return {
        countInterest: state.count.dataInterest,
        messagesPresent: state.count.dataMessages,
        favoritesPresent: state.count.dataFavorites
    }
}

export default withRouter(connect(mapStateToProps)(Header));
