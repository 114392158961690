import React from 'react';
import {Link} from "react-router-dom";
import CookiePolicy from '../../shared/components/CookiePolicy';
import PrivacyPolicy from '../../shared/components/PrivacyPolicy';

export function Footer(props){
    return (
        <div className='footer'>
            <div className='logoFooter'>
                <Link to='/'><img src={require('../../styles/images/header.png')}/></Link>
            </div>
            <div className='info'>
                <div className='capitale'>
                    @2020 Cerved Credit Management Group S.r.l. Via dell’Unione Europea, 6A-6B - 20097 San Donato M.se - R.E.A. 1966396<br/>
                    Capitale Sociale: € 56.098,72 -  C.F. e P.I. IT07549040967 - Società diretta e coordinata da Cerved Group S.p.A.
                </div>
                <div className="link">
                    <div className='privacy'>
                        <PrivacyPolicy />
                    </div>
                    &nbsp;&amp;&nbsp; 
                    <div className='privacy'>
                        <CookiePolicy />
                    </div>
                    <div className='lux'>
                        <a href={process.env.REACT_APP_LUX_URL} target="_blank">lux.markagain.it</a>
                    </div>
                </div>
            </div>
        </div>
    );
}