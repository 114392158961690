import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import {FaStar, FaRegStar, FaImage, FaTrashAlt} from 'react-icons/fa';
import '../../styles/search/mainSearch.css'
import '../../styles/components/carrello.css'
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as actions from "../../actions";
import {connect} from "react-redux";
import ViewViewed from "../../shared/views/ViewViewed";
import {Link} from "react-router-dom"; // Import css


class Carrello extends Component{

    remove (id){
        this.props.dispatch(actions.removeCarrello(id));
    }
    hide = () => this.setState({ open: false, callback: null });

    submit = () => {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,

             title: <div ><div id="image"></div> <br/>Are you sure you want to remove this portfolio from your indications of interest?</div>,
             message: '',
             buttons: [
                 {
                     label: 'Yes, remove',
                     onClick: ()=> this.remove(this.props.data.id)

                 },
                 {
                     label: 'Cancel',
                     onClick: () => this.hide()
                 }
             ]
         });
     };

    addVisti(id){
        this.props.dispatch(actions.addVisti(id));
    }

    addPref(id) {
        this.props.dispatch(actions.addPreferiti(id))
    }

    componentDidMount(){
        if(window.innerWidth > 500) {
            if (window.innerHeight > document.getElementById('root').clientHeight) {
                document.getElementById('question').classList.remove('fixed')
            } else {
                document.getElementById('question').classList.add('fixed')
            }
        }
    }

     render() {
         const mystyle = {
             overflow: "hidden",
             display: "-webkit-box",
             "-webkit-line-clamp": "2",
             "-webkit-box-orient": "vertical"
         };
        return (
            <div className={this.props.colNum}>
                <div className='card containerPack'>
                    <div className='stato'>
                        <div className='statoData'>
                            <h6 className={`tipo ${(this.props.data.stato==='Offer in evaluation' || this.props.data.stato==='On sale') ?'onSale':''}`}><span className='point'/>{this.props.data.stato}</h6>
                        </div>
                        <div className='star' onClick={this.addPref.bind(this, this.props.data.id)}>
                            {this.props.data.preferred ? <FaStar/> : <FaRegStar/>}
                        </div>
                    </div>
                    <Link onClick={this.addVisti.bind(this, this.props.data.id)} to={`/portfolio-detail/${this.props.data.id}/positions`} style={{ textDecoration: 'none' , color: 'black'}}>
                        <div className='titolo'>
                            <h2 style={mystyle}>{this.props.data.denominazione}</h2>
                            <div className='desc'>
                                <span className='data'>
                                    {
                                        this.props.data.dataInizioValid!=='' && this.props.data.dataFineValid!==''?
                                            `Available from ${this.props.data.dataInizioValid} to ${this.props.data.dataFineValid}`:
                                            this.props.data.dataInizioValid!==''?`Available from ${this.props.data.dataInizioValid}`:
                                                this.props.data.dataFineValid!==''?`Expires in ${this.props.data.dataFineValid}`:''
                                    }
                                </span>
                                {
                                    this.props.data.mandante &&
                                    <h6>By {this.props.data.mandante}</h6>
                                }
                                    <h6 style={mystyle}>{this.props.data.descrizione}</h6>
                                </div>
                            </div>
                        <div className='gbv'>
                            <h3>{(parseFloat(this.props.data.gbv)/1000000).toFixed(1)}</h3><h6>Mln € GBV</h6>
                        </div>
                        <div className='categoria'>
                            {
                                (this.props.data.nSecured>0?
                                        this.props.data.nSecured==1 && this.props.data.nUnsecured==0?<h5 className='secured'><span>Secured</span><span>SINGLE-NAME</span></h5>:
                                            <h5 className='secured'><span>Secured</span><span>{this.props.data.nSecured}</span></h5>
                                        :''
                                )
                            }
                            {
                                (this.props.data.nUnsecured>0?
                                        this.props.data.nUnsecured==1 && this.props.data.nSecured==0?<h5 className='unsecured'><span>Unsecured</span><span>SINGLE-NAME</span></h5>:
                                            <h5 className='unsecured'><span>Unsecured</span><span>{this.props.data.nUnsecured}</span></h5>
                                        :''
                                )
                            }

                        </div>
                    </Link>
                        <div id="border">
                            <div id="remove" onClick={this.submit}><FaTrashAlt/><span id="elimina">  Remove</span></div>
                        </div>
                    </div>
            </div>
    );
    }
}


export default connect()(Carrello)
