import React from 'react';
import {Link} from "react-router-dom";
import { ReactComponent as ILogin } from '../../styles/svg/external-link-square.svg';
import { ReactComponent as IAsterisk } from '../../styles/svg/asterisk.svg';
import {confirmAlert} from "react-confirm-alert";
import axios from "axios";
import '../../styles/areaPubblica/buyer.css';
import '../../styles/areaPubblica/privacy.css';
import { Footer } from './Footer';
import { PrivacyCookiePolicy } from '../../shared/components/PrivacyCookiePolicy';


const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

class Signup extends React.Component{
    constructor(){
        super();
        this.state = {
            nome: null,
            cognome: null,
            azienda: null,
            email: null,
            disclaimer: false,
            privacy: false,
            marketing: false,
            personalData: false,
            subject: '',
            other: '',
            validMailList: [],
            invalidMail: '',
            errorDomain: false,
            errors: {
                nome: '',
                cognome: '',
                azienda: '',
                email: '',
                disclaimer: '',
                privacy: '',
                marketing: ''
            },
            enableDisclaimer: true,
            enablePrivacy: false,
            enableSubject: false,
            enableOther: false
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'nome':
                errors.nome =
                    value.length === 0
                        ? 'This field is required.'
                        : '';
                break;
            case 'cognome':
                errors.cognome =
                    value.length === 0
                        ? 'This field is required.'
                        : '';
                break;
            case 'azienda':
                errors.azienda =
                    value.length === 0
                        ? 'This field is required.'
                        : '';
                break;
            case 'email':
                errors.email =  this.errorEmail(value);
                break;
            default:
                break;
        }

        this.setState({errors, [name]: value});
    }

    errorEmail(value){

        this.setState({invalidMail: '', errorDomain: false});

        if(value.length === 0){
            return 'This field is required.';
        } else if(!validEmailRegex.test(value)){
            return 'Email is not valid!'
        } else if(value.length > 255){
            return 'Invalid input, email too long'
        } else if(!this.validateMail(value)){

            var invalidMail = ''
            var invalidMailTrunck = ''
            Array.from(this.state.validMailList).forEach((l, index, arr) => {
                invalidMail = invalidMail.concat(l);

                if(index===4){
                    invalidMailTrunck = invalidMail.concat('...)')
                }
    
                if(index < arr.length-1 ){
                    invalidMail = invalidMail.concat(', ')
                }
            });

            this.setState({invalidMail: invalidMail, errorDomain: true});

            if(invalidMailTrunck.length>0){
                return 'Attention, it is not possible to request accreditation via personal email addresses or PEC (not valid: ' + invalidMailTrunck;
            } else {
                return 'Attention, it is not possible to request accreditation via personal email addresses or PEC (not valid: ' + invalidMail +')';
            }

        } else {
            return '';
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if(validateForm(this.state.errors)) {
            const nome = this.state.nome;
            const cognome = this.state.cognome;
            const azienda = this.state.azienda;
            const email = this.state.email;
            const disclaimerFlag = this.state.disclaimer;
            const privacyFlag = this.state.privacy;
            const marketingFlag = this.state.marketing;
            const personalDataFlag = this.state.personalData;
            const soggettoAbilitato = this.state.subject;
            const soggettoAbilitatoOther = this.state.other;

            const data = {
                nome, cognome, azienda, email, disclaimerFlag, privacyFlag, marketingFlag, personalDataFlag, soggettoAbilitato, soggettoAbilitatoOther
            }

            axios.post('/api-public/subscription/addUser', data).then(resp => this.confirm())
            console.info('Valid Form')
        }else{
            console.error('Invalid Form')
        }
    }

    validateMail(mail){
        var mailValida = true;
        var domMail = mail.substring(mail.indexOf('@'));
    
        Array.from(this.state.validMailList).forEach((l) => {
            if(l.toString().toUpperCase() === domMail.trim().toUpperCase()){
                mailValida = false;
            }
        });

        return mailValida;
    }


    confirm = () => {
        document.getElementById('form').classList.add('hidden');
        document.getElementById('conferma').classList.add('show');
    };


    componentDidMount() {
        document.getElementById('body').classList.add('big');
        if(window.innerWidth < 500){
            document.getElementById('body').classList.add('public');
        }
        document.getElementById('marketPlace').classList.add('hide');
        axios.get('/api-public/subscription/email/domain/invalid').then(resp => {this.setState({validMailList: resp.data})});
    }
    componentWillUnmount() {
        document.getElementById('body').classList.remove('big');
        if(window.innerWidth < 500){
            document.getElementById('body').classList.remove('public');
        }
        document.getElementById('marketPlace').classList.remove('hide');
    }

    openMenu() {
        document.getElementById('openPublic').classList.toggle('open');
    }

    setCheckDisclaimer = () => {
        this.setState({
            disclaimer: !this.state.disclaimer,
            enablePrivacy: !this.state.disclaimer,
            enableSubject: false,
            privacy: false,
            marketing: false,
            personalData: false,
            subject: ''

        });
    }

    setCheckPrivacy = () => {
        this.setState({
            privacy: !this.state.privacy,
            enableSubject: !this.state.privacy,
            subject: ''
        });
    }

    setCheckMarketing = () => {
        this.setState({
            marketing: !this.state.marketing
        });
    }

    setCheckPersonal = () => {
        this.setState({
            personalData: !this.state.personalData
        });
    }

    setSubject = (val) => {
        this.setState({subject: val});
    }

    setOther = (val) => {
        document.getElementById('other').classList.remove('has-error');
        if (val === '') {
            document.getElementById('other').classList.add('has-error');
        }
        this.setState({other: val});
    }

    enableSubmit = () => {
        if (this.state.nome && this.state.cognome && this.state.azienda && this.state.email && this.state.disclaimer && this.state.privacy && this.state.subject) {
            if (this.state.subject === 'Altro') {
                return this.state.other !== '';
            }
            return true;
        }
        return false;
    }

    openDomaniInvalid = () => {
            confirmAlert({
                title: <div ><div id="image"></div> <br/> Not valid Domain</div>,
                message: 'Attention, it is not possible to request accreditation via personal email addresses or PEC (not valid: '+this.state.invalidMail+')',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => this.hide()
                    }
                ]
            });
    }

    hide = () => this.setState({ openModalDomain: false});

    showFormInput = () => {
        if (this.state.disclaimer && this.state.privacy && this.state.subject !== '') {
            if (this.state.subject === 'Altro') {
                return this.state.other !== '';
            }
            return true;
        }
        return false;
    }

    render() {
        const {errors} = this.state;
        return(
            <div id='signup'>
                <div className='fasciaHeader'>
                    <div id='headerPublic'>
                        {window.innerWidth > 500?
                            <div className='logo'>
                                <Link to='/'><img src={require('../../styles/images/header.png')}/></Link>
                            </div>
                            :
                            <div className='menuMobile'>
                                <div className='logo'>
                                    <Link to='/'><img src={require('../../styles/images/header.png')}/></Link>
                                </div>
                                <div className='btnMenu' onClick={this.openMenu}>
                                    <img src={require('../../styles/images/openMenu.png')}/>
                                </div>
                            </div>
                        }
                        {window.innerWidth > 500?
                            <div id='openPublic' className='linkHeader'>
                                <Link to='/for-buyer'><h5 className='active'>For Buyer</h5></Link>
                                <Link to='/for-seller'><h5>For Seller</h5></Link>
                                <Link to='/about'><h5>About Us</h5></Link>
                                {/*<Link to='/faq'><h5>FAQ</h5></Link>*/}
                                <a href={process.env.REACT_APP_CAS_LOGIN_URL + window.location.origin + '/home'}>
                                    <div className='login'>
                                        <ILogin width='14px' fill='#008996'/><h5>Log In</h5>
                                    </div>
                                </a>
                                <Link to='/signup'>
                                    <div className='signIn'>
                                        <h5>Sign Up</h5>
                                    </div>
                                </Link>
                            </div>
                            :
                            <div id='openPublic' className='linkHeader'>
                                <div className='linkMenu'>
                                    <Link to='/for-buyer'><h5 className='active'>For Buyer</h5></Link>
                                    <Link to='/for-seller'><h5>For Seller</h5></Link>
                                    <Link to='/about'><h5>About Us</h5></Link>
                                    {/*<Link to='/faq'><h5>FAQ</h5></Link>*/}
                                </div>
                                <div className='buttonMenu'>
                                    <Link to='/home'>
                                        <div className='login'>
                                            <ILogin width='14px' fill='#008996'/><h5>Log In</h5>
                                        </div>
                                    </Link>
                                    <Link to='/signup'>
                                        <div className='signIn'>
                                            <h5>Sign Up</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className='fasciaSignup'>
                    <div className='description'>
                        <div className='link'>
                            <Link to='/'>markagain.cerved.com</Link>
                        </div>
                        <div className='started'>
                            Get started
                        </div>
                        <div className='question'>
                            Request your credentials<br/>
                            <span>to login the Markagain.</span>
                        </div>
                        <div className='testo'>
                            Enter your details to request your login credentials. Our advisors will take charge of your request and will contact you via email as soon as possible.
                        </div>

                    </div>
                    <div className='formSignup'>
                        <form id ="form" onSubmit={this.handleSubmit.bind(this)} method='post' noValidate>
                            <div className="header">
                                <span className="title">About you</span>
                                <span className="logo"><IAsterisk fill='#007e8a' width='8px'/>Required fields</span>
                            </div>
                            <div className={`checkContainer ${!this.state.enableDisclaimer ? 'disabled' : ''}`}>
                                <input type="hidden" value={this.state.disclaimer}/>
                                <div className={`checkPrivacy ${this.state.disclaimer ? 'selected' : ''}`} onClick={e => this.setCheckDisclaimer()}/>
                                <div className="checkLabel"><IAsterisk fill='#007e8a' width='8px'/> I have read and accept <a target="_blank" href="Disclaimer.pdf">the Markagain disclaimer</a> </div>
                            </div>
                            <div className={`checkContainer ${!this.state.enablePrivacy ? 'disabled' : ''}`}>
                                <input type="hidden" value={this.state.privacy}/>
                                <div className={`checkPrivacy ${this.state.privacy ? 'selected' : ''}`} onClick={e => this.state.enablePrivacy ? this.setCheckPrivacy() : ''}/>
                                <div className="checkLabel"><IAsterisk fill='#007e8a' width='8px'/> I have read and accept&nbsp;
                                    <PrivacyCookiePolicy />
                                </div>
                            </div>
                            <div className={`checkContainer ${!this.state.enablePrivacy ? 'disabled' : ''}`}>
                                <input type="hidden" value={this.state.marketing}/>
                                <div className={`checkPrivacy ${this.state.marketing ? 'selected' : ''}`} onClick={e => this.state.enablePrivacy ? this.setCheckMarketing() : ''}/>
                                <div className="checkLabel">I consent to the use of my personal data for marketing purposes as per&nbsp;
                                    <PrivacyCookiePolicy />
                                </div>
                            </div>
                            <div className={`checkContainer ${!this.state.enablePrivacy ? 'disabled' : ''}`}>
                                <input type="hidden" value={this.state.personalData}/>
                                <div className={`checkPrivacy ${this.state.personalData ? 'selected' : ''}`} onClick={e => this.state.enablePrivacy ? this.setCheckPersonal() : ''}/>
                                <div className="checkLabel">I consent to the communication of my personal data to Cerved Group S.p.A. for marketing purposes as per&nbsp;
                                    <PrivacyCookiePolicy />
                                </div>
                            </div>
                            <div className={`selectContainer ${!this.state.enableSubject ? 'disabled' : ''}`}>
                                <div className="selectLabel">Soggetto abilitato <IAsterisk fill='#007e8a' width='8px'/></div>
                                <select className="custom-select" disabled={!this.state.enableSubject} onChange={e => this.setSubject(e.target.value)}>
                                    <option selected={!this.state.enableSubject} value=""></option>
                                    <option value="Banche italiane o estere autorizzate a operare in Italia">Banche italiane o estere autorizzate a operare in Italia</option>
                                    <option value="Intermediari finanziari iscritti all\’albo ex 106 TUB;">Intermediari finanziari iscritti all’albo ex 106 TUB</option>
                                    <option value="Società veicolo per la cartolarizzazione di cui alla Legge 130/1999">Società veicolo per la cartolarizzazione di cui alla Legge 130/1999</option>
                                    <option value="Società con licenza per lo svolgimento dell’attività di recupero crediti ai sensi dell’art. 115 T.U.L.P.S.">Società con licenza per lo svolgimento dell’attività di recupero crediti ai sensi dell’art. 115 T.U.L.P.S.</option>
                                    <option value="Organismi di investimento collettivo del risparmio italiani e stranieri autorizzati a investire in crediti">Organismi di investimento collettivo del risparmio italiani e stranieri autorizzati a investire in crediti</option>
                                    <option value="Società di capitali che, ai sensi di legge e del relativo statuto sociale, possono acquistare crediti il cui sottostante è un immobile strumentale allo scopo sociale">Società di capitali che, ai sensi di legge e del relativo statuto sociale, possono acquistare crediti il cui sottostante è un immobile strumentale allo scopo sociale</option>
                                    <option value="Advisor o consulenti di uno dei soggetti precedenti">Advisor o consulenti di uno dei soggetti precedenti</option>
                                    <option value="Altro">Altro</option>
                                </select>
                            </div>
                            <div className={`textareaContainer ${this.state.subject !== 'Altro' ? 'disabled' : ''}`}>
                                <textarea id="other" className="form-control mb-3"
                                          onChange={e => this.setOther(e.target.value)}/>
                                {errors.email.length > 0 &&
                                <span className='error'>{errors.email}</span>}
                            </div>
                            {this.showFormInput() ?
                                <div className="formContainer">
                                    <div className={`form-group ${errors.nome.length > 0 && 'error-area'}`}>
                                        <label htmlFor="nome">Firstname <IAsterisk fill='#007e8a' width='8px'/></label>
                                        <input type="nome" name="nome" id="nome" onChange={this.handleChange} noValidate
                                               className="form-control mb-3"/>
                                        {errors.nome.length > 0 &&
                                        <span className='error'>{errors.nome}</span>}
                                    </div>
                                    <div className={`form-group ${errors.cognome.length > 0 && 'has-error'}`}>
                                        <label htmlFor="cognome">Lastname <IAsterisk fill='#007e8a'
                                                                                     width='8px'/></label>
                                        <input type="cognome" name="cognome" id="cognome" onChange={this.handleChange}
                                               noValidate className="form-control mb-3"/>
                                        {errors.cognome.length > 0 &&
                                        <span className='error'>{errors.cognome}</span>}
                                    </div>
                                    <div className={`form-group ${errors.azienda.length > 0 && 'has-error'}`}>
                                        <label htmlFor="azienda">Company <IAsterisk fill='#007e8a' width='8px'/></label>
                                        <input type="azienda" name="azienda" id="azienda" onChange={this.handleChange}
                                               noValidate className="form-control mb-3"/>
                                        {errors.azienda.length > 0 &&
                                        <span className='error'>{errors.azienda}</span>}
                                    </div>
                                    <div className={`form-group ${errors.email.length > 0 && 'has-error'}`}>
                                        <label htmlFor="email">Email <IAsterisk fill='#007e8a' width='8px'/></label>
                                        <input type="email" name="email" id="email" onChange={this.handleChange}
                                               noValidate className="form-control mb-3"/>
                                        {errors.email.length > 0 && this.state.errorDomain &&
                                            <span className='error solo' onClick={()=> {this.openDomaniInvalid();}}>{errors.email}</span>}
                                        {errors.email.length > 0 && !this.state.errorDomain &&
                                            <span className='error'>{errors.email}</span>}
                                             
                                    </div>
                                </div>
                                : ''}

                            {!this.enableSubmit() ?
                                <div className="carrelloheader disabled">
                                    <button disabled id="buttoncont" type="submit">Send</button>
                                </div>:
                                <div className="carrelloheader">
                                    <button id="buttoncont" type="submit">Send</button>
                                </div>
                            }

                            <div className='already'>Already have an account? <a href={process.env.REACT_APP_CAS_LOGIN_URL + window.location.origin + '/home'}>Sign in now.</a></div>
                            <div id="conferma">
                                <div className='title'>
                                    Thank you
                                </div>
                                <div className='testo'>
                                	The request has been successfully submitted. We will get back to you as soon as possible. Best regards, the Markagain Team
                                </div>
                                <Link to='/'  className='backHome'>
                                    Go home
                                </Link>
                            </div>
                        </form>

                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Signup
