import React, { Component } from 'react';
import ViewPacks from '../../shared/views/ViewPacks';
import ViewPrefs from '../../shared/views/ViewPrefs'
import {Wellcome} from "../../components/wellcome/Wellcome";
import { Link } from 'react-router-dom';
import ViewViewed from "../../shared/views/ViewViewed";
import FiltriHome from "../../components/filtriHome/FiltriHome"
import axios from "axios";
import ViewHashTag from "../../shared/views/ViewHashTag";

class Home extends Component{
    state = { user: '' };

componentWillMount() {

    let header = new URLSearchParams(window.location.search);
    let cookie = header.get('ticket');
    if (localStorage.length === 0 || localStorage.getItem('jwt-token') === 'undefined') {
        if (cookie === null) {
            window.location.replace(process.env.REACT_APP_CAS_LOGIN_URL + window.location.origin + '/home')
        } else {
            localStorage.setItem('jwt-token', cookie);
        }
    }
    axios.get('/api-mkt/common/getUsername').then(response => {this.setState({user: response.data})});

}

    render(){

        return(

            <div id='allPack'>
                <Wellcome user={this.state.user}/>
                <ViewHashTag/>
                <ViewViewed title='Recently viewed:' operation='Clear all'/>
                <ViewPrefs title='Your favorites:' type='preferiti' operation='See all favorites' bkg={true}/>
                <FiltriHome/>
                <ViewPacks title='Recently added:' type='recently' operation='See all portfolios' bkg={true}/>
                <ViewPacks forceList='true' title='All portfolios:' operation='See all'/>
                <div className='button'>
                    <Link to='/search/portfolios'><div>See all portfolios</div></Link>
                </div>
            </div>
        );
    }
}

export default Home;
