import React from 'react'
import Autosuggest from 'react-autosuggest';
import '../../styles/filter/filter.css'

var data ;
const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestions = (value, filtro) => {
    const escapedValue = escapeRegexCharacters(value.trim());
    data = filtro.map((value, index) => {
        return { name: value, key :index}
    });
    if (escapedValue === '') {
        return [];
    }
    const regex = new RegExp('^' + escapedValue, 'i');
    const suggestions = data.filter(datas => regex.test(datas.name));

    return suggestions;
};

class AutoCompileCustom extends React.Component{
    constructor(props) {
        super(props);

    this.state = {
        value: '',
        suggestions: [],
        input: ''
        };
    }

    // componentWillUpdate(nextProps, nextState, nextContext) {
    //
    //      data = nextProps.filtro.map((value, index) => {
    //         return { name: value, key :index}
    //     });
    //
    // }

    callHandle(suggestionValue) {
        this.props.handleInputAutocompile(suggestionValue, this.props.id);
    }

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue,
            input: method,
        });
        this.props.handleInputAutocompile(newValue, this.props.id);
    };

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) =>{
        if(method === 'enter' || method === 'click'){
            this.setState({
                value: suggestionValue,
                input: method,
            });
            this.callHandle(suggestionValue);
        }
    }
    componentDidMount() {
        let item =1;
        if (this.props.id === 'luogoAsset' || document.getElementsByClassName('react-autosuggest__input').length === 1){
            item = 0;
        }

        document.getElementsByClassName('react-autosuggest__input').item(item).addEventListener('keyup', event => {
            if (event.isComposing || event.keyCode === 13) {
                this.setState({
                    value: this.state.value,
                    input : 'enter'
                });
                this.callHandle(this.state.value);
            }
        })
    }

    getSuggestionValue = suggestion => {
        return suggestion.name;
    };

    renderSuggestion = suggestion => {
        return suggestion.name;
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value, this.props.filtro)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    reset = () =>{
        this.setState({
            value: '',
            suggestions: [],
            input: ''
        });
    }

    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "Municipality, province or region",
            value,
            onChange: this.onChange
        };

        return (
            <div id={`autosuggest${this.props.id}`} className='filterBox'>
                <div className='disable'/>
                <h5 className='label'>{this.props.label}</h5>
                <div className='d-flex check'>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={this.getSuggestionValue}
                        onSuggestionSelected={this.onSuggestionSelected}
                        renderSuggestion={this.renderSuggestion}
                        inputProps={inputProps}
                    />
                </div>
            </div>


        );
    }
}

export default AutoCompileCustom
