import React, { Component } from 'react';
import {connect} from "react-redux";
import * as actions from "../../actions";
import {PackMessages} from "../packs/PackMessages";

class ViewMessages extends  Component {

    componentWillMount() {
        this.props.dispatch(actions.fetchConversazioni());
        this.props.dispatch(actions.isGestore());
        this.props.dispatch(actions.fetchAssistenza());
    }

    initViewPrefs(){
        if(this.props.conversazione.open === undefined && this.props.conversazione.closed === undefined){
            return false
        }else{
            return <PackMessages conv={this.props.conversazione} ass={this.props.assistenza} gestore={this.props.isgestore}/>
        }
    }

    componentDidMount() {
        if (window.innerWidth > 500) {
            if (window.innerHeight > document.getElementById('root').clientHeight) {
                document.getElementById('question').classList.remove('fixed')
            } else {
                document.getElementById('question').classList.add('fixed')
            }
        }
    }

    render(){
        return(
            this.initViewPrefs()
        );

    }
}

function mapStateToProps(state) {
    return {
        conversazione: state.conversations.data,
        isgestore: state.conversations.isgestore,
        assistenza: state.conversations.assistenza
    }
}

export default connect(mapStateToProps)(ViewMessages)
