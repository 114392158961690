import React, { Component } from 'react';
import {connect} from "react-redux";
import * as actions from "../../actions";
import {PackCartList} from "../packs/PackCartList";
import {CarrelloHeader} from "../components/CarrelloHeader";
import ViewViewed from "./ViewViewed";
import {Link} from "react-router-dom";


class ViewCarrello extends Component {

    initViewCarrello() {
        if(this.props.packs.length === 0){
            return (<div >
                    <CarrelloHeader/>
                    <div  id="emptyCart" className="container-fluid">
                        <div id="image"></div>
                        <div id="messagioEmpty" className="d-flex justify-content-center">There are no portfolios in your indications of interests</div>
                        <div id="descriptionEmpty" className="d-flex justify-content-center">In this section you can find portfolios of your interest. You can do a indication of interest for more than one portfolio at time.</div>
                        <div id="links" className="d-flex justify-content-center">
                            <Link style={{ textDecoration: 'none'}}  to='/site-guide'><a id="link">Take a tour</a></Link>
                            <div id="divider"></div>
                            <Link  style={{ textDecoration: 'none'}} to='/search/portfolios'><a id="link" >Discover our portfolios</a></Link>

                        </div>

                    </div>
                            <ViewViewed title='Recently Viewed:' operation='Clear All'/>
                        </div>
        )

        }else{
            return <PackCartList all={this.props.packs}/>}
    }

    componentWillMount() {
        this.props.dispatch(actions.fetchCarrello());
    }

    render(){
        return(
            this.initViewCarrello()
        );
    }

}

    function mapStateToProps(state){
    return{
        packs: state.packs.carrello
    }
}

export default connect(mapStateToProps)(ViewCarrello)
