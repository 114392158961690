import {
    FETCH_ASSETS_SUCCESS,
    FETCH_ASSETSBYPORTID_SUCCESS, FETCH_ASSETSMAP_INIT,
    FETCH_ASSETSMAP_SUCCESS,
    FETCH_ASSETSMAPSBYPORTID_INIT,
    FETCH_ASSETSMAPSBYPORTID_SUCCESS, FETCH_ASSISTENZA_SUCCESS,
    FETCH_CARRELLO_SUCCESS,
    FETCH_CONVERSAZIONE_SUCCESS,
    FETCH_COUNT_INDICATIONOF_INTEREST,
    FETCH_DATAFILTERED_SUCCESS,
    FETCH_FAVORITES_PRESENT,
    FETCH_FILTRIPORTVALUES_SUCCESS,
    FETCH_FILTRIPOSVALUES_SUCCESS,
    FETCH_IS_GESTORE,
    FETCH_MESSAGES_PRESENT,
    FETCH_MESSAGGIBYID_SUCCESS,
    FETCH_PACK_BY_ID_INIT,
    FETCH_PACK_BY_ID_SUCCESS,
    FETCH_PACK_SUCCESS,
    FETCH_PORTAFOGLIOAREAGEOGRAFICA_SUCCESSO,
    FETCH_PORTAFOGLIOCONTROPARTE_SUCCESS,
    FETCH_PORTAFOGLIODESTINAZIONEUSO_SUCCESSO,
    FETCH_PORTAFOGLIOSTRATS_SUCCESS,
    FETCH_PORTPOSITIONS_SUCCESS,
    FETCH_POSITIONS,
    FETCH_POSITIONS_SUCCESS,
    FETCH_POSIZIONE_BYID,
    FETCH_PREFERITI_SUCCESS,
    FETCH_SEARCH_SUCCESS,
    FETCH_VISUALIZZATI_SUCCESS,
    ERASE_DATAFILTERED,
} from './type';

import axios from 'axios';
import moment from "moment";

import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';

axios.interceptors.request.use(function (config) {

    const host = process.env.REACT_APP_HOST;
    if(host){
        config.url=host+config.url;
    }
    const token = localStorage.getItem("jwt-token");
    config.headers.Authorization = "Bearer " + token;
    return config;
});

axios.interceptors.response.use(response => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));

    params.delete('errorPage');
    return response;
}, error => {
    console.log("error****:",error)
    if(error.response && error.response.status){
        if (error.response.status === 401 || error.response.status === 403) {
            localStorage.clear();
            var baseUrl = window.location.origin + "/";
            if (window.location.href != baseUrl && !window.location.href.includes("ticket=")) {
                window.location.replace(baseUrl)
            }
        }
        if(error.response.status === 500 && !window.location.pathname.includes('errorPage')){
            let url = new URL(window.location.href);
            let params = new URLSearchParams(url.search.slice(1));
            params.append('errorPage', '');
            window.location.href = '/errorPage'
        }
    }
    return error;
});

const fetchPackByIdInit = () => {
    return {
        type: FETCH_PACK_BY_ID_INIT
    }
}


export const fetchPacks = () => {
    return dispatch => {
        axios.get('/api-mkt/portfolio/findAll').then((packs) => {
            dispatch(fetchPackSuccess(packs.data));
        });
    }
}

const fetchPackSuccess = (packs) => {
    return {
        type: FETCH_PACK_SUCCESS,
        packs
    }
}

const fetchPackByIdSuccess = (pack) => {
    return {
        type: FETCH_PACK_BY_ID_SUCCESS,
        pack
    }
}

export const fetchPackById = (packId) => {
    return function (dispatch) {
        dispatch(fetchPackByIdInit());
        axios.get(`/api-mkt/portfolio/findById/${packId}`).then(pack =>
            dispatch(fetchPackByIdSuccess(pack.data))
        );
    }
}

export const fetchSearch = () => {
    return dispatch => {
        axios.get('/api-mkt/portfolio/findAll').then((packs) => {
            dispatch(fetchSearchSuccess(packs.data));
        });
    }
}

const fetchSearchSuccess = (packs) => {
    return {
        type: FETCH_SEARCH_SUCCESS,
        packs
    }
}
//
// export const fetchPositions = () => {
//     return dispatch => {
//         axios.get('/api-mkt/posiziones').then((positions) => {
//             dispatch(fetchPositionsSuccess(positions.data));
//         });
//     }
// }

const fetchPositionsByPortafoglioSuccess = (positions) => {
    return {
        type: FETCH_PORTPOSITIONS_SUCCESS,
        positions
    }
}

export const fetchPositionsByPortafoglioId = (id, filtro, page, size) => {
    return dispatch => {
        axios.post(`/api-mkt/search/positions/sortByFilters/${id}?page=${page}&size=${size}`, {
            gbvValues: filtro.gbvValues,
            tipoDiCreditoValues: filtro.tipoDiCreditoValues,
            controparteValues: filtro.controparteValues,
            caratteristicheAssetValues: filtro.caratteristicheAssetValues,
            luogoAsset: filtro.luogoAsset,
            residenzaDebitori: filtro.residenzaDebitori,
            procedimentiInCorsoValues: filtro.procedimentiInCorsoValues,
            sortBy: filtro.sortBy,
            sortOrder: filtro.sortOrder

        }).then(response => {
            dispatch(fetchPositionsByPortafoglioSuccess(response.data));
        })
    }
}
// const fetchPositionsSuccess = (positions) => {
//     return {
//         type: FETCH_POSITIONS_SUCCESS,
//         positions
//     }
// }
const fetchPreferitiSuccess = (preferiti) => {
    return {
        type: FETCH_PREFERITI_SUCCESS,
        preferiti
    }
}

export const fetchPreferiti = () => {

    return dispatch => {
        axios.get('/api-mkt/portfolio/findAllFavorites').then((preferiti) => {
            dispatch(fetchPreferitiSuccess(preferiti.data));
        })
    }
};

const fetchVisualizzatiSuccess = (visualizzati) => {
    return {
        type: FETCH_VISUALIZZATI_SUCCESS,
        visualizzati
    }

};

export const fetchVisualizzati = () => {
    return dispatch => {
        axios.get('/api-mkt/portfolio/findAllViewed').then((visualizzati) => {
            dispatch(fetchVisualizzatiSuccess(visualizzati.data));
        })
    }
};

export const addPreferiti = (id, filters) => {
    return (dispatch) => {
        axios.post('/api-mkt/preferred/addPortafoglio', {utenteId: null, portafoglioId: id})
            .then((response) => {
                if (response.status === 204) {
                    console.log("insert")
                    // toast.notify("", {position: 'bottom', duration: 0.1});
                    toast.notify("Portfolio added to your favorites", {position: 'top-right', duration: 2000});
                } else {
                    console.log("delete")
                    // toast.notify("", {position: 'bottom', duration: 0.1});
                    toast.notify("Portfolio removed from your favorites.", {position: 'top-right', duration: 2000});
                    axios.delete(`/api-mkt/preferred/deleteByPortafoglioId/${id}`);
                }
            })
            .then(() => dispatch(fetchVisualizzati()))
            .then(() => dispatch(fetchPreferiti()))
            .then(() => dispatch(favoritesPresent()))
            .then(() => dispatch(fetchPortafogliFiltrati(filters)))
            .then(() => dispatch(fetchPackById(id)))
            .then(() => dispatch(fetchCarrello()))
    }
}

export const addCarrello = (id) => {
    return dispatch => {
        axios.post('/api-mkt/cart/addPortfolio', {utenteId: null, portafoglioId: id})
            .then((response) => {
                if (response.status === 204) {
                    // toast.notify("", {position: 'bottom', duration: 0.1});
                    toast.notify("Portfolio added to your indications of interest.", {
                        position: 'top-right',
                        duration: 2000
                    });
                } else {
                    axios.delete(`/api-mkt/cart/deleteByPortfolioId/${id}`).then(() => dispatch(countIndicationOfInterest()));
                    // toast.notify("", {position: 'bottom', duration: 0.1});
                    toast.notify("Portfolio removed from your indications of interest.", {
                        position: 'top-right',
                        duration: 2000
                    });

                }
            })
            .then(() => dispatch(countIndicationOfInterest()))
            .then(() => dispatch(fetchPackById(id)))
            .then(() => dispatch(fetchPreferiti()))
    }
}

export const addCarrelloSelected = (data, filters) => {
    return dispatch => {
        axios.post('/api-mkt/cart/addPortfolios', data)
            .then((response) => {
                if (response.status === 200) {
                    // toast.notify("", {position: 'bottom', duration: 0.1});
                    toast.notify(response.data + " portfolios added to your indications of interest.", {
                        position: 'top-right',
                        duration: 2000
                    });
                }
            })
            .then(() => dispatch(countIndicationOfInterest()))
            .then(() => dispatch(fetchPortafogliFiltrati(filters)))
            .then(() => dispatch(fetchPreferiti()))
    }
}

export const setMessageRead = (id) => {
    return dispatch => {
        axios.post(`/api-mkt/messages/setMessageRead/${id}`)
            .then(() => dispatch(fetchMessagesPresent()))
    }
}

const fetchCarrelloSuccess = (carrello) => {
    return {
        type: FETCH_CARRELLO_SUCCESS,
        carrello
    }
}

export const fetchCarrello = () => {
    return dispatch => {
        axios.get('/api-mkt/portfolio/findAllInCart').then((carrello) => {
            dispatch(fetchCarrelloSuccess(carrello.data));
        })
    }
}


export const removeCarrello = (carId) => {
    return dispatch => {
        axios.delete(`/api-mkt/cart/deleteByPortfolioId/${carId}`).then((carrello) => dispatch(fetchCarrello(carrello.data)))
            .then(() => dispatch(countIndicationOfInterest()));
    }
}

export const removePreferito = (prefId) => {
    return dispatch => {
        axios.delete(`/api-mkt/preferred/deleteByPortafoglioId/${prefId}`)
            .then((preferiti) => dispatch(fetchPreferiti(preferiti.data)))
            .then(() => dispatch(favoritesPresent()));
    }
}

const fetchPosizioneByIdSuccess = (positions) => {
    return {
        type: FETCH_POSIZIONE_BYID,
        positions
    }

}

export const fetchPosizioneById = (posId) => {
    return dispatch => {
        axios.get(`/api-mkt/position/findById/${posId}`).then((positions) => dispatch(fetchPosizioneByIdSuccess(positions.data)));
    }
}
const fetchConversazioneSuccess = (conversazione) => {
    return {
        type: FETCH_CONVERSAZIONE_SUCCESS,
        conversazione
    }
}

const fetchAssistenzaSuccess = (assistenza) => {
    return {
        type: FETCH_ASSISTENZA_SUCCESS,
        assistenza
    }
}

export const fetchConversazioni = () => {

    return dispatch => {
        axios.get('/api-mkt/messages/getInfoRequests').then((conversazione) => dispatch(fetchConversazioneSuccess(conversazione.data)))
    }
}

export const fetchAssistenza = () => {

    return dispatch => {
        axios.get('/api-mkt/messages/getHelpRequests').then((assistenza) => dispatch(fetchAssistenzaSuccess(assistenza.data)))
    }
}

const fetchMessaggiSuccess = (messages) => {
    return {
        type: FETCH_MESSAGGIBYID_SUCCESS,
        messages
    }
}

export const fetchMessaggiById = (convId) => {
    return dispatch => {
        axios.get(`/api-mkt/messages/getAllByConversation/${convId}`).then((messages) => dispatch(fetchMessaggiSuccess(messages.data)))
    }
}

const fetchMapFiltratiInit = () => {
    return {
        type: FETCH_ASSETSMAP_INIT
    }
}

export const fetchMapFiltrati = (filtro) => {
    return dispatch => {
        dispatch(fetchMapFiltratiInit());
        axios.post('/api-mkt/search/portfolios/filterMap', {
            gbvValues: filtro.gbvValues,
            tipoDiCreditoValues: filtro.tipoDiCreditoValues,
            controparteValues: filtro.controparteValues,
            caratteristicheAssetValues: filtro.caratteristicheAssetValues,
            luogoAsset: filtro.luogoAsset,
            residenzaDebitori: filtro.residenzaDebitori,
            procedimentiInCorsoValues: filtro.procedimentiInCorsoValues,
            onlyMonoposizione: filtro.onlyMonoposizione

        })
            .then(response => {
                dispatch(fetchAssetsMapSuccess(response.data));
            });
    }
}

const fetchAssetsMapSuccess = (assets) => {
    return {
        type: FETCH_ASSETSMAP_SUCCESS,
        assets
    }
}

const fetchMapFiltratiByPortIdInit = () => {
    return {
        type: FETCH_ASSETSMAPSBYPORTID_INIT
    }
}

export const fetchMapFiltratiByPortId = (id, filtro) => {
    return dispatch => {
        dispatch(fetchMapFiltratiByPortIdInit());
        axios.post(`/api-mkt/search/positions/filterMap/${id}`, {
            gbvValues: filtro.gbvValues,
            tipoDiCreditoValues: filtro.tipoDiCreditoValues,
            controparteValues: filtro.controparteValues,
            caratteristicheAssetValues: filtro.caratteristicheAssetValues,
            luogoAsset: filtro.luogoAsset,
            residenzaDebitori: filtro.residenzaDebitori,
            procedimentiInCorsoValues: filtro.procedimentiInCorsoValues
        })
            .then(response => {
                dispatch(fetchAssetsMapByPortIdSuccess(response.data));
            });
    }
}

const fetchAssetsMapByPortIdSuccess = (assets) => {
    return {
        type: FETCH_ASSETSMAPSBYPORTID_SUCCESS,
        assets
    }
}


// export const fetchAssets = () => {
//     return dispatch => {
//         axios.get('/api-mkt/getAssets').then((assets) => {
//             dispatch(fetchAssetsSuccess(assets.data));
//         });
//     }
// }

const fetchAssetsSuccess = (assets) => {
    return {
        type: FETCH_ASSETS_SUCCESS,
        assets
    }
}

export const fetchAssetsByPortId = () => {
    return dispatch => {
        axios.get('/api-mkt/getAssets').then((assets) => {
            dispatch(fetchAssetsByPortIdSuccess(assets.data));
        });
    }
}

const fetchAssetsByPortIdSuccess = (assets) => {
    return {
        type: FETCH_ASSETSBYPORTID_SUCCESS,
        assets
    }
}

const fetchPortafoglioStratsSuccess = (strats) => {
    return {
        type: FETCH_PORTAFOGLIOSTRATS_SUCCESS,
        strats,

    }
}

export const fetchPortafoglioStrats = (filtro) => {
    return dispatch => {
        axios.post('/api-mkt/strats/filterPortafoglioTipoDiCredito', {
            gbvValues: filtro.gbvValues,
            tipoDiCreditoValues: filtro.tipoDiCreditoValues,
            controparteValues: filtro.controparteValues,
            caratteristicheAssetValues: filtro.caratteristicheAssetValues,
            luogoAsset: filtro.luogoAsset,
            residenzaDebitori: filtro.residenzaDebitori,
            procedimentiInCorsoValues: filtro.procedimentiInCorsoValues,
            onlyMonoposizione: filtro.onlyMonoposizione

        })
            .then((response) => dispatch(fetchPortafoglioStratsSuccess(response.data)))
    }
}

const fetchPortafoglioControparteSuccess = (corpstrat) => {
    return {
        type: FETCH_PORTAFOGLIOCONTROPARTE_SUCCESS,
        corpstrat
    }
}

export const fetchPortafoglioControparte = (filtro) => {
    return dispatch => {
        axios.post('/api-mkt/strats/filterPortafoglioControparte', {
            gbvValues: filtro.gbvValues,
            tipoDiCreditoValues: filtro.tipoDiCreditoValues,
            controparteValues: filtro.controparteValues,
            caratteristicheAssetValues: filtro.caratteristicheAssetValues,
            luogoAsset: filtro.luogoAsset,
            residenzaDebitori: filtro.residenzaDebitori,
            procedimentiInCorsoValues: filtro.procedimentiInCorsoValues,
            onlyMonoposizione: filtro.onlyMonoposizione

        })
            .then((response) => dispatch(fetchPortafoglioControparteSuccess(response.data)))
    }
}

const fetchPortafoglioDestinazioneUsoSuccess = (destinazione) => {
    return {
        type: FETCH_PORTAFOGLIODESTINAZIONEUSO_SUCCESSO,
        destinazione
    }
}

export const fetchPortafoglioDestinazioneUso = (filtro) => {
    return dispatch => {
        axios.post('/api-mkt/strats/filterPortafoglioDestUso', {
            gbvValues: filtro.gbvValues,
            tipoDiCreditoValues: filtro.tipoDiCreditoValues,
            controparteValues: filtro.controparteValues,
            caratteristicheAssetValues: filtro.caratteristicheAssetValues,
            luogoAsset: filtro.luogoAsset,
            residenzaDebitori: filtro.residenzaDebitori,
            procedimentiInCorsoValues: filtro.procedimentiInCorsoValues,
            onlyMonoposizione: filtro.onlyMonoposizione

        })
            .then((response) => dispatch(fetchPortafoglioDestinazioneUsoSuccess(response.data)))
    }
}

const fetchPortafoglioAreaGeograficaSuccess = (area) => {
    return {
        type: FETCH_PORTAFOGLIOAREAGEOGRAFICA_SUCCESSO,
        area
    }
}

export const fetchPortafoglioAreaGeografica = (filtro) => {
    return dispatch => {
        axios.post('/api-mkt/strats/filterPortafoglioAreaGeografica', {
            gbvValues: filtro.gbvValues,
            tipoDiCreditoValues: filtro.tipoDiCreditoValues,
            controparteValues: filtro.controparteValues,
            caratteristicheAssetValues: filtro.caratteristicheAssetValues,
            luogoAsset: filtro.luogoAsset,
            residenzaDebitori: filtro.residenzaDebitori,
            procedimentiInCorsoValues: filtro.procedimentiInCorsoValues,
            onlyMonoposizione: filtro.onlyMonoposizione

        })
            .then((response) => dispatch(fetchPortafoglioAreaGeograficaSuccess(response.data)))
    }
}


export const fetchPosizioniTipoDiCredito = (id, filtro) => {
    return dispatch => {
        axios.post(`/api-mkt/strats/filterPosizioniTipoDiCredito/${id}`, {
            gbvValues: filtro.gbvValues,
            tipoDiCreditoValues: filtro.tipoDiCreditoValues,
            controparteValues: filtro.controparteValues,
            caratteristicheAssetValues: filtro.caratteristicheAssetValues,
            luogoAsset: filtro.luogoAsset,
            residenzaDebitori: filtro.residenzaDebitori,
            procedimentiInCorsoValues: filtro.procedimentiInCorsoValues,
            onlyMonoposizione: filtro.onlyMonoposizione
        }).then((response) => dispatch(fetchPortafoglioStratsSuccess(response.data)))
    }
}

export const fetchPosizioniControparte = (id, filtro) => {
    return dispatch => {
        axios.post(`/api-mkt/strats/filterPosizioniControparte/${id}`, {
            gbvValues: filtro.gbvValues,
            tipoDiCreditoValues: filtro.tipoDiCreditoValues,
            controparteValues: filtro.controparteValues,
            caratteristicheAssetValues: filtro.caratteristicheAssetValues,
            luogoAsset: filtro.luogoAsset,
            residenzaDebitori: filtro.residenzaDebitori,
            procedimentiInCorsoValues: filtro.procedimentiInCorsoValues,
            onlyMonoposizione: filtro.onlyMonoposizione
        }).then((response) => dispatch(fetchPortafoglioControparteSuccess(response.data)))
    }
}

export const fetchPosizioniDestUso = (id, filtro) => {
    return dispatch => {
        axios.post(`/api-mkt/strats/filterPosizioniDestUso/${id}`, {
            gbvValues: filtro.gbvValues,
            tipoDiCreditoValues: filtro.tipoDiCreditoValues,
            controparteValues: filtro.controparteValues,
            caratteristicheAssetValues: filtro.caratteristicheAssetValues,
            luogoAsset: filtro.luogoAsset,
            residenzaDebitori: filtro.residenzaDebitori,
            procedimentiInCorsoValues: filtro.procedimentiInCorsoValues,
            onlyMonoposizione: filtro.onlyMonoposizione
        }).then((response) => dispatch(fetchPortafoglioDestinazioneUsoSuccess(response.data)))
    }
}

export const fetchPosizioniAreaGeografica = (id, filtro) => {
    return dispatch => {
        axios.post(`/api-mkt/strats/filterPosizioniAreaGeografica/${id}`, {
            gbvValues: filtro.gbvValues,
            tipoDiCreditoValues: filtro.tipoDiCreditoValues,
            controparteValues: filtro.controparteValues,
            caratteristicheAssetValues: filtro.caratteristicheAssetValues,
            luogoAsset: filtro.luogoAsset,
            residenzaDebitori: filtro.residenzaDebitori,
            procedimentiInCorsoValues: filtro.procedimentiInCorsoValues,
            onlyMonoposizione: filtro.onlyMonoposizione
        }).then((response) => dispatch(fetchPortafoglioAreaGeograficaSuccess(response.data)))
    }
}

export const addVisti = (id) => {
    return dispatch => {
        axios.post('/api-mkt/viewed/addPortafoglio', {
            id: null,
            utenteId: null,
            portafoglioId: id,
            dataVisualizzazione: moment()
        })
    }
}


export const fetchFiltriPortValues = () => {
    return dispatch => {
        axios.get('/api-mkt/search/getPortfolioFiltersValues').then((filtriValues) => {
            dispatch(fetchFiltriPortValuesSuccess(filtriValues.data));
        });
    }
}

export const fetchFiltriPosValues = (portId) => {
    return dispatch => {
        axios.get(`/api-mkt/search/getPositionFiltersValues/${portId}`).then((filtriValues) => {
            dispatch(fetchFiltriPosValuesSuccess(filtriValues.data));
        });
    }
}

const fetchFiltriPortValuesSuccess = (filtriValues) => {
    return {
        type: FETCH_FILTRIPORTVALUES_SUCCESS,
        filtriValues
    }
}

const fetchFiltriPosValuesSuccess = (filtriValues) => {
    return {
        type: FETCH_FILTRIPOSVALUES_SUCCESS,
        filtriValues
    }
}

export const fetchPortafogliFiltrati = (filtro, counter) => {
    if(filtro){
        return dispatch => {
            dispatch(eraseDataFiltered(counter || 1));
            axios.post('/api-mkt/search/portfolios/filter', {
                gbvValues: filtro.gbvValues,
                tipoDiCreditoValues: filtro.tipoDiCreditoValues,
                hashtag: filtro.hashtag,
                controparteValues: filtro.controparteValues,
                caratteristicheAssetValues: filtro.caratteristicheAssetValues,
                luogoAsset: filtro.luogoAsset,
                residenzaDebitori: filtro.residenzaDebitori,
                procedimentiInCorsoValues: filtro.procedimentiInCorsoValues,
                sortBy: filtro.sortBy,
                sortOrder: filtro.sortOrder,
                onlyMonoposizione: filtro.onlyMonoposizione
            })
                .then(response => {
                    dispatch(fetchDataFilteredSuccess(response.data,counter || 1));
                });
        }
    }else{
        return dispatch =>{return false};
    }
}

const fetchFilteredPositions = () => {
    return {
        type: FETCH_POSITIONS
    }
}

const eraseDataFiltered = (counter) => {
    return {
        type: ERASE_DATAFILTERED,
        counter
    }
}

const fetchDataFilteredSuccess = (packs, counter) => {
    return {
        type: FETCH_DATAFILTERED_SUCCESS,
        packs,
        counter,
    }
}

export const fetchPosizioniFiltrati = (filtro, page, size) => {
    return dispatch => {
        dispatch(fetchFilteredPositions());
        axios.post(`/api-mkt/search/portfolios/filterPositions?page=${page}&size=${size}`, {
            gbvValues: filtro.gbvValues,
            tipoDiCreditoValues: filtro.tipoDiCreditoValues,
            hashtag: filtro.hashtag,
            controparteValues: filtro.controparteValues,
            caratteristicheAssetValues: filtro.caratteristicheAssetValues,
            luogoAsset: filtro.luogoAsset,
            residenzaDebitori: filtro.residenzaDebitori,
            procedimentiInCorsoValues: filtro.procedimentiInCorsoValues,
            onlyMonoposizione: filtro.onlyMonoposizione

        }).then(response => {
            dispatch(fetchdataFilteredPosSuccess(response.data));
        })
    }
}

const fetchdataFilteredPosSuccess = (positions) => {
    return {
        type: FETCH_POSITIONS_SUCCESS,
        positions
    }
}

export const isGestore = () => {
    return dispatch => {
        axios.get("/api-mkt/common/isGestore").then((isgestore) => {
            dispatch(fetchisGestore(isgestore.data));
        })
    }

}
const fetchisGestore = (isgestore) => {
    return {
        type: FETCH_IS_GESTORE,
        isgestore
    }
}


const fetchCountIndicationOfInterest = (count) => {
    return {
        type: FETCH_COUNT_INDICATIONOF_INTEREST,
        count
    }
}

export const countIndicationOfInterest = () => {
    return dispatch => {
        axios.get('/api-mkt/common/countIndicationOfInterest').then((count) => {
            dispatch(fetchCountIndicationOfInterest(count.data))
        })
    }
}

const fetchMessagesPresent = (present) => {
    return {
        type: FETCH_MESSAGES_PRESENT,
        present
    }
}

const fetchFavoritesPresent = (present) => {
    return {
        type: FETCH_FAVORITES_PRESENT,
        present
    }
}

export const messagesPresent = () => {
    return dispatch => {
        axios.get('/api-mkt/common/newMessagesPresent').then((present) => {
            dispatch(fetchMessagesPresent(present.data))
        })
    }
}

export const favoritesPresent = () => {
    return dispatch => {
        axios.get('/api-mkt/common/favoritesPresent').then((present) => {
            dispatch(fetchFavoritesPresent(present.data))
        })
    }
}

export const clearAllViewed = () => {
    return dispatch => {
        axios.post('/api-mkt/viewed/clearAllViewed').then(() => dispatch(fetchVisualizzati()))
    }
}
