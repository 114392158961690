import React from 'react';
import {Link} from "react-router-dom";
import axios from 'axios';

import '../../styles/components/welcome.css'

export function Wellcome(props){
    return (
        <div id='wellcome'>
            <div className="userDetail">
                <h1 className='nome'><span className='hello'>Hello</span> <span id="user">{props.user}, </span> <span className='wellcomeBr'>welcome to Cerved Markagain.</span></h1>
                <p className='info'>Explore Cerved Markagain. Find portfolios that meet your purchasing goals and get in touch with our Cerved specialists.</p>
                <div className="d-flex btnWelcome">
                    <h5 className='link'>
                        <Link to='/site-guide'><button  type="button" className="buttonTour">Take a tour</button></Link>
                    </h5>
                    <div id="divider"></div>
                    <h5 className='link'>
                        <Link to='/search/portfolios'><button className="buttonAll" type="button">All portfolios</button></Link>
                    </h5>
                </div>
            </div>
        </div>
    );
}
