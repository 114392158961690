import React from "react";
import {Tag} from "./Tag";
import CheckBox from "../filter/CheckBox";


export class TagList extends React.Component {

    initTags() {
        const filters = this.props.handleChangeTab();

        if (filters.length === 0) {
            return ''
        } else {
            var allFilters = [];
            var count = 0

            // GBV Value
            if (filters.gbvValues) {
                if(filters.gbvValues.length > 0){
                    allFilters[count] = {
                        type: "gbvValues",
                        value: {min: (filters.gbvValues[0]/1000000).toFixed(1), max: (filters.gbvValues[1]/1000000).toFixed(1)},
                        solo: false
                    };
                    count = count + 1;
                }
            }

            // Portoflio type
            if (filters.onlyMonoposizione) {
                allFilters[count] = {
                    type: "onlyMonoposizione",
                    value: 'Single-name',
                    solo: false
                };
                count = count + 1;

            }

            // Debt category
            if (filters.tipoDiCreditoValues) {
                for (var i = 0; i < filters.tipoDiCreditoValues.length; i++) {
                    allFilters[count] = {
                        type: "debtCategory",
                        value: filters.tipoDiCreditoValues[i].value,
                        solo: filters.tipoDiCreditoValues[i].solo
                    };
                    count = count + 1;
                }
            }

            // Borrower Type
            if (filters.controparteValues) {
                for (var i = 0; i < filters.controparteValues.length; i++) {
                    allFilters[count] = {
                        type: "borrowerType",
                        value: filters.controparteValues[i].value,
                        solo: filters.controparteValues[i].solo
                    };
                    count = count + 1;
                }
            }

            // Asset Type
            if (filters.caratteristicheAssetValues) {
                for (var i = 0; i < filters.caratteristicheAssetValues.length; i++) {
                    allFilters[count] = {
                        type: "assetType",
                        value: filters.caratteristicheAssetValues[i].value,
                        solo: filters.caratteristicheAssetValues[i].solo
                    };
                    count = count + 1;
                }
            }

            // Asset Location
            if (filters.luogoAsset) {
                if(filters.luogoAsset !== ''){
                    allFilters[count] = {
                        type: "luogoAsset",
                        value: filters.luogoAsset,
                        solo: false
                    };
                    count = count + 1;
                }
            }

            // Borrower Location
            if (filters.residenzaDebitori) {
                if(filters.residenzaDebitori !== ''){
                    allFilters[count] = {
                        type: "residenzaDebitori",
                        value: filters.residenzaDebitori,
                        solo: false
                    };
                    count = count + 1;
                }
            }

            // Current Procedure
            if (filters.procedimentiInCorsoValues) {
                for (var i = 0; i < filters.procedimentiInCorsoValues.length; i++) {
                    let value=''
                    switch (filters.procedimentiInCorsoValues[i].value) {
                        case 'posizione_att_strag_attiva':
                            value = 'Active Out-of-court activity'
                            break
                        case 'posizione_proc_monit_attivo':
                            value = 'Active Payment injuction'
                            break
                        case 'posizione_proc_esec_mob_attiva':
                            value = 'Active Personal Property Foreclosure'
                            break
                        case 'posizione_proc_esec_immob_attiva':
                            value = 'Active Real Estate Foreclosure'
                            break
                        case 'posizione_proc_concor_attiva':
                            value = 'Active Insolvency procedure'
                            break
                        case 'posizione_proc_falli_attiva':
                            value = 'Active Bankruptcy procedure'
                            break
                        case 'posizione_cause_gudizi_in_corso':
                            value = 'Ongoing lawsuit'
                            break
                    }
                    allFilters[count] = {
                        type: "procedimentiInCorsoValues",
                        inputName: filters.procedimentiInCorsoValues[i].value,
                        value: value,
                        solo: filters.procedimentiInCorsoValues[i].solo
                    };
                    count = count + 1;
                }
            }

            if (filters.hashtag) {
                if(filters.hashtag !== ''){
                    allFilters[count] = {
                        type: "hashtag",
                        value: filters.hashtag,
                        solo: false
                    };
                    count = count + 1;
                }
            }

            return allFilters.map((filter, index) => {
                return <Tag key={index}
                    resetFiltroDebitori={this.props.resetFiltroDebitori}
                    resetFiltroLuogoAsset={this.props.resetFiltroLuogoAsset}
                    resetFiltroMonoPosition={this.props.resetFiltroMonoPosition}
                    resetFiltroRange={this.props.resetFiltroRange}
                    resetFiltroCheck={this.props.resetFiltroCheck}
                    resetFiltroHashtag={this.props.resetFiltroHashtag}
                    type={filter.type}
                    value={filter.value}
                    inputName={filter.inputName}/>
            });
        }
    }

    render() {
        return (
            <div id='tags'>
                {this.initTags()}
            </div>
        )
    }
}
