import React, { Component } from 'react';
import Spinner from "./Spinner";

export class ResultLoader extends React.Component {
    render() {
        return (
            <div className='row'>
                <div className='col-12 p-0 m-0'>
                    <div className='resultLoader opaco'>
                        <div className='loader'>
                            <Spinner/>
                            <div className='testo'>
                                Loading current results
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
