import React from 'react';
import {ReactComponent as ISearch} from "../../styles/svg/search.svg";
import HashTagInput from "./HashTagInput"

class FilterHashtag extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            filterHashTag: [],
            selected: '',
            matchUrlParameter: true
        }
    }
    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({
            filterHashTag: nextProps.filtro
        })
    }

    collapseBOX(event){
        if(event.target.id === "freccia"){
            event.target.classList.toggle('active');
        }else{
            event.target.children.freccia.classList.toggle('active');
        }
    }

    handlerAutocompile = (e) => {
        e.preventDefault();
        if (this.state.selected) {
            document.getElementById('autocompileHash').value = '';
            this.props.handleHashtagSelected('');
        }
        if (e.target.value !== '') {
            let filter = this.props.filtro
            this.setState({filterHashTag: filter.filter(hash => {
                    return hash.descrizione.toUpperCase().includes(e.target.value.toUpperCase())
                })
                , selected: ''
            });
        } else {
            this.setState({filterHashTag: this.props.filtro, selected: ''});
        }
    }

    selectHashtag = (e, hash) => {
        e.preventDefault();
        let filter = this.state.filterHashTag.filter(hashItem => {
            return hashItem.descrizione.toUpperCase().includes(hash.descrizione.toUpperCase())
        });
        this.setState({filterHashTag: filter, selected: hash.id});

        document.getElementById('autocompileHash').value = hash.descrizione
        this.props.handleHashtagSelected(hash.descrizione);
    }

    setMatchUrlParameter = () => {
        this.setState({matchUrlParameter: false})
    }

    reset = () => {
        document.getElementById('autocompileHash').value = '';
        this.setState({filterHashTag: this.props.filtro, selected: ''});
        this.props.handleHashtagSelected('');
    }

    render() {
        if(this.props.filtro === undefined){
            return false
        }else {
            return (
                <div className='filterBox'>
                    <div className='boxCollapse'>
                        <h5 className='label'>{this.props.label}</h5>
                        <a onClick={this.collapseBOX} className="filterCollapse" data-toggle="collapse"
                           href={`#collapse${this.props.id}`} aria-expanded="false"
                           aria-controls={`#collapse${this.props.id}`}>
                            <div id='freccia'/>
                        </a>
                    </div>
                    <div className="collapse show" id={`collapse${this.props.id}`}>
                        <div className="autocopileHashtag">
                            <div className="headerInput d-flex align-items-center filter">
                                <div className="input d-flex justify-content-between align-items-center">
                                    <input ref="inputHashtag" id="autocompileHash" type="text" placeholder="Search Hashtag"
                                           onChange={e => this.handlerAutocompile(e)}
                                    />
                                    <ISearch fill="#ddd" width="20px"/>
                                </div>
                            </div>
                            <div className="selectedHashtag">
                                {
                                    this.state.filterHashTag.map(hash => {
                                        return (
                                            <HashTagInput
                                                hash={hash}
                                                selected={this.state.selected}
                                                selectHashtag={this.selectHashtag}
                                                setMatchUrlParameter={this.setMatchUrlParameter}
                                                matchUrlParameter={this.state.matchUrlParameter}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default FilterHashtag;