import React from 'react';
import Spinner from "../../shared/components/Spinner";
import toast from 'toasted-notes';
import ScrollableAnchor from 'react-scrollable-anchor'
import {ReactComponent as IPlusCircle} from '../../styles/svg/plus.svg';
import {ReactComponent as IPlusPencil} from '../../styles/svg/pencil.svg';
import {ReactComponent as IPlusSave} from '../../styles/svg/save.svg';
import {ReactComponent as IPlusTrash} from '../../styles/svg/trash-can.svg';
import {ReactComponent as IPlusCancel} from '../../styles/svg/iconmonstr-x-mark-7.svg';
import {ReactComponent as IAsterisk} from '../../styles/svg/asterisk.svg';
import {ReactComponent as Close} from "../../styles/svg/window-close.svg";
import {confirmAlert} from "react-confirm-alert";
import {connect} from "react-redux";
import * as actions from "../../actions";
import axios from "axios";
import '../../styles/components/questionAnswer.css'

const validEmailRegex = RegExp('^[0-9]+$');
class QuestionAnswer extends React.Component{
    constructor(props){
        super(props);

        this.updateRow = this.updateRow.bind(this);
        this.saveRow = this.saveRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.addRow = this.addRow.bind(this);

        this.state = {
            qaList: [],
            edit: false,
            loading: false,
            insert: false,
            error: "",
            domanda: "",
            risposta: "",
            ordine: "",
            errorDomanda: "",
            errorRisposta: "",
            errorOrdine: "",
        }
    }

    componentWillMount() {
        this.props.dispatch(actions.isGestore());
        this.refreshQeList();
    }

    refreshQeList(){
        axios.get(`/api-mkt/portfolio/${this.props.idPortafoglio}/fqa`).then(response => {this.setState({qaList: this.editList(response.data), loading: false})});
    }

    editList(qaList){   
        const newlistQ = [];
        qaList.forEach(l => {
            newlistQ.push({
            domanda: l.domanda,
            risposta: l.risposta,
            id: l.id,
            ordine: l.ordine,
            edit: false,
          });
        })
        return newlistQ;
    }

    addRow(e){
        this.state.qaList.push({domanda: "", risposta: "", ordine: "", edit: true})
        toast.notify("Enter a new question", {
            position: 'bottom-right',
            duration:3000
        });

        this.setState({edit: true, insert: true, domanda: "", risposta: "", ordine: ""});
        
    }

    updateRow = (_e, el) => {
        el.edit = true;
        this.setState({ edit: true, domanda: el.domanda, risposta: el.risposta, ordine: el.ordine});
    }

    cancelRow = (_e, el) => {
        el.edit = false;
        this.setState({edit: false, insert: false, qaList: [], loading: true, errorDomanda: "", errorRisposta: "", errorOrdine: "", domanda: "", risposta: "", ordine: ""});
        this.refreshQeList();
    }

    hide = () => this.setState({ open: false, callback: null });

    
    saveRow = (_e, el) => {
    
        if(this.validaForm(el.id)){
            const domanda = this.state.domanda;
            const risposta = this.state.risposta;
            const ordine = this.state.ordine;
            const id = el.id;

            const data = { domanda, risposta, ordine, id }

            if(this.state.insert){
                this.setState({ loading: true }, () => {
                    axios.post(`/api-mkt/portfolio/${this.props.idPortafoglio}/fqa`, data).then((response) => {
                        if (response.response && response.response.status === 400){
                            this.setState({error: response.response.data.title});
                        } else{
        
                            toast.notify("Save successful", {
                                position: 'bottom-right',
                                duration:3000
                            });
        
                            el.edit = false;
                            this.setState({edit: false, insert: false, qaList: [], domanda: "", risposta: "", ordine: ""});
                            this.refreshQeList();
                        }
                    });
                 });
                
            } else {

                this.setState({ loading: true }, () => {
                    axios.put(`/api-mkt/portfolio/${this.props.idPortafoglio}/fqa`, data).then((response) => {
                        if (response.response && response.response.status === 400){
                            this.setState({loading:false,error: response.response.data.title});
                        } else{
                           
                            toast.notify("Save successful", {
                                position: 'bottom-right',
                                duration:3000
                            });
        
                            el.edit = false;
                            this.setState({edit: false, insert: false, qaList: []});
                            this.refreshQeList();
                        }
                    });
                });
            }

        } else {
            console.log("Error Validate");      
        }
       
    }

    onChange = (value, e) => {
    
        this.setState({error: ""});
        if(value==="domanda"){
            this.setState({errorDomanda: ""});
            this.setState({domanda: e.target.value});
        }
        if(value==="risposta"){
            this.setState({errorRisposta: ""});
            this.setState({risposta: e.target.value});
        }
        if(value==="ordine"){
            this.setState({errorOrdine: ""});
            this.setState({ordine: e.target.value});
        }
    };

    validaForm(id){  
        let errorDomanda = "";
        let errorRisposta = "";
        let errorOrdine = "";


        if(this.state.domanda===""){
            errorDomanda='The Question field is required';
        }   
        else if(this.state.risposta===""){
            errorRisposta='The Answer field is required';
        }
        else if(this.state.ordine===""){
            errorOrdine='The Order field is required';
        }
        else if(this.state.domanda.length>400){
            errorDomanda='the Question cannot contain more than 400 characters';
        }
        else if(this.state.risposta.length>400){
            errorRisposta='the Answer cannot contain more than 400 characters';
        }
        
        else if(this.verificaOrdine(this.state.ordine, id)){
            errorOrdine='The order number is already present';
        }
        

        else if(!this.verificaOrdineNumber(this.state.ordine)){
            errorOrdine='The order must be a number';
        }
        
        if(errorDomanda!=="" || errorRisposta!=="" || errorOrdine!=="" ){
            this.setState({errorDomanda: errorDomanda, errorRisposta: errorRisposta, errorOrdine: errorOrdine});
            return false;
        } else {
            return true;   
        }
    }

    verificaOrdineNumber(str) {
       return validEmailRegex.test(str);
    }

    
    verificaOrdine(ordine, id){ 
        let isPresente=false;
        this.state.qaList.forEach(l => {
            if(l.id !== id && l.ordine.toString() === ordine){
                isPresente = true;
            }
        });
        return isPresente;
    }
    

    handleSubmitDelete(e, id){

        this.setState({ loading: true }, () => {
            axios.delete(`/api-mkt/portfolio/${this.props.idPortafoglio}/fqa/${id}`).then((response) => {
                if (response.response && response.response.status === 400){
                    this.setState({error: response.response.data.title});
                } else{
                    this.setState({edit: false, insert: false, qaList: []});
                    this.refreshQeList();
                    this.confirmDelete();
                }
            });  
        });
    };

    confirmDelete = () => {
        confirmAlert({
            title: <div><div id="image"></div> <br/> Thank you! Your request has been sent.</div>,
            message: 'Question has been successfully deleted',
            buttons: [
                {
                    label: 'Close',
                    onClick: () => this.hide()
                }
            ]
        });

        this.setState({edit: false, insert: false, qaList: []});
        this.refreshQeList();
        
    };

    deleteRow(id, question){
        confirmAlert({
            closeOnEscape: true,
            closeOnClickOutside: false,
            customUI: ({onClose}) =>{
                
                return (
                    <div className='custom-ui'>
                        <p id="headerAlert" className="h4 mb-4">Q&A<span id="close" className="h4 mb-4"><Close onClick={()=> {onClose();}} id="xclose" width="14px"/></span></p>
                            <div className="p-4">
                                <p id="dataT" className="h7 mb-4">Do you want to delete the Q&A 
                                <div className='wordWrap'>
                                    <span id="question">{`"${question}"`}</span>
                                </div>
                                </p>
                               
                                <div className="clearfix"></div>
                                <div className="chat_bottom">
                                    <div className="chatbutton">
                                        <div id="dataTButtons" className="d-flex justify-content-start">
                                            <button id="dataTapeAnnulla" onClick={onClose}>Cancel</button>
                                            <div id="divider"></div>
                                            <button id="dataTape" onClick={e => this.handleSubmitDelete(e, id)}>Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       
                    </div>

                );

            }
        });
    }

    collapseBOX(event){
        Array.from(document.getElementsByClassName('itemCollapse')).forEach((link) => {
            link.classList.remove('blu');
        });
        event.target.classList.add('blu')

    }

    sortByOrdineDesc(ordine) {
        ordine.sort(function (a, b) {
            var key1 = a.ordine;
            var key2 = b.ordine;

            if (key1 < key2) {
                return -1;
            } else if (key1 === key2) {
                return 0;
            } else {
                return 1;
            }
        })
        return ordine;
    }


    render() {
        return (
            <div id='questionAnswer'> 
                <ScrollableAnchor id={'qa'}>
                    <div id='guida' className='row questionAnswer'>
                        <div className='qeTitle label'>
                            <h3><span style={{color: "#008996"}}>Question & Answer</span></h3>
                            {this.props.isgestore && !this.state.edit &&(
                            <div className='bottonQuestion m10' onClick={e => this.addRow(e)} >
                                    <IPlusCircle fill="#007e8a" height="22"/>
                            </div>
                            )}
                        </div>
    
                        {this.state.loading && (
                        <div className="spinnerQuestioAnswer">
                            <Spinner />
                        </div>
                        )}
    
                        <div id='expWays' style={{width: "100%"}}>
                        {this.state.qaList.length > 0 && !this.state.loading && 
                            this.sortByOrdineDesc(this.state.qaList).map(el => (
                            <div className="qeList" key={el.id}>
                                
                                {!el.edit && (
                                    <div className='ways' style={{width: "100%"}}>
                                    <div className='type'>
                                        <div className='exploreCollapse'>  
                                            <div className='d-flex justify-content-between'>
                                                <a onClick={this.collapseBOX} className="itemCollapse collapsed wordWrap" data-toggle="collapse" href={`#collapse${el.id}`} aria-expanded="true" aria-controls={`collapse${el.id}`}>
                                                    <div className='wordWrap'>{el.domanda}</div>
                                                </a>
                                            
                                                {this.props.isgestore && !this.state.edit && (
                                                    <div className='bottonQuestion'>
                                                        <div className='m10' onClick={ e => this.updateRow(e, el)}>
                                                            <IPlusPencil fill="#007e8a" height="20"/>
                                                        </div>
                                                        <div className='m10' onClick={e => this.deleteRow(el.id, el.domanda)}>
                                                            <IPlusTrash fill="#007e8a" height="20"/>
                                                        </div>
                                                    </div>
                                                )} 
    
                                            </div>
                                        </div>
                                        <div className="collapse testo" id={`collapse${el.id}`} aria-labelledby={el.id} data-parent="#expWays">
                                             <div className='wordWrap'>{el.risposta}</div>
                                        </div>
                                    </div>
                                    </div>
                                )} 
    
                                {el.edit && (
                                    <div style={{width: "100%"}}>
    
                                        {this.state.edit && (
                                            <div className='bottonQuestion'>
                                                <div className='m10' onClick={ e => this.cancelRow(e, el)}>
                                                    <IPlusCancel fill="#007e8a" height="20"/>
                                                </div>
                                                <div className='m10' onClick={e => this.saveRow(e, el)}>
                                                    <IPlusSave fill="#007e8a" height="20"/>
                                                </div>
                                            </div>
                                        )}
                            
                                        <div className="questionAnswerInput">       
                                                <label htmlFor="nome" className="label-question">Question<IAsterisk fill='#007e8a' width='8px'/></label>
                                                <input type="text" name="nome" className={`inputTextQuestionAnswer ${this.state.errorDomanda !== "" && 'error-area'}`}  style={{width: "100%"}} defaultValue={el.domanda} id="questionText" onChange={e => this.onChange("domanda", e)} noValidate/>
                                        </div>   
                                        
                                        {!this.state.edit && (
                                            <div className='bottonQuestion'>
                                                <div className='m10' onClick={ e => this.updateRow(e, el)}>
                                                    <IPlusPencil fill="#007e8a" height="20"/>
                                                </div>
                                                <div className='m10' onClick={e => this.deleteRow(el.id, el.domanda)}>
                                                    <IPlusTrash fill="#007e8a" height="20"/>
                                                </div>
                                            </div>
                                        )} 
    
                                                
                                        <div className="questionAnswerInput">       
                                                <label htmlFor="nome" className="label-question">Answer <IAsterisk fill='#007e8a' width='8px'/></label>
                                                <input type="text" name="nome" className={`inputTextQuestionAnswer ${this.state.errorRisposta !== "" && 'error-area'}`}  style={{width: "100%"}} defaultValue={el.risposta} id="answerText" onChange={e => this.onChange("risposta", e)} noValidate/>
                                                
                                        </div> 
    
                                        <div className="orderInput">       
                                                <label htmlFor="nome" className="label-question">Order <IAsterisk fill='#007e8a' width='8px'/></label>
                                                <input type="text" name="nome" className={`inputTextQuestionAnswer ${this.state.errorOrdine !== "" && 'error-area'}`}  style={{width: "100%"}} defaultValue={el.ordine} id="orderText" onChange={e => this.onChange("ordine", e)} noValidate/>
                                        </div> 
    
                                        <div>
                                            {this.state.errorDomanda !== "" &&
                                                <span className='error'>{this.state.errorDomanda}</span>}   
    
                                            {this.state.errorRisposta !== "" &&
                                                <span className='error'>{this.state.errorRisposta}</span>}
        
                                            {this.state.errorOrdine !== "" &&
                                                <span className='error'>{this.state.errorOrdine}</span>}
                                    
                                            {this.state.error !== "" &&
                                                <span className='error'>{this.state.error}</span>}
                                        </div>   
                                    </div> 
                                )} 
                            </div>
                        ))}  
                        </div>
                        {!this.state.qaList.length > 0 && !this.state.loading && 
                            <div>Frequently asked questions and answers have not been published</div>
                        }
                    </div>
                </ScrollableAnchor>
            </div>
        )}
}

function mapStateToProps(state) {
    return {     
        isgestore: state.conversations.isgestore,  
    }
}

export default connect(mapStateToProps) (QuestionAnswer);

