import React, {Component} from "react";
import Messaggi from "../../pages/messaggi/Messaggi";
import Assistenza from "../../pages/messaggi/Assistenza";
import {Link} from "react-router-dom";

export class PackMessages extends Component{

    initPacksOpened(){
        const gestore = this.props.gestore;
        const p = this.props.conv.open;
        return p.map((pack, index) => {
            return <Messaggi gestore={gestore} key={index} colNum='col-md-3 col-xs-6' data={pack} type={pack.type} ass={false}/>
        });

    }

    initPacksClosed(){
        const gestore = this.props.gestore;
        const p = this.props.conv.closed;
        return p.map((pack, index) => {
            return <Messaggi gestore={gestore} key={index} colNum='col-md-3 col-xs-6' data={pack} type={pack.type} ass={false}/>
        });
    }

    initPacksAss(){
        const gestore = this.props.gestore;
        const p = this.props.ass;
        return p.map((pack, index) => {
            return <Assistenza key={index} colNum='col-md-3 col-xs-6' data={pack} type={pack.type} ass={true}/>
        });

    }


    componentDidMount() {
        document.getElementById('marketPlace').classList.add('grigio');
    }
    componentWillUnmount() {
        document.getElementById('marketPlace').classList.remove('grigio');
    }

    render(){
        const viewOpen = this.props.conv.open.length > 0;
        const viewClosed = this.props.conv.closed.length > 0;
        const viewAss = this.props.ass.length > 0;

        return(
            <div className=''>
                <div id="container">
                    <div className="d-flex justify-content-center">
                        <div className="d-flex flex-column justify-content-center">
                            <span  id="yourmess" className="d-flex justify-content-center">Your messages</span>
                            <p id="messp"> Here you can find all your conversations and your assistance requests.</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div id="Tabs">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item" >
                                    <a className="active" href="#personal"  aria-controls="messagecontainer" aria-selected="true" role="tab" data-toggle="tab">
                                        Info Requests
                                        <div className='barraActive'></div>
                                    </a>
                                </li>
                                <li className="nav-item" >
                                    <a href="#assistance"  aria-controls="assistancecontainer" aria-selected="false" role="tab" data-toggle="tab">
                                        Help Requests
                                        <div className='barraActive'></div>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                {(this.props.conv.length === 0 || (this.props.conv.open.length === 0 && this.props.conv.closed.length === 0)) ?
                                    <div role="tabpanel" className="tab-pane active" id="personal">
                                        <div id="emptyCart" className="container-fluid">
                                            <div id="image"></div>
                                            <div id="messagioEmpty" className="d-flex justify-content-center">There are
                                                no messages in your inbox.
                                            </div>
                                            <div id="descriptionEmpty" className="d-flex justify-content-center">In this
                                                section you can find all information requests submitted to Cerved. To
                                                submit an information request press the "Info request" button on
                                                portfolio or borrower page to send a new message.
                                            </div>
                                            <div id="links" className="d-flex justify-content-center">
                                                <Link style={{textDecoration: 'none'}} to='/site-guide'><a id="link">Take
                                                    a tour</a></Link>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div role="tabpanel" className="tab-pane active" id="personal">
                                        {viewOpen ? (
                                            <div id="aperte" className="d-flex">
                                                <h2>Open messages ({this.props.conv.open.length})</h2>
                                            </div>
                                        ) : null}
                                        <div className="containerMessages" id="messagecontainer">
                                            {this.initPacksOpened()}
                                        </div>

                                        {viewClosed ? (
                                            <div id="chiuse" className="d-flex justify-content-between">
                                                <h2>Closed messages ({this.props.conv.closed.length})</h2>
                                            </div>
                                        ) : null}
                                        <div className="containerMessages" id="messagecontainer">
                                            {this.initPacksClosed()}
                                        </div>
                                    </div>
                                }
                                {(this.props.ass.length === 0) ?
                                    <div role="tabpanel" className="tab-pane" id="assistance">
                                        <div id="emptyCart" className="container-fluid">
                                            <div id="image"></div>
                                            <div id="messagioEmpty" className="d-flex justify-content-center">There are
                                                no messages in your inbox.
                                            </div>
                                            <div id="descriptionEmpty" className="d-flex justify-content-center">In this
                                                section you can find all information requests submitted to Cerved. To
                                                submit an information request press the "Info request" button on
                                                portfolio or borrower page to send a new message.
                                            </div>
                                            <div id="links" className="d-flex justify-content-center">
                                                <Link style={{textDecoration: 'none'}} to='/site-guide'><a id="link">Take
                                                    a tour</a></Link>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div role="tabpanel" className="tab-pane" id="assistance">
                                        {viewAss ? (
                                            <div id="chiuse" className="d-flex justify-content-between">
                                                <h2>Help Request ({this.props.ass.length})</h2>
                                            </div>
                                        ) : null}
                                        <div className="containerMessages" id="messagecontainer">
                                            {this.initPacksAss()}
                                        </div>

                                    </div>
                                }
                                </div>
                            </div>
                        </div>

                </div>

            </div>
        );
    }
}
