import React, {Component} from 'react';
import Position from "../../shared/Position";
import {connect} from "react-redux";
import * as actions from "../../actions";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import {Sort} from "../../shared/components/Sort";
import EmptyPage from "../../shared/components/EmptyPage";
import axios from "axios";

class PositionListSerched extends Component{
    state = {
        current: 0,
        total: 0
    };

    onChange = (page) => {
        const match = this.props.match;
        const filtro = this.props.handleChangeTab();
        this.setState({current: page-1}, ()=> this.props.dispatch(actions.fetchPositionsByPortafoglioId(match,filtro, page-1, itemsPerPage)));
    };

    initPosition(){
        if(this.props.positions.positions === 0){
            return <EmptyPage/>}else{
        return this.props.positions.positions.map((pos, index) => {
            return (
                <Position
                    key={index}
                    data={pos.posizione}
                    currentProceduresCount={pos.currentProceduresCount}
                    notMatchedGbv={pos.notMatchedGbv}
                    notMatchedDebtCategory={pos.notMatchedDebtCategory}
                    notMatchedBorrowerType={pos.notMatchedBorrowerType}
                    notMatchedCaratteristiche={pos.notMatchedCaratteristiche}
                    notMatchedProcedimenti={pos.notMatchedProcedimenti}
                    notMatchedAssetLocation={pos.notMatchedAssetLocation}
                    notMatchedBorrowerLocation={pos.notMatchedBorrowerLocation}
                />
            )
        });
            }
    }

    componentWillMount() {
        const match = this.props.match;
        const filtro = this.props.handleChangeTab();
        this.props.dispatch(actions.fetchPositionsByPortafoglioId(match,filtro, activePage, itemsPerPage));
        axios.get(`/api-mkt/position/countAllById/${this.props.match}`).then(res => {
            this.setState({total: res.data})
        })
    }

    componentDidUpdate(nextProps, nextState, nextContext) {
        this.props.countPostionsFilter(this.props.positions.positionCount);
        document.getElementById('sort').classList.remove('show')
    }

    render(){
        if(window.innerWidth > 500){
            return (
                <div>
                    <section id='positionList'>
                        <div className='row'>
                            <div className='countFiltered'>
                                <Sort from='Id' handleChangeTab={this.props.handleChangeTab} handleSortBy={this.props.handleSortBy} handleSortOrder={this.props.handleSortOrder}/>
                            </div>
                            <div className='col-12'>
                                <div className="d-flex col-12 justify-content-between headerPos">
                                    <div div className='colPos small'>ID</div>
                                    <div div className='colPos small'>NDG</div>
                                    <div div className='colPos medium'>Debt Category</div>
                                    <div div className='colPos medium'>Borrower type</div>
                                    <div div className='colPos small'>Borrower's province</div>
                                    <div div className='colPos large'>Prevalent Loan Category</div>
                                    <div div className='colPos extraSmall'>Nr. <br/>Asset</div>
                                    <div div className='colPos small'>Prevalent mortages's degree</div>
                                    <div div className='colPos small'>Current procedures</div>
                                    <div div className='colPos extraLarge'>GBV</div>
                                    <div div className='colPos last'></div>
                                </div>
                            </div>
                        </div>
                        {this.initPosition()}
                    </section>
                    <div className="d-flex justify-content-end">
                        <Pagination
                            onChange={this.onChange}
                            current={this.state.current+1}
                            total={this.state.total}
                            style={{ marginBottom: '2rem' ,marginTop: '2rem'}}
                            showTitle={false}
                        />
                    </div>
                </div>
            );
        }else{
            return (
                <div>
                    <div className='row sortMobile'>
                        <div className='countFiltered'>
                            <Sort from='Id' handleChangeTab={this.props.handleChangeTab} handleSortBy={this.props.handleSortBy} handleSortOrder={this.props.handleSortOrder}/>
                        </div>
                    </div>
                    <section id='positionList'>
                        {this.initPosition()}
                    </section>
                    <div className="d-flex justify-content-end">
                        <Pagination
                            onChange={this.onChange}
                            current={this.state.current+1}
                            total={this.state.total}
                            style={{ marginBottom: '2rem' ,marginTop: '2rem'}}
                            showTitle={false}
                        />
                    </div>
                </div>
            );
        }

    }
}

function mapStateToProps(state){
    return{
        positions: state.positions.dataDet
    }
}

export default connect(mapStateToProps)(PositionListSerched)

let activePage= 0;
export const itemsPerPage= 10;
