import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import HashTagModal from "../components/HashTagModal";
import axios from "axios";
import {Link} from "react-router-dom";

class ViewHashTag extends Component{
    constructor(props) {
        super(props);
        this.state = {
            hashtags: [],
            showModal: false
        }
    }

    componentWillMount() {
        axios.get('/api-mkt/hashtags/findAllHashtags').then(res => {
            this.setState({
                hashtags: res.data
            })
        });
    }

    showModal = () => {
        this.setState({showModal: !this.state.showModal});
    }

    capitalize = (str) => {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    renderHashItemHome = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext("2d");
        let count = 0
        ctx.font = "16px Arial";

        let widthPage = window.outerWidth;
        if (window.outerWidth > 1024) {
            widthPage = 1170;
        }
        return this.state.hashtags.map(hash => {
            if (window.outerWidth > 480) {
                const widthString = ctx.measureText(hash.descrizione).width + 50;
                widthPage = widthPage - widthString;
                if (widthPage > 0) {
                    return (
                        <Link style={{textDecoration: 'none', color: '#363a40'}}
                              to={`/search/portfolios?filtroHome=hashtag-${hash.descrizione}`}>
                            <div className="homeItemHash">{this.capitalize(hash.descrizione)}</div>
                        </Link>);
                }
            } else {
                count = count+1;
                if (count <= 6) {
                    return (
                        <Link style={{textDecoration: 'none', color: '#363a40'}}
                              to={`/search/portfolios?filtroHome=hashtag-${hash.descrizione}`}>
                            <div className="homeItemHash">{this.capitalize(hash.descrizione)}</div>
                        </Link>);
                }
            }

        })
    }

    render() {

        return (
            <section id="homeHashTag" className="containerHashTag">
                <div className="bkg"/>
                <div className="text-center">
                    <h2 className='page-title text-center'>Search by Tags:</h2>
                </div>
                <div className="listHomeHashTag d-flex align-items-center mt-10">
                    {
                        this.renderHashItemHome()
                    }
                </div>
                <div onClick={this.showModal} className="btnHash d-flex justify-content-center">
                    <h5>See all</h5>
                </div>
                <Modal id="hashModal" show={this.state.showModal} onHide={this.showModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5>All Tags</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <HashTagModal />
                    </Modal.Body>
                </Modal>
            </section>
        );
    }
}

export default ViewHashTag;
