import React from 'react'
import CheckBox from "./CheckBox";

export class FilterStatic extends React.Component{

    collapseBOX(event){
        if(event.target.id === "freccia"){
            event.target.classList.toggle('active');
        }else{
            event.target.children.freccia.classList.toggle('active');
        }

    }

    render() {
        return(
            <div className='filterBox'>
                <div className='boxCollapse'>
                    <h5 className='label'>{this.props.label}</h5>
                    <a onClick={this.collapseBOX} className="filterCollapse" data-toggle="collapse" href={`#collapse${this.props.id}`} aria-expanded="false" aria-controls={`#collapse${this.props.id}`}>
                        <div id='freccia' className='active'/>
                    </a>
                </div>

                <div className="collapse" id={`collapse${this.props.id}`}>
                    <CheckBox key='1' value='posizione_att_strag_attiva' labelCheck='Active Out-of-court activity' {...this.props} />
                    <CheckBox key='2' value='posizione_proc_monit_attivo' labelCheck='Active Payment injuction' {...this.props} />
                    <CheckBox key='3' value='posizione_proc_esec_mob_attiva' labelCheck='Active Personal Property Foreclosure' {...this.props} />
                    <CheckBox key='4' value='posizione_proc_esec_immob_attiva' labelCheck='Active Real Estate Foreclosure' {...this.props} />
                    <CheckBox key='5' value='posizione_proc_concor_attiva' labelCheck='Active Insolvency procedure' {...this.props} />
                    <CheckBox key='6' value='posizione_proc_falli_attiva' labelCheck='Active Bankruptcy procedure' {...this.props} />
                    <CheckBox key='7' value='posizione_cause_gudizi_in_corso' labelCheck='Ongoing lawsuit' {...this.props} />
                </div>
            </div>
        );
    }
}
