import React, { Component } from 'react';
import * as actions from "../../actions";
import {connect} from "react-redux";
import {PackPrefsList} from "../packs/PackPrefsList";
import {PreferitiHeader} from "../components/PreferitiHeader";
import ViewViewed from "./ViewViewed";
import {Link} from "react-router-dom";

class ViewPrefsPage extends Component{

    initViewPrefs(){
        if(this.props.packs.length === 0){
            return (<div>
                <PreferitiHeader/>
                    <div id="emptyCart" className="container-fluid">
                        <div id="image"></div>
                        <div id="messagioEmpty" className="d-flex justify-content-center">You have no preferred portfolios.</div>
                        <div id="descriptionEmpty" className="d-flex justify-content-center">Add "Star" to portfolios that you want to find later easily</div>
                        <div id="links" className="d-flex justify-content-center">
                            <Link style={{ textDecoration: 'none'}}  to='/site-guide'><a id="link">Take a tour</a></Link>
                            <div id="divider"></div>
                            <Link  style={{ textDecoration: 'none'}} to='/search/portfolios'><a id="link" >Discover our portfolios</a></Link>

                        </div>
                    </div>
                <ViewViewed title='Recently viewed:' operation='Clear all'/>

            </div>)
        }else{
            return <PackPrefsList all={this.props.packs}/>
        }
    }

    componentWillMount() {
        this.props.dispatch(actions.fetchPreferiti());
    }

    render(){
        return(
            this.initViewPrefs()
        );

    }
}

function mapStateToProps(state){

    return{
        packs: state.packs.preferiti
    }
}

export default connect(mapStateToProps)(ViewPrefsPage)
