import React, {Component} from 'react';
import PackCheckCart from "../../shared/packs/PackCheckCart";
import {connect} from "react-redux";
import * as actions from "../../actions";
import {Sort} from "../../shared/components/Sort"
import EmptyPage from "../../shared/components/EmptyPage";
import SearchItemsCount from "../../shared/components/SearchItemsCount";
import {TagList} from "../../shared/components/TagList";
import { ResultLoader } from '../../shared/components/ResultLoader';


class PackListSearched extends Component{

    constructor() {
        super()
        this.state = {
            countPackCartSel: 0,
            formDataPackCart: []
        }
    }

    initPacks(){
        if(this.props.packs.length === 0){
           return this.props.packsFetching ? <div className='col-12'><ResultLoader /></div> : <EmptyPage/>;
        }else {
            return this.props.packs.map((pack, index) => {
                return (
                <PackCheckCart
                    {...this.props}
                    ref={(packCheckCart) => this.child = packCheckCart}
                    key={index}
                    handleChangeTab={this.props.handleChangeTab}
                    colNum='group-3'
                    data={pack}
                    handleCount={this.handleCount}
                />);
            });
        }
    }

    componentWillMount() {
        let urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.has('filtroHome' )) {
            const filtro = this.props.handleChangeTab();
            this.props.dispatch(actions.fetchPortafogliFiltrati(filtro,this.props.counter+1));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        document.getElementById('sort').classList.remove('show')
    }

    handleCount = (id) => {
        if(this.state.formDataPackCart.some(p => p.portafoglioId === id)){
            this.setState((prevState) => {
                return {countPackCartSel: prevState.countPackCartSel - 1};
            })
            this.setState({formDataPackCart: this.state.formDataPackCart.filter(p => p.portafoglioId !== id)});
        } else{
            let list = this.state.formDataPackCart;
            this.setState((prevState) => {
                return {countPackCartSel: prevState.countPackCartSel + 1};
            })
            list.push({portafoglioId: id});
            this.setState({formDataPackCart: list});
        }
    }

    checkAllPacks = (e) => {
        e.target.classList.toggle('selected');
        let list = [];
        let count = 0;

        this.child.setAllPackCart(e);

        if (e.target.className === 'selected') {
            this.props.packs.map((pack) => {
                if (!pack.inCart && pack.actionsEnabled) {
                    list.push({portafoglioId: pack.id});
                    count++;
                }
            });
        }

        this.setState({formDataPackCart: list});
        this.setState({countPackCartSel: count})
    }

    addCart = () => {
        this.props.dispatch(actions.addCarrelloSelected(this.state.formDataPackCart, this.props.handleChangeTab));
        this.setState({countPackCartSel: 0, formDataPackCart: []});
    }

    render(){
        return(
            <section id='portList'>
                <div className='row countFiltered'>
                    {
                        <SearchItemsCount/>
                    }<Sort from='Date' handleChangeTab={this.props.handleChangeTab} handleSortBy={this.props.handleSortBy} handleSortOrder={this.props.handleSortOrder}/>
                </div>
                <div id='checkAllCart'>
                    <span onClick={e => this.checkAllPacks(e)}/>
                    Select all
                </div>
                {
                    <TagList
                        resetFiltroDebitori={this.props.resetFiltroDebitori}
                        resetFiltroLuogoAsset={this.props.resetFiltroLuogoAsset}
                        resetFiltroMonoPosition={this.props.resetFiltroMonoPosition}
                        resetFiltroRange={this.props.resetFiltroRange}
                        resetFiltroCheck={this.props.resetFiltroCheck}
                        resetFiltroHashtag={this.props.resetFiltroHashtag}
                        handleChangeTab={this.props.handleChangeTab}
                    />
                }
                <div className='row tablet'>
                    {this.initPacks()}
                </div>
                <div className={`listPackSelCart ${this.state.countPackCartSel > 0 ? 'show':''}`}>
                    <div className='content'>
                        <div className='label'><span>{this.state.countPackCartSel}</span>Portfolio Selected</div>
                        <div className="btnDet verde" onClick={this.addCart}>
                            <h5>Add to your indication of interest</h5>
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

function mapStateToProps(state){
    return{
        packs: state.packs.data,
        packsFetching: state.packs.fetching,
        counter: state.packs.counter,
    }
}

export default connect(mapStateToProps)(PackListSearched)