import React, {Component} from 'react';
import '../../styles/search/mainSearch.css'
import {connect} from "react-redux";
import * as actions from "../../actions";
import {confirmAlert} from "react-confirm-alert";
import axios from "axios";

import { ReactComponent as IComment } from '../../styles/svg/comment-alt-lines.svg';
import { ReactComponent as IServer } from '../../styles/svg/server.svg';
import { ReactComponent as IStar } from '../../styles/svg/star.svg';
import {ReactComponent as Close} from "../../styles/svg/window-close.svg";
import {ReactComponent as Attach} from "../../styles/svg/paperclip.svg";
import {ReactComponent as Alert} from "../../styles/svg/exclamation-circle.svg";

import {Get, Map} from "react-lodash";
import TextareaAutosize from 'react-autosize-textarea';

export class Composition extends Component{

    constructor(){
        super();
        this.state = {
            assetypes: [],
            borrowerTypes: [],
            debtCategories: []
        }
    }
    componentWillMount() {
        axios.get(`/api-mkt/portfolio/getGroupedCount/${this.props.id}`).then(res => {
            this.setState(res.data)
        })
    }

    setInfoPositions(){
        if(this.state.debtCategories.length > 0){
            return this.state.debtCategories.map((cat) => {
                    return (
                        <div className='attributes'>
                            <div className={`quadrato ${cat.group==='secured'?'verde':'viola'}`}>{cat.count}</div>
                            <div className='label'>{cat.group}</div>
                        </div>
                    )
                });
        }else{
            return false;
        }
    }

    setInfoType(){
        if(this.state.borrowerTypes.length > 0){
            return this.state.borrowerTypes.map((cat) => {
                if(cat.group !== ''){
                    return (
                        <div className='attributes'>
                            <div className='quadrato'>{cat.count}</div>
                            <div className='label'>{cat.group}</div>
                        </div>
                    )
                }
            });
        }else{
            return false;
        }
    }

    setInfoAsset(){
        if(this.state.assetypes.length > 0){
            return this.state.assetypes.map((cat) => {
                if(cat.group !== ''){
                    return (
                        <div className='attributes'>
                            <div className='quadrato'>{cat.count}</div>
                            <div className='label'>{cat.group}</div>
                        </div>
                    )
                }
            });
        }else{
            return (
                <div className='attributes'>
                    <div className='label noFound'>No Assets</div>
                </div>
            )
        }
    }

    render() {
        return(
            <div className='composition'>
                <h5 className='titolo'>Composition:</h5>
                <div className='box'>
                    <div className='type'>
                        <h5>Borrowers:</h5>
                        {this.setInfoPositions()}
                    </div>
                    <div className='type'>
                        <h5>Type of subject:</h5>
                        {this.setInfoType()}
                    </div>
                </div>
                <div className='box asset'>
                    <div className='type'>
                        <h5>Nr. Asset:</h5>
                        {this.setInfoAsset()}
                    </div>
                </div>
            </div>
        )
    }
}


class Datail extends Component{
    constructor(props){
        super(props);
        this.state = {
            payload: '',
            attachments: [],
            allegati :true,
            showError: false,
            errMesg: ""
        };

    }


    componentWillMount() {
        const packId = this.props.id;
        this.props.dispatch(actions.fetchPackById(packId));
    }

    addPref(id) {
        this.props.dispatch(actions.addPreferiti(id));
        }

    addCart(id) {
        this.props.dispatch(actions.addCarrello(id))
    }

    hide = () => this.setState({ open: false, callback: null, errmess: "" });

    onChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name= e.target.name;
        this.setState({[name]: value, errmess: "", showError: false} , ()=> this.submit({[name]: value}) );
    };

    onChangeHandler = event=>{
        const name = event.target.name;
        const file = event.target.files;
        this.setState({[name]: file}, () => this.submit({[name]: file}));
        this.setState(state => ({allegati: !state.allegati}));

    };

    removeAllegato = event =>{
        if(this.state.attachments.length === 0){
            this.deleteAttachments();
        }
        const name = event.currentTarget.parentElement.parentElement.id;
        this.setState({attachments: Array.from(this.state.attachments).filter(item => item.name !== name)}, () => this.submit(this.state.attachments));
        this.setState(state => ({allegati: !state.allegati}));

    }

    deleteAttachments(){
        this.setState({attachments : [], errmess: "", showError: false});
    }


    handleSubmit = (e) => {
        e.preventDefault();
        const portafoglioId = this.props.pack.id;
        const messaggio = this.state.payload;
        const formData = new FormData();
        const msgErrorMexMax = "The message field must not be empty or exceed 255 characters";
        const msgErrorInfoMax = "Sorry, you have already submitted three questions for this portfolio";

        if(messaggio && (messaggio.trim().length < 1 || messaggio.trim().length > 255 )){
            this.setState({ showError: true, errmess: msgErrorMexMax});
            this.submit();
            return;
        }
        
        formData.append("payload", JSON.stringify({portafoglioId, messaggio}));
        for (var i = 0 ; i<this.state.attachments.length;i ++){
            formData.append("attachments" , this.state.attachments[i]);
        }

        axios.post("/api-mkt/messages/requestInfo" , formData ).then((response) => {

            if (response.response && response.response.status === 409){
                this.error();
            } else if(response.response && response.response.status === 400){
                if(response.response.data && response.response.data.errorKey === 'mexmax'){
                    this.setState({ showError: true, errmess: msgErrorMexMax});
                    this.submit();
                } else if (response.response.data && response.response.data.errorKey === 'requestInfomax') {
                    this.setState({ showError: true, errmess: msgErrorInfoMax});
                    this.submit();
                }
            
            } else{
                this.confirm();
                this.setState({attachments : [],payload:''});
            }
        });
    };

    confirm = () => {
        confirmAlert({

            title: <div ><div id="image"></div> <br/> Thank you! Your request has been sent.</div>,
            message: 'Our portfolio specialist will take in charge your information request and recontact you as soon as possible. You will find all the information requests in the Messagges section',
            buttons: [
                {
                    label: 'Close',
                    onClick: () => this.hide()
                }
            ]
        });
    };


    error = () => {
        confirmAlert({

            title: <div ><div id="image"></div> <br/>You already have an open conversation</div>,
            message: 'Go to Messages and send a message to Markagain Admin!',
            buttons: [

                {
                    label: 'Close',
                    onClick: () => this.hide()
                }
            ]
        });
    };

    handleSubmitTape = (e) => {
        e.preventDefault();
        axios.post(`/api-mkt/notifications/requestDataTape/${this.props.pack.id}`);
        this.confirm();

    };

    openRequestInfo = () => {
        this.setState({payload: ''},() => this.submit());
    };

    submit = () => {
            confirmAlert({
            closeOnEscape: true,
            closeOnClickOutside: false,
            customUI: ({onClose}) =>{

                return (
                    <div className='custom-ui'>
                        <p id="headerAlert" className="h4 mb-4">Request info <span id="close" className="h4 mb-4"><Close onClick={()=> {this.deleteAttachments(); onClose();}} id="xclose" width="14px"/></span></p>
                        <form id ="form" onSubmit={this.handleSubmit.bind(this)}>
                            <div id="dataTHelp">
                                <span id="alertIcon"> <Alert fill="#008996" width="24px"/></span>
                                <p id="description">At the end of the communication between you and the portfolio referent, the thread will be notarized using blockchain<br/> <a target="_blank" id="linkData" href='/site-guide'>Find out more</a></p>

                            </div>

                            <div id="dataTHelp" style={{ marginTop: '5px' }}>
                                <span id="alertIcon"> <Alert fill="#008996" width="20px"/></span>
                                <p id="description">Please note that you can only submit three questions per portfolio and the maximum message length is 255 characters</p>

                            </div>

                            <div className="message_write">
                                <div className="textandinput">
                                    <TextareaAutosize name="payload" id="textarea" className="form-control" onChange={this.onChange} placeholder="Start writing"/>
                                    <div className="p-2">
                                    {this.state.attachments ? <div id="containerAllegati"><Map collection={this.state.attachments} iteratee={name =><li id={name.name}><Get object={name} path={'name'}></Get><span id="allegatoSize"><Get object={name} path={'size'}>{value => (parseFloat(value)/1000000).toFixed(2)}</Get>MB <Close onClick={this.removeAllegato.bind(name.id)}fill="#979da5" width="9px"/> </span> </li>}/> </div>: <div></div>}
                                    </div>
                                    <div className="borderUpload">
                                        <input className="file" name="attachments" multiple id="file" onChange={this.onChangeHandler} type="file"/>
                                    </div>
                                    <label id="uploadButton" name="attachments" type="button btn" htmlFor="file"><Attach id="icon" height="21px" width="14px"/> Attach files</label>
                                    <div className={`counter ${this.state.payload.length > 255 ? 'error': ''}`}>{this.state.payload.length} / 255</div>
                                </div>
                                <div className="clearfix"></div>

                                {this.state.showError && (
                                    <div className="error">
                                        {this.state.errmess}
                                        <span id="close" className="h4 mb-4" style={{ margin: '0 10px' }}></span>
                                    </div>)
                                }

                                <div className="chat_bottom">
                                    <div className="chatbutton">
                                        <div>
                                            <button disabled={!this.state.payload} onClick={this.handleSubmit.bind(this)}>Send</button>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            {/*<textarea type="textarea" name="textarea" id="textarea" style={{resize:'none'}} className="form-control rounded-0" required rows="6"  onChange={this.onChange}></textarea>*/}
                            {/*<br/>*/}
                            {/*<div className="carrelloheader">*/}
                            {/*    <input type="file" name="uploadfiles" onChange={this.onChangeHandler}/>*/}
                            {/*    /!*<button id="buttonann" className="btn btn-light">Allega File</button>*!/*/}
                            {/*    <button id="buttoncont" className="btn btn-info btn-block col-5" type="submit" >Invia</button>*/}
                            {/*</div>*/}
                        </form>
                    </div>

                );

            }
        });
    };

    submitTape = () => {
        confirmAlert({
            closeOnEscape: true,
            closeOnClickOutside: false,
            customUI: ({onClose}) => {

                return (

                    <div className='custom-ui'>
                        <p id="headerAlert" className="h4 mb-4">Request Data Tape <span id="close" className="h4 mb-4"><Close onClick={onClose} id="xclose" width="14px"/></span></p>

                        <form id="form" onSubmit={this.handleSubmitTape.bind(this)}>
                                <div id="dataTHelp">
                                    <span id="alertIcon"> <Alert fill="#008996" width="24px"/></span>
                                    <p id="description">At the end of the communication between you and the portfolio referent, the thread will be notarized using blockchain<br/> <a target="_blank" id="linkData" href='/site-guide'>Find out more</a></p>

                                </div>
                            <div className="p-4">
                                <p id="dataT" className="h7 mb-4">Do you want to request the Data Tape for the <span id="nomePacchetto"> {this.props.pack.denominazione}</span> portfolio?</p>
                                <p>You will be contacted by the portfolio specialist in the Messagges section.</p>
                                <div className="clearfix"></div>
                                <div className="chat_bottom">
                                    <div className="chatbutton">
                                        <div id="dataTButtons" className="d-flex justify-content-start">
                                            <button id="dataTapeAnnulla" onClick={onClose}>Cancel</button>
                                            <div id="divider"></div>
                                            <button id="dataTape" onClick={this.handleSubmitTape.bind(this)}>Send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<textarea type="textarea" name="textarea" id="textarea" style={{resize:'none'}} className="form-control rounded-0" required rows="6"  onChange={this.onChange}></textarea>*/}
                            {/*<br/>*/}
                            {/*<div className="carrelloheader">*/}
                            {/*    <input type="file" name="uploadfiles" onChange={this.onChangeHandler}/>*/}
                            {/*    /!*<button id="buttonann" className="btn btn-light">Allega File</button>*!/*/}
                            {/*    <button id="buttoncont" className="btn btn-info btn-block col-5" type="submit" >Invia</button>*/}
                            {/*</div>*/}
                        </form>
                    </div>

                );
            }
        });
    };

    currencyFormatDE(num) {
        if(num !== undefined){
            return (
                num
                    .toFixed(0)
                    .replace('.', ',')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            )
        }
    }

    capitalize = (str) => {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    renderHashtag = () => {
        if (this.props.hashtags.length > 0) {
            return (
                <div className="listHomeHashTag d-flex align-items-center mt-10">
                    {
                        this.props.hashtags.map(hash => {
                            return (
                                <div className="homeItemHash">{this.capitalize(hash.descrizione)}</div>
                            );
                        })
                    }
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div id="detailPort">
                <div className='info'>
                    <div className='stato'>
                        <div className='statoData'>
                        <h6 className={`tipo ${(this.props.pack.stato==='Offer in evaluation' || this.props.pack.stato==='On sale') ? 'onSale':''}`}><span className='point'/>{this.props.pack.stato}
                            <span className='data'>{this.props.pack.dataInizioValid} - </span>
                            <span>{this.props.pack.dataFineValid}</span>
                        </h6>
                        </div>
                    </div>
                    <div className='header'>
                        <div className='titolo'>
                            {this.props.pack.denominazione}
                        </div>
                        <div className='gbv'>
                            {this.currencyFormatDE(this.props.pack.gbv)}
                        </div>
                    </div>
                    <div className='seller'>
                        <h5>Seller: <span>{this.props.pack.mandante}</span></h5>
                        <h5>Servicer: <span>Cerved</span></h5>
                    </div>
                    <div className='description'>
                        <h5>{this.props.pack.descrizione}</h5>
                    </div>
                    <div className='comunicationRef'>
                        <h5>Comunication Referent: <span>{this.props.pack.referenteComNom}</span></h5>
                    </div>
                    {
                        this.renderHashtag()
                    }
                    {window.innerWidth > 500?
                    <div>
                        <div className='buttonsDetail'>
                            <div className='request'>
                                <div className={`btnDet grigio ${!this.props.pack.actionsEnabled?'disable':''}`} onClick={ this.props.pack.actionsEnabled? this.openRequestInfo:''}>
                                    <h5 ><IComment/> Request Info</h5>
                                </div>
                                <div className={`btnDet grigio ${!this.props.pack.actionsEnabled?'disable':''}`} onClick={ this.props.pack.actionsEnabled? this.submitTape:''} >
                                    <h5 ><IServer/> Request Data Tape</h5>
                                </div>
                            </div>
                            <div className='add'>
                                <div className={`btnDet pref ${this.props.pack.preferred?'prefered':''} ${!this.props.pack.actionsEnabled?'disable':''}`} onClick={this.props.pack.actionsEnabled? this.addPref.bind(this, this.props.pack.id):''}>
                                    <h5><IStar/></h5>
                                </div>
                                <div className={`btnDet verde ${!this.props.pack.actionsEnabled?'disable':''}`} onClick={this.props.pack.actionsEnabled? this.addCart.bind(this, this.props.pack.id):''}>
                                    <h5>
                                        { this.props.pack.inCart ? 'Remove from ' : 'Add to ' } your indication of interest
                                    </h5>
                                </div>
                            </div>   
                        </div>
                        
                        <div className={`btnDet' ${!this.props.pack.actionsEnabled?'disable':''}`}>
                            <h5><a className='anchorLink verdeLink' id='qa' href='#qa' onClick={this.setHighlight}>Q&A</a></h5>
                        </div>
                    </div>   
                    :''}

                </div>
                <Composition id={this.props.id}/>
                {window.innerWidth < 500?
                <div>
                    <div className='info btn'>
                        <div className='buttonsDetail'>
                            <div className='request'>
                                <div className="btnDet grigio" onClick={this.openRequestInfo}>
                                    <h5 ><IComment/> Request Info</h5>
                                </div>
                                <div className="btnDet grigio" onClick={this.submitTape} >
                                    <h5 ><IServer/> Request Data Tape</h5>
                                </div>
                            </div>
                            <div className='add'>
                                <div className={`btnDet pref ${this.props.pack.preferred?'prefered':''}`} onClick={this.addPref.bind(this, this.props.pack.id)}>
                                    <h5><IStar/></h5>
                                </div>
                                <div className="btnDet verde" onClick={this.addCart.bind(this, this.props.pack.id)}>
                                    <h5>
                                        { this.props.pack.inCart ? 'Remove from ' : 'Add to ' } your indication of interest
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className={`btnDet' ${!this.props.pack.actionsEnabled?'disable':''}`} style={{padding: "10px 10px 10px 10px"}}>
                        <h5><a className='anchorLink verdeLink' id='qa' href='#qa' onClick={this.setHighlight}>Q&A</a></h5>
                    </div>
                </div>
                :''}
                    
            </div>



        );
    }


}

function mapStateToProps(state){
    let hashtags = []
    if (state.pack.data.hashtags !== undefined && state.pack.data.hashtag !== null) {
        hashtags = state.pack.data.hashtags.length > 0 ? [state.pack.data.hashtag].concat(state.pack.data.hashtags) : [state.pack.data.hashtag]
    }
    return{
        pack: state.pack.data,
        hashtags: hashtags
    }
}

export default connect(mapStateToProps)(Datail)
