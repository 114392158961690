import React from 'react';

class CookiePolicy extends React.Component{
    componentDidMount() {
        window.loaderIub(document);
    }
    render() {
        const label = "Cookie Policy";
        const contentLabel = this.props.small ? (<h5>{label}</h5>): label;
        return(
            <a href="https://www.iubenda.com/privacy-policy/66700813/cookie-policy" className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iubenda-nostyle width-auto height-auto" title="Cookie Policy ">
                {contentLabel}
            </a>
        );
    }
}
export default CookiePolicy;