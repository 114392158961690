import React from 'react';
import { TabsDetail } from './TabsDetail';
import Detail from './Datail';
import FilterPos from '../../shared/components/FilterPos';
import {connect} from "react-redux";
import { CountPos } from '../../shared/components/CountPos'
import * as actions from "../../actions";
import axios from "axios";
import '../../styles/search/mainSearch.css'
import QuestionAnswer from "../../components/questionAnswer/QuestionAnswer"


class SearchDetail extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            gbvValues: [],
            tipoDiCreditoValues: [],
            controparteValues: [],
            caratteristicheAssetValues: [],
            luogoAsset: '',
            residenzaDebitori: '',
            procedimentiInCorsoValues: [],
            sortBy: 'Id',
            sortOrder: 'Desc',
        }
        this.resetFiltroRange = this.resetFiltroRange.bind(this)
    }
    // stateValue = {
    //     gbvValues: [],
    //     tipoDiCreditoValues: [],
    //     controparteValues: [],
    //     caratteristicheAssetValues: [],
    //     luogoAsset: '',
    //     residenzaDebitori: '',
    //     procedimentiInCorsoValues: []
    // };


    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.location.pathname === this.props.location.pathname) {
            const tab = nextProps.location.pathname.split("/");
            if (tab.includes("positions")) {
                this.props.dispatch(actions.fetchPositionsByPortafoglioId(nextProps.match.params.id, nextState, activePage, itemsPerPage));
            } else if (tab.includes("strats")) {
                this.props.dispatch(actions.fetchPosizioniTipoDiCredito(nextProps.match.params.id, nextState));
                this.props.dispatch(actions.fetchPosizioniControparte(nextProps.match.params.id, nextState));
                this.props.dispatch(actions.fetchPosizioniDestUso(nextProps.match.params.id, nextState));
                this.props.dispatch(actions.fetchPosizioniAreaGeografica(nextProps.match.params.id, nextState));
                axios.post(`/api-mkt/search/positions/sortByFilters/${nextProps.match.params.id}?page=0&size=10`, {
                    gbvValues: nextState.gbvValues,
                    tipoDiCreditoValues: nextState.tipoDiCreditoValues,
                    controparteValues: nextState.controparteValues,
                    caratteristicheAssetValues: nextState.caratteristicheAssetValues,
                    luogoAsset: nextState.luogoAsset,
                    residenzaDebitori: nextState.residenzaDebitori,
                    procedimentiInCorsoValues: nextState.procedimentiInCorsoValues,
                    sortBy: nextState.sortBy,
                    sortOrder: nextState.sortOrder
                }).then(res => {
                    this.countPostionsFilter(res.data.positionCount);
                })
            } else {

            }
        }
    }

    handleInputRange = (value) =>{
        const values = [value.min, value.max];
        this.setState({gbvValues: values});
        // this.stateValue.gbvValues = values; fase2
    }

    handleSortBy = (event) => {
        const value = event.target.getAttribute('data-attr');
        const order = event.target.getAttribute('data-order');
        this.setState({sortBy: value, sortOrder: order});
    }

    handleSortOrder = (event) => {
        const value = event.target.parentElement.getAttribute('data-attr')
        this.setState({sortOrder: value});
    }

    handlerMostraRis = () =>{
        // this.setState({gbvValues : this.stateValue.gbvValues});
        // this.setState({tipoDiCreditoValues : this.stateValue.tipoDiCreditoValues});
        // this.setState({controparteValues : this.stateValue.controparteValues});
        // this.setState({caratteristicheAssetValues : this.stateValue.caratteristicheAssetValues});
        // this.setState({luogoAsset : this.stateValue.luogoAsset});
        // this.setState({residenzaDebitori : this.stateValue.residenzaDebitori});
        // this.setState({procedimentiInCorsoValues : this.stateValue.procedimentiInCorsoValues});

        this.setState({gbvValues : []});
        this.setState({tipoDiCreditoValues : []});
        this.setState({controparteValues : []});
        this.setState({caratteristicheAssetValues : []});
        this.setState({luogoAsset : ''});
        this.setState({residenzaDebitori : ''});
        this.setState({procedimentiInCorsoValues : []});

    }

    handleInputAutocompile = (newValue,id) =>{
        this.setState({
            [id]: newValue
        });
        // this.stateValue[id] = newValue; fase2
    }

    handleCheckElement = (event) => {
        const stateId = event.target.id;
        const value = {value: event.target.value, solo: false};
        let valueState = this.state[stateId];
        let indexSolo = valueState.findIndex(x => x.solo === true);
        let soli = event.target.parentNode.parentNode.getElementsByClassName('solo');
        let solo = event.target.parentNode.getElementsByClassName('solo').item(0);

        if(event.target.checked){
            if(indexSolo >= 0){
                valueState[indexSolo].solo = false;
                Array.from(soli).forEach((s) => {
                    s.classList.remove('select');
                });
            }
            valueState.push(value)
        }else{
            valueState = valueState.filter(val => val.value !== value.value)
            if(solo.classList.contains('select')){
                solo.classList.toggle('select');
            }

        }

        this.setState({ [stateId]: valueState})
        // this.stateValue[stateId] = valueState; fase2
    }

    handleSoloElement = (event) => {
        const stateId = event.target.id;
        const value = {value: event.target.getAttribute('data-filter'), solo: true};
        let valueState = this.state[stateId];
        let inputs = event.target.parentNode.parentNode.getElementsByTagName('input');
        let soli = event.target.parentNode.parentNode.getElementsByClassName('solo');

        if(!event.target.classList.contains("select")){
            valueState = [];
            Array.from(inputs).forEach((input, index) => {
                soli[index].classList.remove('select');
                if(input.name !== value.value)
                    input.checked = false;
                else
                    input.checked = true;
            });
            valueState.push(value)
            event.target.classList.add("select");

        }else{
            event.target.classList.remove("select");
            valueState = valueState.filter(val => val.value !== value.value);
            const valueChanged = {value: event.target.getAttribute('data-filter'), solo: false};
            valueState.push(valueChanged);
        }

        this.setState({ [stateId]: valueState})
        // this.stateValue[stateId] = valueState; fase2
    }


    showFilter() {
        document.getElementById('filterContainer').classList.toggle('showFilter');
        document.getElementById('over').classList.toggle('show');
    }

    handleChangeTab = () => {
        return this.state
    }

    resetAll = (idPort, e) => {
        this.props.dispatch(actions.fetchFiltriPosValues(idPort));
        Array.from(document.getElementsByTagName('input')).forEach((el) => {
            el.checked = false;
            if(el.type === 'text'){
                el.value = '';
            }
        });
        Array.from(document.getElementsByClassName('solo')).forEach((el) => {
            el.classList.remove('select')
        });
        if(document.getElementById('autosuggestluogoAsset') != null)
            document.getElementById('autosuggestluogoAsset').getElementsByTagName('input').item(0).value = "";
        if(document.getElementById('autosuggestresidenzaDebitori') != null)
            document.getElementById('autosuggestresidenzaDebitori').getElementsByTagName('input').item(0).value = "";

        // fase 2
        // this.stateValue.gbvValues = [];
        // this.stateValue.tipoDiCreditoValues = [];
        // this.stateValue.controparteValues = [];
        // this.stateValue.caratteristicheAssetValues = [];
        // this.stateValue.luogoAsset = '';
        // this.stateValue.residenzaDebitori = '';
        // this.stateValue.procedimentiInCorsoValues = [];

        this.handlerMostraRis();
    }

    countPostionsFilter = (value) =>{
        this.refs.count.setValuePos(value);
    };

    componentDidMount() {
        document.getElementById('marketPlace').classList.add('grigio');
    }
    componentWillUnmount() {
        document.getElementById('marketPlace').classList.remove('grigio');
    }

    resetFiltroCheck = (inputName) =>{
        Array.from(document.getElementsByTagName('input')).forEach((el) => {
            if(el.name === inputName){
                el.click();
            }
        });
    }

    getFiltroRange =(ref)=>{
        this.rangeRef = ref
    }

    resetFiltroRange(){
        const min = document.getElementsByClassName('input-range__slider').item(0).getAttribute('aria-valuemin');
        const max = document.getElementsByClassName('input-range__slider').item(1).getAttribute('aria-valuemax')
        const valMinMax = {
            min: min,
            max: max
        }
        this.rangeRef.setState(() => {this.rangeRef.state.value = valMinMax});
        this.rangeRef.forceUpdate();
        const values = [];
        this.setState({gbvValues: values});
    }

    resetFiltroMonoPosition = () =>{
        document.getElementById('all').classList.toggle('select');
        document.getElementById('single').classList.toggle('select');
        this.setState({onlyMonoposizione : false});
    }

    resetFiltroLuogoAsset = () =>{
        this.setState({luogoAsset : ''});
        document.getElementById('autosuggestluogoAsset').getElementsByTagName('input').item(0).value = "";

    }

    resetFiltroDebitori = () =>{
        this.setState({residenzaDebitori : ''});
        document.getElementById('autosuggestresidenzaDebitori').getElementsByTagName('input').item(0).value = "";
    }


    render() {
        return (
            <div id='searchDetail' className='row'>
                <div id='history'>
                </div>
                <Detail id={this.props.match.params.id}/>
                <div id='filtroPos'>
                    <FilterPos getFiltroRange={this.getFiltroRange} idPort={this.props.match.params.id} resetAll={this.resetAll} showFilter={this.showFilter} handleCheckElement={this.handleCheckElement} handleSoloElement={this.handleSoloElement} handleInputAutocompile={this.handleInputAutocompile} handleInputRange={this.handleInputRange}/>
                    <div id='mostraRis' onClick={this.handlerMostraRis}>
                        <h5>Mostra risultati</h5>
                    </div>
                </div>
                <div className='col s'>

                    <CountPos
                        showFilter={this.showFilter}
                        resetFiltroDebitori={this.resetFiltroDebitori}
                        resetFiltroLuogoAsset={this.resetFiltroLuogoAsset}
                        resetFiltroMonoPosition={this.resetFiltroMonoPosition}
                        resetFiltroRange={this.resetFiltroRange}
                        resetFiltroCheck={this.resetFiltroCheck}
                        ref='count' idPort={this.props.match.params.id}
                        filtered={this.state}
                        handleChangeTab={this.handleChangeTab}
                    />

                    <TabsDetail props={this.props} handleChangeTab={this.handleChangeTab} countPostionsFilter={this.countPostionsFilter} handleSortBy={this.handleSortBy} handleSortOrder={this.handleSortOrder}/>
                </div>

                <div style={{width: "100%"}}>
                    <QuestionAnswer idPortafoglio={this.props.match.params.id} />
                </div>
            </div> 
        );
    }
}

function mapStateToProps(state) {
    return {     
        isgestore: state.conversations.isgestore,
    }
}

export default connect(mapStateToProps) (SearchDetail)

let activePage= 0;
export const itemsPerPage= 10;
