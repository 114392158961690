import React, {Component} from "react";
import '../../styles/components/messaggidettaglio.css'
import {Map} from "react-lodash";
import { ReactComponent as File } from '../../styles/svg/file-alt.svg';
import axios from "axios";

export class MessaggioDettaglio extends Component{

    downloadAttachments = (id) => {
        axios({
            url: `/api-mkt/common/download/${id}`,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {

            if (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                const contentDisposition = response.headers['content-disposition'];
                var filename = contentDisposition.substring(contentDisposition.indexOf('filename=') + 9);
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        });
    };

    componentDidMount(){
        if(window.innerWidth > 500) {
            if (window.innerHeight > document.getElementById('root').clientHeight) {
                document.getElementById('question').classList.remove('fixed')
            } else {
                document.getElementById('question').classList.add('fixed')
            }
        }
    }

    render() {

        return (
            <div id="messageDetailBorder" className="container pt-2">
                <div id="circle" className={`d-flex float-left ${this.props.data.gestore?'gestore':''}`}>
                    {this.props.data.initials}
                </div>
                <div id="messages" className="container flex-row">
                    <div id="content" className="d-flex justify-content-between">
                        <div id='nome' className="mr-auto p-2">{this.props.data.nomeUtente} </div>
                        <div id="dataConversation" className="p-2">{this.props.data.dataCreazione}</div>
                    </div>
                    <div className="d-flex justify-content-start wordWrap">
                        <div id="testoConversation" className="p-2 pb-4">
                            {this.props.data.testo}
                        </div>
                    </div>
                    <div className="allegatiConversation">
                        <Map collection={this.props.data.allegati} iteratee={i => <li onClick={this.downloadAttachments.bind(this, i.id)} key={i}><File fill="#007e8a" height="24px" width="18px"/><div className='d-flex flex-column'><div className='nomeFile'>{i.filename}</div><div className='mbFile'>{i.mb} MB</div></div></li>} />
                    </div>
                </div>
            </div>
        )
    }
}
