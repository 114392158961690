import React, {Component} from 'react';
import {MapWithFitBounds} from "../../shared/maps/MapDetail";
import * as actions from "../../actions";
import {connect} from "react-redux";
import {ReactComponent as ICompress} from "../../styles/svg/compress.svg";
import {ReactComponent as IExpand} from "../../styles/svg/expand.svg";
import {ReactComponent as ISlider} from "../../styles/svg/sliders-h.svg";
import SearchItemsCount from "../../shared/components/SearchItemsCount";
import {ResultLoader} from "../../shared/components/ResultLoader";
import EmptyPage from "../../shared/components/EmptyPage";
import {TagList} from "../../shared/components/TagList";

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: true
        }
    }

    stateInit = false;

    componentWillMount() {
        const filtro = this.props.handleChangeTab();
        this.props.dispatch(actions.fetchMapFiltrati(filtro));
        // this.props.dispatch(actions.fetchPortafogliFiltrati(filtro));
    }

    fullscreen() {
        document.getElementsByClassName('map').item(0).classList.toggle('fullscreen');
    }

    showMapFilter() {
        document.getElementById('filterContainer').classList.toggle('show');
        document.getElementById('over').classList.toggle('show');
    }

    render() {
        {
            if (this.props.assets && (this.props.assets.assetList && this.props.assets.assetList.length > 0) || (this.props.assets.borrowerList && this.props.assets.borrowerList.length > 0)) {
                return (
                    <div className='containerMapSearchTab'>
                        <div className='row mb-3'>
                            {
                                <SearchItemsCount/>
                            }
                        </div>
                        {
                            <TagList
                                resetFiltroDebitori={this.props.resetFiltroDebitori}
                                resetFiltroLuogoAsset={this.props.resetFiltroLuogoAsset}
                                resetFiltroMonoPosition={this.props.resetFiltroMonoPosition}
                                resetFiltroRange={this.props.resetFiltroRange}
                                resetFiltroCheck={this.props.resetFiltroCheck}
                                handleChangeTab={this.props.handleChangeTab} />
                        }
                        <div className='row'>
                            <div className='col-12 nopadding'>
                                <div
                                    className={`map ${window.innerWidth < 500 ? this.state.fullscreen ? 'fullscreen' : '' : ''}`}>
                                    {window.innerWidth < 500 ?
                                        <div>
                                            <div id='compressMap' onClick={this.fullscreen}>
                                                <ICompress/>
                                            </div>
                                            <div id='expandMap' onClick={this.fullscreen}>
                                                <IExpand/>
                                            </div>
                                            <div id="buttonMapFiltra" onClick={this.showMapFilter}>
                                                <ISlider/><h4></h4>
                                            </div>
                                        </div> : ''
                                    }
                                    <MapWithFitBounds
                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCKPVmJuHKuTsnWnllTn-aFb6OZY74F_Lk&libraries=geometry,drawing,places"
                                        loadingElement={<div style={{height: `100%`}}/>}
                                        containerElement={<div style={{height: `600px`}}/>}
                                        mapElement={<div style={{height: `100%`}}/>}
                                        markers={this.props.assets}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                if(this.stateInit){
                    return (
                        <div className='containerMapSearchTab'>
                            <div className='row mb-3'>
                                {
                                    <SearchItemsCount/>
                                }
                            </div>
                            {
                                <TagList
                                    resetFiltroDebitori={this.props.resetFiltroDebitori}
                                    resetFiltroLuogoAsset={this.props.resetFiltroLuogoAsset}
                                    resetFiltroMonoPosition={this.props.resetFiltroMonoPosition}
                                    resetFiltroRange={this.props.resetFiltroRange}
                                    resetFiltroCheck={this.props.resetFiltroCheck}
                                    handleChangeTab={this.props.handleChangeTab} />
                            }
                            <EmptyPage/>
                        </div>
                    )
                }else{
                    this.stateInit = true;
                    return(
                        <ResultLoader />
                    )
                }
            }
        }

    }
}

function mapStateToProps(state) {
    return {
        assets: state.assets.dataMapPort,
        // packs: state.packs.data
    }
}

export default connect(mapStateToProps)(Map)
