import React from 'react';
import { ReactComponent as ISortUp } from '../../styles/svg/sort-amount-up.svg';
import { ReactComponent as ISortDown } from '../../styles/svg/sort-amount-down.svg';

export class Sort extends React.Component{

    openSelect(){
        document.getElementById('sort').classList.toggle('show');
    }

    render() {
        const filtro = this.props.handleChangeTab();
        let label = '';
        if(filtro.sortOrder === 'Desc'){
            if(filtro.sortBy === 'Id' || filtro.sortBy === 'Date'){
                label = '(most recent)';
            }else{
                label = '(more expensive)';
            }
        }else{
            if(filtro.sortBy === 'Id'|| filtro.sortBy === 'Date'){
                label = '(less recent)';
            }else{
                label = '(less expensive)';
            }
        }
        const valueFrom = this.props.from;
        return(
            <div id='sort'>
                <div id='selected'>
                    <div id='colonna' onClick={this.openSelect}>
                        <h5>Sort by: <span>{filtro.sortBy} {label}</span></h5>
                    </div>
                    <div id='typeOrder' data-attr={filtro.sortOrder === 'Desc'?'Asc':'Desc'}>
                        {filtro.sortOrder === 'Asc'?<ISortUp/>:<ISortDown/>}
                    </div>
                </div>
                <div id='other'>
                    <div className='itemOrder' data-attr={valueFrom} data-order="Desc" onClick={this.props.handleSortBy}>{`${valueFrom} (most recent)`}</div>
                    <div className='itemOrder' data-attr={valueFrom} data-order="Asc" onClick={this.props.handleSortBy}>{`${valueFrom} (less recent)`}</div>
                    <div className='itemOrder' data-attr='Gbv' data-order="Desc" onClick={this.props.handleSortBy}>Gbv (most expensive)</div>
                    <div className='itemOrder' data-attr='Gbv' data-order="Asc" onClick={this.props.handleSortBy}>Gbv (less expensive)</div>
                </div>
            </div>
        )
    }
}
