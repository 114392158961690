export const FETCH_PACKS = 'FETCH_PACKS';
export const FETCH_PACK_SUCCESS ='FETCH_PACK_SUCCESS';
export const FETCH_PACK_BY_ID_SUCCESS = 'FETCH_PACK_BY_ID_SUCCESS';
export const FETCH_PACK_BY_ID_INIT = 'FETCH_PACK_BY_ID_INIT';
export const FETCH_SEARCH_SUCCESS ='FETCH_SEARCH_SUCCESS';
export const FETCH_POSITIONS ='FETCH_POSITIONS';
export const FETCH_POSITIONS_SUCCESS ='FETCH_POSITIONS_SUCCESS';
export const FETCH_PREFERITI_SUCCESS = 'FETCH_PREFERITI_SUCCESS';
export const FETCH_VISUALIZZATI_SUCCESS = 'FETCH_VISUALIZZATI_SUCCESS';
export const FETCH_CARRELLO_SUCCESS = 'FETCH_CARRELLO_SUCCESS';
export const FETCH_PORTPOSITIONS_SUCCESS = 'FETCH_PORTPOSITIONS_SUCCESS';
export const FETCH_POSIZIONE_BYID ='FETCH_POSIZIONE_BYID';
export const FETCH_CONVERSAZIONE_SUCCESS = 'FETCH_CONVERSAZIONE_SUCCESS';
export const FETCH_ASSISTENZA_SUCCESS = 'FETCH_ASSISTENZA_SUCCESS';
export const FETCH_MESSAGGIBYID_SUCCESS = 'FETCH_MESSAGGIBYID_SUCCESS';
export const FETCH_PORTAFOGLIOSTRATS_SUCCESS = 'FETCH_PORTAFOGLIOSTRATS_SUCCESS';
export const FETCH_ASSETS_SUCCESS = 'FETCH_ASSETS_SUCCESS';
export const FETCH_ASSETSMAP_INIT = 'FETCH_ASSETSMAP_INIT';
export const FETCH_ASSETSMAP_SUCCESS = 'FETCH_ASSETSMAP_SUCCESS';
export const FETCH_ASSETSMAPSBYPORTID_INIT = 'FETCH_ASSETSMAPSBYPORTID_INIT';
export const FETCH_ASSETSBYPORTID_SUCCESS = 'FETCH_ASSETSBYPORTID_SUCCESS';
export const FETCH_ASSETSMAPSBYPORTID_SUCCESS = 'FETCH_ASSETSMAPSBYPORTID_SUCCESS';
export const FETCH_PORTAFOGLIOCONTROPARTE_SUCCESS = 'FETCH_PORTAFOGLIOCONTROPARTE_SUCCESS';
export const FETCH_PORTAFOGLIODESTINAZIONEUSO_SUCCESSO = 'FETCH_PORTAFOGLIODESTINAZIONEUSO_SUCCESSO';
export const FETCH_PORTAFOGLIOAREAGEOGRAFICA_SUCCESSO = 'FETCH_PORTAFOGLIOAREAGEOGRAFICA_SUCCESSO';
export const FETCH_FILTRIPORTVALUES_SUCCESS = 'FETCH_FILTRIPORTVALUES_SUCCESS';
export const FETCH_FILTRIPOSVALUES_SUCCESS = 'FETCH_FILTRIPOSVALUES_SUCCESS';
export const FETCH_DATAFILTERED_SUCCESS = 'FETCH_DATAFILTERED_SUCCESS';
export const FETCH_IS_GESTORE = 'FETCH_IS_GESTORE';
export const FETCH_COUNT_INDICATIONOF_INTEREST = 'FETCH_COUNT_INDICATIONOF_INTEREST';
export const FETCH_MESSAGES_PRESENT = 'FETCH_MESSAGES_PRESENT';
export const FETCH_FAVORITES_PRESENT = 'FETCH_FAVORITES_PRESENT';
export const ERASE_DATAFILTERED = 'ERASE_DATAFILTERED';
