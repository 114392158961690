import React, {Component} from "react";
import Carrello from "../../pages/carrello/Carrello";
import {CarrelloHeader} from "../components/CarrelloHeader";

export class PackCartList extends Component{

    initPacks(){
        const p = this.props.all;
        return p.map((pack, index) => {
            return <Carrello key={index} colNum='group-4' data={pack} type={pack.type}/>
        });
    }


    render(){
        return(
            <section id='portList' className={this.props.all.type}>
                <CarrelloHeader count={this.props.all.length}/>
                <div className='row tablet prefCarTablet'>
                    {this.initPacks()}
                </div>
            </section>
        );
    }

}