import React from 'react';
import axios from "axios";

export class Count extends React.Component{
    constructor(){
        super()
        this.state = {
            value: 0
        }
    }

    componentWillMount() {
        axios.get('/api-mkt/portfolio/countAll').then(res => {
            this.setState({value: res.data});
        })
    }

    render(){
        return (
            <span>{this.state.value}</span>
        )
    }
}

