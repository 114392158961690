import React from 'react';
import { PrivacyCookiePolicy } from './PrivacyCookiePolicy';

export function FooterApp(){
    return(
        <footer id='footer'>
            <div className='row'>
                <div className='content'>
                    <h6>@2020 Cerved Credit Management Group S.r.l. - P.I. IT07549040967 – All rights reserved.</h6>
                </div>
                <div className='privacy'>
                    <a className="mr-5" href={process.env.REACT_APP_LUX_URL} target="_blank"><h5> lux.markagain.it </h5></a>
                    <PrivacyCookiePolicy small/>
                </div>
            </div>
        </footer>
    );
}
