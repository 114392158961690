import React from 'react';
import {Link} from "react-router-dom";
import { ReactComponent as ILogin } from '../../styles/svg/external-link-square.svg';

import '../../styles/areaPubblica/buyer.css';
import { Footer } from './Footer';

class About extends React.Component {

    componentDidMount() {
        document.getElementById('body').classList.add('big');
        if(window.innerWidth < 500){
            document.getElementById('body').classList.add('public');
        }
        document.getElementById('marketPlace').classList.add('hide');
    }
    componentWillUnmount() {
        document.getElementById('body').classList.remove('big');
        if(window.innerWidth < 500){
            document.getElementById('body').classList.remove('public');
        }
        document.getElementById('marketPlace').classList.remove('hide');
    }

    openMenu() {
        document.getElementById('openPublic').classList.toggle('open');
    }

    writeMail() {
        window.location.href = "mailto:"+process.env.REACT_APP_MAILTO;
    }

    render() {
        return (
            <div id='about'>
                <div className='fasciaHeader'>
                    <div id='headerPublic'>
                        {window.innerWidth > 500?
                            <div className='logo'>
                                <Link to='/'><img src={require('../../styles/images/header.png')}/></Link>
                            </div>
                            :
                            <div className='menuMobile'>
                                <div className='logo'>
                                    <Link to='/'><img src={require('../../styles/images/header.png')}/></Link>
                                </div>
                                <div className='btnMenu' onClick={this.openMenu}>
                                    <img src={require('../../styles/images/openMenu.png')}/>
                                </div>
                            </div>
                        }
                        {window.innerWidth > 500?
                            <div id='openPublic' className='linkHeader'>
                                <Link to='/for-buyer'><h5>For Buyer</h5></Link>
                                <Link to='/for-seller'><h5>For Seller</h5></Link>
                                <Link to='/about'><h5 className='active'>About Us</h5></Link>
                                {/*<Link to='/faq'><h5>FAQ</h5></Link>*/}
                                <a href={process.env.REACT_APP_CAS_LOGIN_URL + window.location.origin + '/home'}>
                                    <div className='login'>
                                        <ILogin width='14px' fill='#008996'/><h5>Log In</h5>
                                    </div>
                                </a>
                                <Link to='/signup'>
                                    <div className='signIn'>
                                        <h5>Sign Up</h5>
                                    </div>
                                </Link>
                            </div>
                            :
                            <div id='openPublic' className='linkHeader'>
                                <div className='linkMenu'>
                                    <Link to='/for-buyer'><h5>For Buyer</h5></Link>
                                    <Link to='/for-seller'><h5>For Seller</h5></Link>
                                    <Link to='/about'><h5 className='active'>About Us</h5></Link>
                                    {/*<Link to='/faq'><h5>FAQ</h5></Link>*/}
                                </div>
                                <div className='buttonMenu'>
                                    <Link to='/home'>
                                        <div className='login'>
                                            <ILogin width='14px' fill='#008996'/><h5>Log In</h5>
                                        </div>
                                    </Link>
                                    <Link to='/signup'>
                                        <div className='signIn'>
                                            <h5>Sign Up</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className='fasciaAboutPage'>
                    <img src={require('../../styles/images/aboutPage.png')}/>
                    <div className='description'>
                        <div className='question'>
                            We are deeply <span>passionate about data.</span>
                        </div>
                        <div className='testo'>
                            It stems from the Cerved Group's desire to meet the need for a constantly growing non performing loans market in order to ease and develop the match between supply and demand.
                        </div>
                    </div>
                </div>
                <div className='fasciaSearch'>
                    <div className='img'>
                        <img src={require('../../styles/images/experience.png')}/>
                    </div>
                    <div className='description'>
                        <div className='question'>
                            <b>A decade of experience</b> on the NPL market and in credit management.
                        </div>
                        <div className='testo'>
                            Thanks to our advanced internal expertise and our software, we successfully analyze enormous quantities of data.
                        </div>
                        <div className='testo'>
                            <b>Data drives what we do</b>. It guides our work and pushes us towards new territory. Our data enables us to provide superior services, from credit risk analysis to marketing solutions to managing non-performing loans and bad debt.
                        </div>
                        <div className='btn'>
                            <a target='_blank' href="http://www.cerved.com">Find out more on Cerved.com</a>
                        </div>
                    </div>
                </div>
                <div className='fasciaReady'>
                    <div className='description'>
                        <span className='title'>Are you ready to start?</span>
                        <span className='subtitle'>Request your login credential, enter your personal area and start browsing over 12 portfolios. </span>
                        <Link to='/signup'><span className='getStart'>Get started!</span></Link>
                    </div>
                </div>
                <div className='fasciaHelp'>
                    <div className='description'>
                        <div className='question'>
                            Do you need help?
                        </div>
                        {window.innerWidth>500?
                            <div className='testo' onClick={this.writeMail}>
                                <span>Write Us an email</span>
                            </div>
                            :
                            <div className='testo' onClick={this.writeMail}>
                                <span>Write Us an email</span>
                            </div>
                        }
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default About
