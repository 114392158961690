import React from "react";
import { ReactComponent as Sliders } from '../../styles/svg/sliders.svg';
import '../../styles/components/emptypage.css';


class EmptyPage extends React.Component {
  render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
      return (
          <div className='empty'>
              <div className="emptyContent">
                  <div className='circleEmpty'>
                    <Sliders fill="#7d299f" width="24px"/>
                  </div>
                  <p>Nessun risultato corrisponde alla tua ricerca</p>
                  <p className='prova'>Prova a rimuovere l'ultimo filtro che hai applicato</p>
              </div>
          </div>

      );
  }
}

export default EmptyPage;