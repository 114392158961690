import React, {Component} from 'react';
import '../../styles/search/strats.css'
import Chart from 'react-apexcharts'
import * as actions from "../../actions";
import {connect} from "react-redux";
class Strats extends Component{

    constructor(props) {
        super(props);
    }
    componentWillMount() {
        const id = this.props.match;
        const filtro = this.props.handleChangeTab();
        this.props.dispatch(actions.fetchPosizioniTipoDiCredito(id,filtro));
        this.props.dispatch(actions.fetchPosizioniControparte(id,filtro));
        this.props.dispatch(actions.fetchPosizioniDestUso(id,filtro));
        this.props.dispatch(actions.fetchPosizioniAreaGeografica(id,filtro));
        this.props.dispatch(actions.fetchPositionsByPortafoglioId(id,filtro, 0, 10))

    }
    savestats(stat) {
        let labels = [];
        let series = [];
        if(stat === undefined) {
        }else{ stat.forEach(function (e) {
            if (e[0] === null) {
                labels.push('Unknown');
                series.push(e[1]);
            } else {
                labels.push(e[0]);
                series.push(e[1]);
            }
        })};
        return [labels, series];
    }
    render() {
        const [labels, series] = this.savestats(this.props.strats);
        const [labelsC, seriesC] = this.savestats(this.props.stratCorp);
        const [labelsD, seriesD] = this.savestats(this.props.stratDest);
        const [labelsA, seriesA] = this.savestats(this.props.stratArea);

        var options = {
            plotOptions: {
                pie: {
                    size: window.innerWidth>500?90:undefined,
                    customScale: 1,
                    expandOnClick: false,
                    stroke: {
                        width: 0,
                    },
                    donut: {
                        size: '85%',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '18px',
                                fontFamily: 'Barlow',
                                color: '#dfsda',
                                offsetY: -10
                            },
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Barlow',
                                color: undefined,
                                offsetY: 0,
                                formatter: function (val) {
                                    return (parseFloat(val)/1000000).toFixed(1)+ ' Mln €';
                                }
                            },
                            total: {
                                show: true,
                                label: "GBV",
                                color: '#373d3f',
                                formatter: function (w) {
                                    let total = w.globals.seriesTotals.reduce((a, b) => {
                                        return  (a + b)
                                    }, 0);
                                    return parseFloat((total)/1000000).toFixed(1)+ ' Mln €';
                                }
                            }
                        }
                    }
                },

            },

            states: {
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
            },
            stroke: {
                width: 2,
            },
            theme: {
                mode: 'light',
                monochrome: {
                    enabled: true,
                    color: '#008996',
                    shadeTo: 'light',
                    shadeIntensity: 1
                },
            },

            dataLabels: {
                enabled: false,
                offset: 0,
                minAngleToShowLabel: 10,
                style:{
                    fontSize:'13px',
                    colors:['#000000', '#000000', '#000000', '#000000', '#000000'],
                }
            },
            labels: labels,
            tooltip:{
                enabled:false,
            },
            chart: {
                fontFamily:'Barlow',
            },
            series: series,
            legend: {
                fontSize: '12px',
                width: window.innerWidth>500?160:150,
                offsetY: 0,
                position: window.innerWidth>500?'right':'bottom',
                verticalAlign: 'top',
                horizontalAlign: window.innerWidth>500?undefined:'left',
                offsetX: 0,
                height: window.innerWidth>500?200:undefined,
                formatter: function(value, { seriesIndex, dataPointIndex, w }) {
                    return parseFloat(w.globals.seriesPercent[seriesIndex]).toFixed(1) + ' % ' +  value;
                },
                show: true,
                onItemHover: {
                    highlightDataSeries: false
                },
                onItemClick: {
                    toggleDataSeries: false
                },
            }
        };
        var optionz = {
            plotOptions: {

                pie: {
                    size: window.innerWidth>500?90:undefined,
                    customScale: 1,
                    expandOnClick: false,

                    stroke: {
                        width: 0,
                    },
                    donut: {
                        size: '85%',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '18px',
                                fontFamily: 'Barlow',
                                color: '#dfsda',
                                offsetY: -10
                            },
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Barlow',
                                color: undefined,
                                offsetY: 0,
                                formatter: function (val) {
                                    return (parseFloat(val)/1000000).toFixed(1)+ ' Mln €';
                                }
                            },
                            total: {
                                show: true,
                                label: "GBV",
                                color: '#373d3f',
                                formatter: function (w) {
                                    let total = w.globals.seriesTotals.reduce((a, b) => {
                                        return  (a + b)
                                    }, 0);
                                    return parseFloat((total)/1000000).toFixed(1)+ ' Mln €';
                                }
                            }
                        }
                    }
                },

            },

            states: {
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
            },
            stroke: {
                width: 2,
            },
            theme: {
                mode: 'light',
                monochrome: {
                    enabled: true,
                    color: '#008996',
                    shadeTo: 'light',
                    shadeIntensity: 1
                },
            },

            dataLabels: {
                enabled: false,
                offset: 0,
                minAngleToShowLabel: 10,
                style:{
                    fontSize:'13px',
                    colors:['#000000', '#000000', '#000000', '#000000', '#000000'],
                }
            },
            labels: labelsC,
            tooltip:{
                enabled:false,
            },
            chart: {
                fontFamily:'Barlow'
            },
            series: seriesC,
            legend: {
                fontSize: '12px',
                width: window.innerWidth>500?160:150,
                offsetY: 0,
                position: window.innerWidth>500?'right':'bottom',
                verticalAlign: 'top',
                horizontalAlign: window.innerWidth>500?undefined:'left',
                offsetX: 0,
                height: window.innerWidth>500?200:undefined,
                formatter: function(value, { seriesIndex, dataPointIndex, w }) {
                    return parseFloat(w.globals.seriesPercent[seriesIndex]).toFixed(1) + ' % ' +  value;
                },
                show: true,
                onItemHover: {
                    highlightDataSeries: false
                },
                onItemClick: {
                    toggleDataSeries: false
                },
            }
        };
        var options1 = {
            plotOptions: {
                pie: {
                    size: window.innerWidth>500?90:undefined,
                    customScale: 1,
                    expandOnClick: false,
                    stroke: {
                        width: 0,
                    },
                    donut: {
                        size: '85%',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '18px',
                                fontFamily: 'Barlow',
                                color: '#dfsda',
                                offsetY: -10
                            },
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Barlow',
                                color: undefined,
                                offsetY: -8,
                                formatter: function (val) {
                                    return (val);
                                }
                            },
                            total: {
                                show: true,
                                label: "Asset",
                                color: '#373d3f',
                                formatter: function (w) {
                                    let total = w.globals.seriesTotals.reduce((a, b) => {
                                        return  (a + b)
                                    }, 0);
                                    return (total);
                                }
                            }
                        }
                    }
                },

            },

            states: {
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
            },
            stroke: {
                width: 2,
            },
            theme: {
                mode: 'light',
                monochrome: {
                    enabled: true,
                    color: '#008996',
                    shadeTo: 'light',
                    shadeIntensity: 1
                },
            },

            dataLabels: {
                enabled: false,
                offset: 0,
                minAngleToShowLabel: 10,
                style:{
                    fontSize:'13px',
                    colors:['#000000', '#000000', '#000000', '#000000', '#000000'],
                }
            },
            labels: labelsD,
            tooltip:{
                enabled:false,
            },
            chart: {
                fontFamily:'Barlow'
            },
            series: seriesD,
            legend: {
                fontSize: '12px',
                width: window.innerWidth>500?160:250,
                offsetY: 0,
                position: window.innerWidth>500?'right':'bottom',
                verticalAlign: 'top',
                horizontalAlign: window.innerWidth>500?undefined:'left',
                offsetX: 0,
                height: window.innerWidth>500?200:undefined,
                formatter: function(value, { seriesIndex, dataPointIndex, w }) {
                    return parseFloat(w.globals.seriesPercent[seriesIndex]).toFixed(1) + ' % ' +  value;
                },
                show: true,
                onItemHover: {
                    highlightDataSeries: false
                },
                onItemClick: {
                    toggleDataSeries: false
                },
            }
        };
        var opt = {
            plotOptions: {

                pie: {
                    size: window.innerWidth>500?90:undefined,
                    customScale: 1,
                    expandOnClick: false,
                    stroke: {
                        width: 0,
                    },
                    donut: {
                        size: '85%',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '15px',
                                fontFamily: 'Barlow',
                                color: '#dfsda',
                                offsetY: -10
                            },
                            value: {
                                show: true,
                                fontSize: '20px',
                                fontFamily: 'Barlow',
                                color: undefined,
                                offsetY: -8,
                                formatter: function (val) {
                                    return (val);
                                }
                            },
                            total: {
                                show: true,
                                label: "Asset",
                                color: '#373d3f',
                                formatter: function (w) {
                                    let total = w.globals.seriesTotals.reduce((a, b) => {
                                        return  (a + b)
                                    }, 0);
                                    return (total);
                                }
                            }
                        }
                    }
                },

            },

            states: {
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
            },
            stroke: {
                width: 2,
            },
            theme: {
                mode: 'light',
                monochrome: {
                    enabled: true,
                    color: '#008996',
                    shadeTo: 'light',
                    shadeIntensity: 1
                },
            },

            dataLabels: {
                enabled: false,
                offset: 0,
                minAngleToShowLabel: 10,
                style:{
                    fontSize:'13px',
                    colors:['#000000', '#000000', '#000000', '#000000', '#000000'],
                }
            },
            labels: labelsA,
            tooltip:{
                enabled:false,
            },
            chart: {
                fontFamily:'Barlow'
            },

            series: seriesA,
            legend: {
                fontSize: '12px',
                width: window.innerWidth>500?160:250,
                offsetY: 0,
                position: window.innerWidth>500?'right':'bottom',
                verticalAlign: 'top',
                horizontalAlign: window.innerWidth>500?undefined:'left',
                offsetX: 0,
                height: window.innerWidth>500?200:undefined,
                formatter: function(value, { seriesIndex, dataPointIndex, w }) {
                    return parseFloat(w.globals.seriesPercent[seriesIndex]).toFixed(1) + ' % ' +  value;
                },
                show: true,
                onItemHover: {
                    highlightDataSeries: false
                },
                onItemClick: {
                    toggleDataSeries: false
                },
            }
        };

        return (
            <div className="containerStrats">

                {/*<div id="Tabs"  role="tabpanel">*/}
                {/*    <ul id="tab" className="nav nav-tabs" role="tablist">*/}
                {/*        <li className="nav-item p-3" ><a className="active" href="#personal"  aria-controls="messagecontainer" aria-selected="true" role="tab" data-toggle="tab"> GBV</a></li>*/}
                {/*        <li className="nav-item p-3"><a href="#employment" aria-controls="employment" role="tab" data-toggle="tab">Nr. Borrowers </a>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*    <div className="tab-content">*/}
                {/*        <div role="tabpanel" className="tab-pane active" id="personal">*/}
                {/*            <div id="aperte" className="d-flex justify-content-between">*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div role="tabpanel" className="tab-pane" id="employment">*/}
                {/*            <div id="aperte" className="d-flex justify-content-between">*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*</div>*/}

                <div className="contentStrats">
                    <div className="tipodiCredito">
                        <div id="tipodicredito"> Debt category</div>
                        <div className="borderStrats"></div>
                        {series.length !==0 ? <Chart options={options} series={options.series} id="chart" type="donut" width="100%" height="100%"/> : <div className="emptyStrats">No Data</div>}
                    </div>
                    <div id="divider"></div>
                    <div className="tipodiCredito">
                        <div id="tipodicredito"> Borrowers</div>
                        <div className="borderStrats"></div>

                        {seriesC.length !==0 ? <Chart options={optionz} series={optionz.series} id="chart" type="donut" width="100%" height="100%"/> : <div className="emptyStrats">No Data</div>}
                    </div>
                </div>
                <div className="contentStrats">
                    <div className="tipodiCredito">
                        <div id="tipodicredito"> Asset type</div>
                        <div className="borderStrats"></div>

                        {seriesD.length !==0 ? <Chart options={options1} series={options1.series} id="chart" type="donut" width="100%" height="100%"/>: <div className="emptyStrats">No Data</div>}
                    </div>
                    <div id="divider"></div>
                    <div className="tipodiCredito">
                        <div id="tipodicredito"> Asset location</div>
                        <div className="borderStrats"></div>

                        {seriesA.length !==0 ? <Chart options={opt} series={opt.series} id="chart" type="donut" width="100%" height="100%"/>: <div className="emptyStrats">No Data</div>}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state){

    return{
        strats: state.strats.data,
        stratCorp: state.strats.datacorp,
        stratDest: state.strats.datadest,
        stratArea: state.strats.dataarea,
    }
}
export default connect(mapStateToProps) (Strats)
