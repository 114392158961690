import React, {Component} from 'react';
import '../../styles/components/messaggio.css'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {ReactComponent as Attach} from "../../styles/svg/paperclip.svg";
import {ReactComponent as FaAngleRight} from "../../styles/svg/chevron-right.svg";
import {ReactComponent as Trash} from '../../styles/svg/trash-alt.svg';
import axios from "axios";
import {confirmAlert} from "react-confirm-alert";
import * as actions from "../../actions";


class Messaggi extends Component {

    notarizazzione(id) {
        axios.post(`/api-mkt/messages/notarizeConversation/${id}`).then(() => this.props.dispatch(actions.fetchConversazioni()));
    }

    setMessageRead(id) {
        this.props.dispatch(actions.setMessageRead(id));
    }

    hide = () => this.setState({ open: false, callback: null });

    componentWillMount(){
        document.getElementsByTagName('body').item(0).classList.add('smallAlert')
    }

    componentDidMount(){
        if(window.innerWidth > 500){
            if(window.innerHeight > document.getElementById('root').clientHeight ){
                document.getElementById('question').classList.remove('fixed')
            }else{
                document.getElementById('question').classList.add('fixed')
            }
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('body').item(0).classList.remove('smallAlert')
    }

    submit = () => {
        confirmAlert({
            title: <div>
                Are you sure you want to notarize and close this conversation?</div>,
            message: '',
            buttons: [
                {
                    label: 'Cancel',
                    onClick: () => this.hide()
                },
                {
                    label: 'Yes, close',
                    onClick: () => this.notarizazzione(this.props.data.id)
                }
            ]
        });
    };

    render() {
        if(window.innerWidth > 500) {
            return (
                <div className={(this.props.data.messaggios[0].stato === 'NUOVO') ? ' new' : ''}>
                    <div className="flex-row containerMessages">
                        <div id="circle"
                             className={`d-flex float-left ${this.props.data.messaggios[0].gestore ? 'gestore' : ''}`}>
                            {this.props.data.initials}
                        </div>
                        <div id="content" className="d-flex justify-content-between">
                            <div className='cntUtente'><span id="utente">{this.props.data.nomeUtente}</span></div>
                            <div className='cntData'><span id="dataMessaggio">{this.props.data.dataCreazione}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="pTesto"><span
                                id="nomeInvestitore">{this.props.data.utenteLastMessage}:</span>
                                <span id="testoMessaggio" className={'wordWrap'}> {this.props.data.messaggios[0].testo}</span>
                                <div className="attachments">
                                    <Link to={`/portfolio-detail/${this.props.data.portafoglioId}/positions`}
                                          style={{textDecoration: 'none', color: 'black'}}><span
                                        className="portafoglioMessage">Portfolio: {this.props.data.portafoglioName}</span></Link>
                                    {this.props.data.posizioneId != null ?
                                        <Link to={`/position/${this.props.data.posizioneId}`}
                                              style={{textDecoration: 'none', color: 'black'}}><span
                                            className="posizioneMessage"> Position: {this.props.data.posizioneId}</span></Link> : ''
                                    }
                                    {this.props.data.messaggios[0].allegati.length > 0 ?
                                        <span className="attachmentsMessage">
                                    <Attach fill="#455a64" height="21px"
                                            width="14px"/> {this.props.data.messaggios[0].allegati.length} attachment/s</span> : ''}

                                </div>
                            </div>
                            <Link className='contentArrow' to={`/messages/details/${this.props.data.id}`}
                                  onClick={this.setMessageRead.bind(this, this.props.data.messaggios[0].id)}
                                  style={{textDecoration: 'none', color: 'inherit'}}>
                                <div id="arrow" className="d-flex float-right"><FaAngleRight width='8px'
                                                                                             fill='#008996'/></div>
                            </Link>
                        </div>
                        {this.props.gestore && this.props.data.stato === 'APERTA' ?
                            <div className='contentBidone'>
                                <div id='bidone'>
                                    <Link to='#' onClick={this.submit} style={{textDecoration: 'none', color: 'inherit'}}>Close and notarize</Link>
                                </div>
                            </div> : ''
                        }
                    </div>
                </div>
            );
        }else{
            return (
                <Link className='contentArrow' to={`/messages/details/${this.props.data.id}`}
                      onClick={this.setMessageRead.bind(this, this.props.data.messaggios[0].id)}
                      style={{textDecoration: 'none', color: 'inherit'}}>
                    <div id='msg' className={(this.props.data.messaggios[0].stato === 'NUOVO') ? ' new' : ''}>
                        <div className="flex-row containerMessages">
                            <div id="circle"
                                 className={`d-flex float-left ${this.props.data.messaggios[0].gestore ? 'gestore' : ''}`}>
                                {this.props.data.initials}
                            </div>
                            <div id="content" className="d-flex justify-content-between">
                                <div className='cntUtente'><span id="utente">{this.props.data.nomeUtente}</span></div>
                                <div className='cntData'><span id="dataMessaggio">{this.props.data.dataCreazione}</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className={`pTesto ${this.props.data.messaggios[0].allegati.length > 0 ?'circleAttach':''}`}><span
                                    id="nomeInvestitore">{this.props.data.utenteLastMessage}:</span>
                                    <span id="testoMessaggio" className={'wordWrap'}> {this.props.data.messaggios[0].testo}</span>
                                    <div className="attachments">
                                        <Link to={`/portfolio-detail/${this.props.data.portafoglioId}/positions`}
                                              style={{textDecoration: 'none', color: 'black'}}><span
                                            className="portafoglioMessage">Portfolio: {this.props.data.portafoglioName}</span></Link>
                                        {this.props.data.posizioneId != null ?
                                            <Link to={`/position/${this.props.data.posizioneId}`}
                                                  style={{textDecoration: 'none', color: 'black'}}><span
                                                className="posizioneMessage">, Position: {this.props.data.posizioneId}</span></Link> :''
                                        }
                                        {this.props.data.messaggios[0].allegati.length > 0 ?
                                            <span className="attachmentsMessage">{this.props.data.messaggios[0].allegati.length}</span> : ''}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.gestore && this.props.data.stato === 'APERTA' ?
                            <div className='contentBidone'>
                                <div id='bidone'><Link to='#'
                                                       onClick={this.submit}
                                                       style={{textDecoration: 'none', color: 'inherit'}}>
                                    <Trash fill="#ff3f5d" width="14px"/>
                                </Link></div>
                            </div> : ''
                        }
                    </div>
                </Link>
            );
        }
    }
}

export default connect()(Messaggi)

