/* global google */
import React from "react";
import {
    withGoogleMap,
    GoogleMap,
    Marker,  InfoWindow,
    withScriptjs
} from "react-google-maps";
import {Link} from "react-router-dom";
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { ReactComponent as IHome } from '../../styles/svg/home.svg';
import { ReactComponent as IUser } from '../../styles/svg/user.svg';
import { ReactComponent as IFreccia } from '../../styles/svg/chevron-left.svg';

import '../../styles/components/map.css';

export class Markers extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            isOpen: false
        }

    }

    shouldComponentUpdate(propsSuccessive, stateSuccessivo){
        if(!propsSuccessive.check){
            stateSuccessivo.isOpen = false;
        }
        return true;
    }

    handleToggleOpen = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    handleToggleClose = () => {
        this.setState({
            isOpen: false
        });
    }

    render() {
        let iconAsset = new window.google.maps.MarkerImage(
            require('../../styles/images/asset.png'),null,null,null,new window.google.maps.Size(35, 40)
        );
        let iconBorrower = new window.google.maps.MarkerImage(
            require('../../styles/images/borrower.png'),null,null,null,new window.google.maps.Size(35, 40)
        );
        let iconAssetNoMatch = new window.google.maps.MarkerImage(
            require('../../styles/images/no-match-assets.png'),null,null,null,new window.google.maps.Size(30, 37)
        );
        let iconBorrowerNoMatch = new window.google.maps.MarkerImage(
            require('../../styles/images/no-match-borrower.png'),null,null,null,new window.google.maps.Size(30, 37)
        );
        return (
            <Marker
                key={this.props.place.id}
                visible={this.props.check}
                position={{ lat: parseFloat(this.props.place.lat),
                    lng: parseFloat(this.props.place.lon)}}
                onClick={() => this.handleToggleOpen()}
                icon={this.props.type === 'assets'?(this.props.place.filterNotMatched? iconAssetNoMatch:iconAsset):this.props.place.filterNotMatched?iconBorrowerNoMatch:iconBorrower
                    //
                    // {url:'data:image/svg+xml;utf-8, \<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 384 512" style="color: rgb(0, 188, 212);" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"></path></svg>'}
                    // :{url:'data:image/svg+xml;utf-8, <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 384 512" style="color: rgb(16,212,12);" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"></path></svg>'}

                }

            >
                {
                    this.props.check && this.state.isOpen &&
                    <InfoWindow options={{pixelOffset: {width: 0, height:window.innerWidth<500?400:0}}} onCloseClick={this.handleToggleClose}>
                        {this.props.type === 'assets'?
                            <div className='infoMarker'>
                                <div className='header'>
                                    <IHome/>
                                    <span className='tipo'>{this.props.place.macroTipoDestUso}</span>
                                </div>
                                <div className='corpo'>
                                    <span className='desc'>{this.props.place.descrizione}</span>
                                    <span className='stato'>{this.props.place.statoOccupazione}({this.props.place.locazione===false?'Non in locazione':'In locazione'})</span>
                                    <span className='indirizzo'>{this.props.place.indirizzo} {this.props.place.comune}</span>
                                    <span className='cap'>({this.props.place.provincia})- {this.props.place.cap}</span>
                                </div>
                                <Link to={`/position/${this.props.place.idPosition}`}><div className='posAppartenenza'><span>{this.props.place.idPosition}</span></div></Link>
                            </div>
                            :
                            <div className='infoMarker'>
                                <div className='header'>
                                    <IUser/>
                                    <span className='tipo'>{this.props.place.tipoSoggetto}</span>
                                </div>
                                <div className='corpo'>
                                    <span className='indirizzo'>{this.props.place.comunePrevalente}</span>
                                    <span className='cap'>({this.props.place.provinciaPrevalente}) - {this.props.place.regionePrevalente}</span>
                                </div>
                                <Link to={`/position/${this.props.place.idPosition}`}><div className='posAppartenenza'><span>{this.props.place.idPosition}</span></div></Link>
                            </div>
                        }


                    </InfoWindow>
                }
            </Marker>
        );
    }
};


export class MyCluster extends React.Component {
    constructor(props) {
        super(props)
    }

    initMarker(places, check, type) {
        let objDuplicate = {};
        const raggio = 0.00006589957251587350625;
        const grado = 22;
        return places.map( (place, i) => {
            const key = place.lat+"_"+place.lon;
            if(!objDuplicate[key]){
                objDuplicate[key] = 1;
            }else{
                objDuplicate[key]=objDuplicate[key] + 1;
                let raggioExt = raggio;
                if(objDuplicate[key] > 15){
                    raggioExt = raggio * 2;
                }
                const rad = objDuplicate[key] * grado * Math.PI / 180;
                place.lat=Number(place.lat)+(raggioExt*Math.cos(rad));
                place.lon=Number(place.lon)+(raggioExt*Math.sin(rad));
            }
            return <Markers place={place} check={check} type={type}/>
        });
    }

    render() {
        let image = new window.google.maps.MarkerImage(
            require('../../styles/images/cluster.png'),null,null,null,new window.google.maps.Size(32, 32)
        );

        let clusterStyles = [
            {
                textColor: '#FFF',
                url: image.url,
                height: 50,
                width: 50
            }
        ]

        function onMarkerClustererClick(markerClusterer){
            const clickedMarkers = markerClusterer.getMarkers()
            let array = [];
            let objDuplicate = {};
            let constLatLng = 0.000025
            clickedMarkers.forEach(function (item, index){
                if(!objDuplicate[item.position.lat()+item.position.lng()]){
                    objDuplicate[item.position.lat()+item.position.lng()] = [index]
                    array.push(item)
                }else{
                    objDuplicate[item.position.lat()+item.position.lng()].push(index)
                    item.setPosition({'lat': item.position.lat()+constLatLng, 'lng': item.position.lng()})
                    array.push(item)
                }
                constLatLng+=0.000025;
            });

        }

        return (
            <MarkerClusterer
                ref='cluster'
                defaultIgnoreHidden={true}
                styles={clusterStyles}
                // onClick={onMarkerClustererClick}

            >
                    {this.initMarker(this.props.state.places.assets, this.props.state.checkAsset, 'assets')}
                    {this.initMarker(this.props.state.places.borrowers, this.props.state.checkDeb, 'debitori')}
            </MarkerClusterer>
        )

    };
}



class MapDetail extends React.Component {
    constructor(props) {
        super(props);

        const assets = () => {
            const places = [];

            if (this.props.markers.assetList) {
                this.props.markers.assetList.map((place, i) => {
                    const asset = {
                        id: place.id + "A",
                        latOrig: place.latitudine,
                        lat: place.latitudine,
                        lon: place.longitudine,
                        lonOrig: place.longitudine,
                        macroTipoDestUso: place.macroTipoDestUso,
                        descrizione: place.descrizione,
                        indirizzo: place.indirizzo,
                        comune: place.comune,
                        provincia: place.provincia,
                        cap: place.cap,
                        statoOccupazione: place.statoOccupazione,
                        locazione: place.locazione,
                        idPosition: place.posizioneId,
                        filterNotMatched: place.filterNotMatched
                    }
                    places.push(asset);
                })
            }
            return places;
        }

        const borrowers = () => {
            const places = [];
            if (this.props.markers.borrowerList) {
                this.props.markers.borrowerList.map(place => {
                    const borrower = {
                        id: place.posizione.id + "D",
                        latOrig: place.latitudine,
                        lat: place.borrowerLat,
                        lon: place.borrowerLon,
                        lonOrig: place.longitudine,
                        tipoSoggetto: place.posizione.tipoSoggetto,
                        comunePrevalente: place.posizione.comunePrevalente,
                        provinciaPrevalente: place.posizione.provinciaPrevalente,
                        regionePrevalente: place.posizione.regionePrevalente,
                        idPosition: place.posizione.id,
                        filterNotMatched: place.filterNotMatched
                    }
                    places.push(borrower)
                })
            }
            return places;
        }
        this.state = {
            places: {
                assets: assets(),
                borrowers: borrowers()
            },
            checkAsset: true,
            checkDeb: true
        }; //initialize initial state from props
    }

    toogleVisibility = (e) => {
        let bAsset = this.state.checkAsset;
        let bDeb = this.state.checkDeb
        if(e.target.value == 'Assets'){
            bAsset = !this.state.checkAsset;
            this.setState({checkAsset: bAsset});
        }else{
            bDeb = !this.state.checkDeb;
            this.setState({checkDeb: bDeb});
        }
    }

    componentDidMount() {
        const bounds = new window.google.maps.LatLngBounds();
        this.props.markers.assetList.map((marker, i) => {
            bounds.extend(new window.google.maps.LatLng(
                parseFloat(marker.latitudine),
                parseFloat(marker.longitudine)
            ));
        });

        this.props.markers.borrowerList.map((marker, i) => {
            bounds.extend(new window.google.maps.LatLng(
                parseFloat(marker.borrowerLat),
                parseFloat(marker.borrowerLon)
            ));
        });
        this.refs.map.fitBounds(bounds);
    }

    showFilterMapMobile(){
        document.getElementsByClassName('boxFilter').item(0).classList.toggle('show');
    }


    render() {

        const exampleMapStyles = [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "poi",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "transit",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            }
        ]


        const options = {
            fullscreenControl: false,
            fullscreenControlOptions: {
                position: 10
            },
            zoomControl: window.innerWidth<500?false:true,
            streetViewControl: false,
            mapTypeControl: false,
            styles: exampleMapStyles
        };






        return (
            <GoogleMap

                ref='map'
                zoom={5}
                options={options}>
                <div className='boxFilter'>
                    {window.innerWidth < 500?
                        <div id='btnMobile' onClick={this.showFilterMapMobile}>
                            <div className='content'>
                                <IFreccia fill='#FFF' width='8px'/>
                            </div>
                        </div>
                        :
                        ''
                    }
                    <h5 className='font-weight-bold'>View:</h5>
                    <div className='d-flex asset'>
                        <input checked='' type='checkbox' checked={this.state.checkAsset} value='Assets' onChange={this.toogleVisibility}/>
                        <h6 className='flex-grow-1'>Assets</h6>
                        <img src={require('../../styles/images/asset.png')} />
                    </div>
                    <div className='d-flex debitori'>
                        <input checked='' type='checkbox' checked={this.state.checkDeb} value='Borrowers' onChange={this.toogleVisibility}/>
                        <h6 className='flex-grow-1'>Borrowers</h6>
                        <img src={require('../../styles/images/borrower.png')} />
                    </div>
                </div>
                <MyCluster
                    ref='cluster'
                    state = {this.state}
                />
            </GoogleMap>
        );
    }
}

// StyledMap.defaultProps = {
//     // The style is copy from https://snazzymaps.com/style/2/midnight-commander
//
// }

export const MapWithFitBounds = withScriptjs(withGoogleMap(MapDetail));
