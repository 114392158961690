import React, {Component} from 'react';
import {MapWithFitBounds} from "../../shared/maps/MapDetail";
import * as actions from "../../actions";
import {connect} from "react-redux";
import Spinner from '../../shared/components/Spinner';
import {ReactComponent as ICompress} from "../../styles/svg/compress.svg";
import {ReactComponent as IExpand} from "../../styles/svg/expand.svg";
import {ReactComponent as ISlider} from "../../styles/svg/sliders-h.svg";
import {ResultLoader} from "../../shared/components/ResultLoader";
import EmptyPage from "../../shared/components/EmptyPage";

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: true
        }
    }

    stateInit = false;

    componentWillMount() {
        const filtro = this.props.handleChangeTab();
        this.props.dispatch(actions.fetchMapFiltratiByPortId(this.props.idPort, filtro));
    }

    fullscreen = () => {
        document.getElementsByClassName('map').item(0).classList.toggle('fullscreen');
        this.setState(prevState => ({
            fullscreen: !prevState.fullscreen
        }));
    }

    showMapFilter() {
        document.getElementById('filterContainer').classList.toggle('showFilter');
        document.getElementById('over').classList.toggle('show');
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        this.props.countPostionsFilter(this.props.assets.positionCount)
    }

    render() {
        {
            if (this.props.assets && (this.props.assets.assetList && this.props.assets.assetList.length > 0) || (this.props.assets.borrowerList && this.props.assets.borrowerList.length > 0)) {
                return (
                    <div className='row'>
                        <div className='col-12 nopadding'>
                            <div
                                className={`map ${window.innerWidth < 500 ? this.state.fullscreen ? 'fullscreen' : '' : ''}`}>
                                {window.innerWidth < 500 ?
                                    <div>
                                        <div id='compressMap' onClick={this.fullscreen}>
                                            <ICompress/>
                                        </div>
                                        <div id='expandMap' onClick={this.fullscreen}>
                                            <IExpand/>
                                        </div>
                                        <div id="buttonMapFiltra" onClick={this.showMapFilter}>
                                            <ISlider/><h4></h4>
                                        </div>
                                    </div> : ''
                                }
                                <MapWithFitBounds
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCKPVmJuHKuTsnWnllTn-aFb6OZY74F_Lk&libraries=geometry,drawing,places"
                                    loadingElement={<div
                                        style={window.innerWidth > 500 ? {height: `100%`} : {height: `100vh`}}/>}
                                    containerElement={<div
                                        style={window.innerWidth > 500 ? {height: `600px`} : {height: `100vh`}}/>}
                                    mapElement={<div
                                        style={window.innerWidth > 500 ? {height: `100%`} : {height: `100vh`}}/>}
                                    markers={this.props.assets}
                                />
                            </div>
                        </div>
                    </div>
                );
            } else {
                if(this.stateInit){
                    return (
                        <EmptyPage/>
                    )
                }else{
                    this.stateInit = true;
                    return(
                        <ResultLoader />
                    )
                }
            }
        }

    }
}

function mapStateToPropsSearch(state) {
    return {
        assets: state.assets.dataMapDet
    }
}


export default connect(mapStateToPropsSearch)(Map)
