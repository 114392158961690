import React, {Component} from 'react';
import {ReactComponent as FaAngleRight} from "../../styles/svg/chevron-right.svg";
import {ReactComponent as Trash} from "../../styles/svg/trash-alt.svg";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class Assistenza extends Component{
    componentWillMount(){
        document.getElementsByTagName('body').item(0).classList.add('smallAlert')
    }

    componentDidMount(){
        if(window.innerWidth > 500){
            if(window.innerHeight > document.getElementById('root').clientHeight ){
                document.getElementById('question').classList.remove('fixed')
            }else{
                document.getElementById('question').classList.add('fixed')
            }
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('body').item(0).classList.remove('smallAlert')
    }

    render() {
        if(window.innerWidth > 500) {
            return (
                <div className=''>
                    <div className="flex-row containerMessages">
                        <div id="circle"
                             className='d-flex float-left'>
                            {this.props.data.initials}
                        </div>
                        <div id="content" className="d-flex justify-content-between">
                            <div className='cntUtente'><span id="utente">{this.props.data.nomeUtente}</span></div>
                            <div className='cntData'><span id="dataMessaggio">{this.props.data.dataCreazione}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="pTesto"><span
                                id="nomeInvestitore">{this.props.data.utenteLastMessage}:</span>
                                <span id="testoMessaggio"> {this.props.data.messaggio}</span>
                                <div className="attachments">
                                    <div className='topic'><span
                                        className="portafoglioMessage">Topic: {this.props.data.topic}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }else{
            return (
                <Link className='contentArrow' to={`/messages/details/${this.props.data.id}`}
                      style={{textDecoration: 'none', color: 'inherit'}}>
                    <div id='msg' className=''>
                        <div className="flex-row containerMessages">
                            <div id="circle"
                                 className='d-flex float-left'>
                                {this.props.data.initials}
                            </div>
                            <div id="content" className="d-flex justify-content-between">
                                <div className='cntUtente'><span id="utente">{this.props.data.nomeUtente}</span></div>
                                <div className='cntData'><span id="dataMessaggio">{this.props.data.dataCreazione}</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className='pTesto'>
                                    <span id="nomeInvestitore">{this.props.data.utenteLastMessage}:</span>
                                    <span id="testoMessaggio"> {this.props.data.messagio}</span>
                                    <div className="attachments">
                                        <div className='topic'><span
                                            className="portafoglioMessage">Topic: {this.props.data.topic}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            );
        }
    }
}

export default connect()(Assistenza)
