import React, {Component} from "react";
import { confirmAlert } from 'react-confirm-alert';
import '../../styles/components/carrello.css';
import { ReactComponent as Close } from '../../styles/svg/window-close.svg';
import TextareaAutosize from 'react-autosize-textarea';
import axios from "axios";

export class CarrelloHeader extends Component{

    constructor(){
        super();
        this.state = {
            email: '',
            formErrors: {email:''},
            emailValid: false,
            formValid: false,
            phone: '',
            investorName: '',
            message: ''
        }

    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        switch (fieldName) {
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : ' is invalid';
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors, emailValid: emailValid},this.validateForm);
    }

    validateForm() {
        this.setState({formValid: this.state.emailValid});
    }

    onChange = (e) => {
        const name= e.target.name;
        const value = e.target.value;
        this.setState({[name]: value}, () => {this.validateField(name, value)});
        this.setState({[name]: value}, () => {this.submit(name, value)});

    }

    handleSubmit= (e) => {

        e.preventDefault();
        const investorName = this.state.investorName;
        const message = this.state.message;
        const phone = this.state.phone;
        const email = this.state.email;
        var idDirectInvestor;

        if( investorName === ''){
            idDirectInvestor = false
        }else{
            idDirectInvestor = true;
        }

        const data = {
            investorName, message, phone, email  ,idDirectInvestor
        }
        axios.post('/api-mkt/notifications/sendIndicationOfInterest', data).then(this.confirm);


    };

    hide = () => this.setState({callback: null });


    confirm = () => {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            title:<div ><div id="image"></div> <br/> Thank you! Your request has been sent.</div> ,
            message: 'You will be contacted by the portfolios specialist.',
            buttons: [

                {
                    label: 'Close',
                    onClick: () => this.hide()
                }
            ]
        });
    };

    submit = () => {
        confirmAlert({
            closeOnEscape: true,
            closeOnClickOutside: false,
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <form onSubmit={this.handleSubmit.bind(this)}>

                            <p id="headerAlert" className="h4 mb-4">Indication of interest <span id="close" className="h4 mb-4"><Close id="xclose" onClick={onClose} width="14px"/></span></p>
                            <div className="p-4">

                            <label htmlFor="email" >Your email address *</label>
                            <input type="email" id="defaultContactFormName" name="email" onChange={this.onChange} required className="form-control mb-4" />

                            <label htmlFor="phone">Your phone number *</label>
                            <input type="phone" id="defaultContactFormName"  name="phone" onChange={this.onChange} required className="form-control mb-4" />

                            <input type="radio" id="noCheck" name="investitoreDiretto" onClick={yesnoCheck} value="investitoreDiretto"/> I'm the direct investor <br/>
                                <input type="radio" id="yesCheck" name="investitoreDiretto" onClick={yesnoCheck} value="investitoreDiretto"/> I'm working on behalf of another investor <br/>

                            <div id="investitore" >
                                <label>Type the name of the buyer *</label>
                            <input type="text" id="defaultContactFormName" name="investorName" onChange={this.onChange} required className="form-control mb-4" />
                            </div>

                            <div className="form-group">
                                <label>Your message</label>
                                <TextareaAutosize type="textarea" name="message" id="defaultContactFormName" className="form-control" onChange={this.onChange} rows="3" placeholder="Your message"></TextareaAutosize>
                            </div>
                            <div className="carrelloheader">
                                <button id="buttonann" className="btn btn-light" onClick={onClose}>Cancel</button>
                                <button id="buttoncont"  disabled={(!this.state.email || !this.state.phone ) && !this.state.investorName } className="btn btn-info btn-block col-5" onClick={this.handleSubmit.bind(this)} type="submit">Contact me</button>
                            </div>
                            </div>
                        </form>
                    </div>
                );
            }
        });
    };

    render() {
        return(
            <div className="headerCarrello">
                <div className='title'> My indications of interest ({this.props.count === undefined ? 0 : this.props.count})</div>
                <div onClick={this.submit} className="procedi">
                    <button disabled={!this.props.count} >Send</button>
                </div>
            </div>

    )
    }
}

function yesnoCheck() {
    if (document.getElementById('yesCheck').checked) {
        document.getElementById('investitore').style.display = 'block';
    }
    else document.getElementById('investitore').style.display = 'none';

}
