import React, {Component} from "react";
import * as actions from "../../actions";
import {connect} from "react-redux";
import '../../styles/components/dettaglioposizione.css'
import {confirmAlert} from "react-confirm-alert";
import {Get, Map, UpperCase} from 'react-lodash'
import {ReactComponent as Comment} from '../../styles/svg/comment-alt-lines.svg';
import {ReactComponent as ILeft} from '../../styles/svg/chevron-left.svg';
import TextareaAutosize from 'react-autosize-textarea';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor'
import axios from "axios";
import {ReactComponent as Close} from "../../styles/svg/window-close.svg";
import {ReactComponent as Alert} from "../../styles/svg/exclamation-circle.svg";
import {ReactComponent as Attach} from "../../styles/svg/paperclip.svg";

import {ResultLoader} from "../../shared/components/ResultLoader";


class DettaglioPosizione extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.state = {
            disabled: true,
            payload: '',
            allegati :true,

        }
    }

    goBack(){
        this.props.history.push(`/portfolio-detail/${this.props.position.Posizione.portafoglioId}`);
    }


    hide = () => this.setState({ open: false, callback: null });

    confirm = () => {
        confirmAlert({

            title: <div ><div id="image"></div> <br/>Thank you! Your request has been sent.</div>,
            message: 'Our portfolio specialist will take in charge your information request and recontact you as soon as possible. You will find all the information requests in the Messagges section',
            buttons: [

                {
                    label: 'Close',
                    onClick: () => this.hide()
                }
            ]
        });
    };


    error = () => {
        confirmAlert({

            title: <div ><div id="image"></div> <br/> You already have an open conversation </div>,
            message: 'Go to Messages and send a message to Markagain Admin!',
            buttons: [

                {
                    label: 'Close',
                    onClick: () => this.hide()
                }
            ]
        });
    };

    setAnchor(){
        const link = window.location.hash;
        Array.from(document.getElementsByClassName('anchorLink')).forEach((link) => {
            link.classList.remove('active');
        });
        if(link !== ''){
            document.getElementById(link.slice(1, link.length)).classList.add('active');
        } else {
            document.getElementById('debGuar').classList.add('active');
        }

    }

    componentWillMount() {
        configureAnchors({offset: -120});
        const {id} = this.props.match.params;
        this.props.dispatch(actions.fetchPosizioneById(id));
    }

    // setAnchor(){
    //     const position = this.scrollY + 120;
    //     let target,id;
    //     Array.from(document.getElementsByClassName('contentAnchor')).forEach((el) => {
    //         target = el.offsetTop;
    //         id = el.getAttribute('data-id')
    //         if (position >= target) {
    //             Array.from(document.getElementsByClassName('anchorLink')).forEach((link) => {
    //                 link.classList.remove('active');
    //             });
    //             document.getElementById(id).classList.add('active');
    //         }
    //     });
    // }

    setHighlight(event){
        Array.from(document.getElementsByClassName('anchorLink')).forEach((link) => {
            link.classList.remove('active');
        });
        event.target.classList.add('active');
    }

    componentDidMount() {
        if (window.innerWidth > 500) {
            window.onscroll = function() {
                if (document.documentElement.scrollTop === 0) {
                    Array.from(document.getElementsByClassName('anchorLink')).forEach((link) => {
                        link.classList.remove('active');
                    });
                    if (document.getElementById('debGuar'))
                        document.getElementById('debGuar').classList.add('active');
                } else if (document.documentElement.offsetHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 10) {
                    Array.from(document.getElementsByClassName('anchorLink')).forEach((link) => {
                        link.classList.remove('active');
                    });
                    if (document.getElementById('documents'))
                        document.getElementById('documents').classList.add('active');
                }
            }
        }
        window.addEventListener("hashchange", this.setAnchor, false);
        document.getElementById('marketPlace').classList.add('grigio');
        // window.addEventListener('scroll', this.setAnchor, true);
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.setAnchor, false);
        document.getElementById('marketPlace').classList.remove('grigio');
    }

    currencyFormatDE(num) {
        return (
            num
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    render() {

        if (this.props.position.Posizione) {

            return (
                <div id="containerPosizione">
                    <div id="backportafoglio" className="d-flex justify-content-center">
                        <div id="text" className="d-flex">
                            {window.innerWidth > 500?<div id="bannerDettaglio">
                                <div className='infoPort'>
                                    <div className='back' onClick={this.goBack}><ILeft/></div>
                                    <div className='namePort'><Get object={this.props.position} path={path}>{value =>
                                        <UpperCase string={value}/>}</Get></div>
                                    <div className='idPosition'> / Borrower {this.props.position.Posizione.id}</div>
                                </div>
                            </div>:''}
                            <div id="bannerNav">
                                {window.innerWidth < 500?
                                <h6>On this page:</h6>:''
                                }
                                <h5><a className='anchorLink' id='debGuar' href='#debGuar' onClick={this.setHighlight}>Debtors and Guarantors</a></h5>
                                <h5><a className='anchorLink' id='loans' href='#loans' onClick={this.setHighlight}>Loans</a></h5>
                                <h5><a className='anchorLink' id='warranties' href='#warranties' onClick={this.setHighlight}>Warranties</a></h5>
                                <h5><a className='anchorLink' id='procedures' href='#procedures' onClick={this.setHighlight}>Procedures</a></h5>
                                <h5><a className='anchorLink' id='realEstate' href='#realEstate' onClick={this.setHighlight}>Real Estate</a></h5>
                                <h5><a className='anchorLink' id='documents' href='#documents' onClick={this.setHighlight}>Documents</a></h5>
                            </div>
                        </div>
                    </div>
                    {window.innerWidth < 500?
                        <div id="bannerDettaglio">
                            <div className='infoPort'>
                                <div className='namePort'><span>...</span> / <span>Portfolios</span> / <span><Get object={this.props.position} path={path}>{value =>
                                    <UpperCase string={value}/>}</Get></span></div>
                                <div className='idPosition'> / Borrower {this.props.position.Posizione.id}</div>
                            </div>
                        </div>:''
                    }
                    <div id="detcont">
                        <div className="d-flex justify-content-between">
                            <div className='posizione'>
                                Borrower <span id="stato"> {this.props.position.Posizione.id}</span>
                            </div>
                            <div className="gbvRequest">
                                <div className='gbv'>
                                    {this.currencyFormatDE(this.props.position.Posizione.gbv)}<span>€</span>
                                </div>
                            </div>
                        </div>
                        {window.innerWidth > 500?
                            <div className="d-flex flex-row info">
                                <div className="d-flex flex-row campo">
                                    <h5>Id: <span>{this.props.position.Posizione.id}</span></h5>
                                </div>
                                <div className="d-flex flex-row campo">
                                    <h5>NDG: <span>{this.props.position.Posizione.ndgControparte}</span></h5>
                                </div>
                                <div className="d-flex flex-row campo">
                                    <h5>Debt Category: <span>{this.props.position.Posizione.debtCategory}</span></h5>
                                </div>
                                <div className="d-flex flex-row campo">
                                    <h5>Borrower type: <span>{this.props.position.Posizione.borrowerType}</span></h5>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="d-flex flex-row info">
                                    <div className="d-flex flex-row campo">
                                        <h5>Id: <span>{this.props.position.Posizione.id}</span></h5>
                                    </div>
                                    <div className="d-flex flex-row campo">
                                        <h5>Category: <span>{this.props.position.Posizione.debtCategory}</span></h5>
                                    </div>
                                </div>
                                <div className="d-flex flex-row info">
                                    <div className="d-flex flex-row campo">
                                        <h5>NDG: <span>{this.props.position.Posizione.ndgControparte}</span></h5>
                                    </div>
                                    <div className="d-flex flex-row campo">
                                        <h5>Subject type: <span>{this.props.position.Posizione.borrowerType}</span></h5>
                                    </div>
                                </div>
                            </div>
                        }
                        {window.innerWidth > 1024?
                            <ScrollableAnchor id={'debGuar'}>
                                <div data-id='debGuar' className='contentAnchor'>
                                    <div className="d-flex justify-content-between">
                                        <div id="obbligati" className="p-2"><h2 id="stato">Debtors and Guarantors</h2></div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-around">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">Complete Name</th>
                                                <th scope="col">Type of connection</th>
                                                <th scope="col">Type of subject</th>
                                                <th scope="col">Ateco</th>
                                                <th scope="col">Province</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {(() => {
                                                const rows = [];

                                                if (this.props.position.SoggettoAnagrafico) {
                                                    for (let i = 0; i < this.props.position.SoggettoAnagrafico.length; i++) {
                                                        rows.push(
                                                            <tr>
                                                                <td>{this.props.position.SoggettoAnagrafico[i].nomeCompleto}</td>
                                                                <td>{this.props.position.SoggettoAnagrafico[i].typeOfConnection}</td>
                                                                <td>{this.props.position.SoggettoAnagrafico[i].tipoSoggettoAnagrafico}</td>
                                                                <td>{this.props.position.SoggettoAnagrafico[i].classificazioneAteco ? this.props.position.SoggettoAnagrafico[i].classificazioneAteco.descrizione : ''}</td>
                                                                <td>{(this.props.position.SoggettoAnagrafico[i].luoghi && this.props.position.SoggettoAnagrafico[i].luoghi[0] && this.props.position.SoggettoAnagrafico[i].luoghi[0].elementoGeografiaProvincia) ? this.props.position.SoggettoAnagrafico[i].luoghi[0].elementoGeografiaProvincia.descrizione : ''}</td>
                                                            </tr>
                                                        );
                                                    }
                                                }
                                                return rows;
                                            })()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ScrollableAnchor>
                        :
                            <ScrollableAnchor id={'debGuar'}>
                                <div data-id='debGuar' className='contentAnchor'>
                                    <div className="d-flex justify-content-between">
                                        <div id="obbligati" className="p-2"><h2 id="stato">Debtors and Guarantors</h2></div>
                                    </div>

                                    {(() => {
                                        const rows = [];
                                        if (this.props.position.SoggettoAnagrafico) {
                                            for (let i = 0; i < this.props.position.SoggettoAnagrafico.length; i++) {
                                                rows.push(
                                                    <div className="d-flex flex-row justify-content-around">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td scope="col">Complete Name</td>
                                                                    <td>{this.props.position.SoggettoAnagrafico[i].nomeCompleto}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td scope="col">Type of connection</td>
                                                                    <td>{this.props.position.SoggettoAnagrafico[i].typeOfConnection}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td scope="col">Type of subject</td>
                                                                    <td>{this.props.position.SoggettoAnagrafico[i].tipoSoggettoAnagrafico}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td scope="col">Ateco</td>
                                                                    <td>{this.props.position.SoggettoAnagrafico[i].classificazioneAteco ? this.props.position.SoggettoAnagrafico[i].classificazioneAteco.descrizione : ''}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td scope="col">Province</td>
                                                                    <td>{(this.props.position.SoggettoAnagrafico[i].luoghi && this.props.position.SoggettoAnagrafico[i].luoghi[0] && this.props.position.SoggettoAnagrafico[i].luoghi[0].elementoGeografiaProvincia) ? this.props.position.SoggettoAnagrafico[i].luoghi[0].elementoGeografiaProvincia.descrizione : ''}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );
                                            }
                                        }else{
                                            rows.push(
                                                <div className="d-flex flex-row justify-content-around">
                                                    <table className="table">
                                                        <tbody>
                                                        <tr>
                                                            <td scope="col">Complete Name</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Type of connection</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Type of subject</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Ateco</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Province</td>
                                                            <td></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            );
                                        }
                                        return rows;
                                    })()}
                                </div>
                            </ScrollableAnchor>
                        }
                        <div className="container-fluid">
                        </div>
                        {window.innerWidth > 1024 ?
                            <ScrollableAnchor id={'loans'}>
                                <div data-id='loans' className='contentAnchor'>
                                    <div className="d-flex justify-content-between">
                                        <div id="rapporti" className="p-2"><h2 id="stato">Loans</h2></div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-around">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">Id</th>
                                                <th scope="col">Secured/Unsecured</th>
                                                <th scope="col">Category</th>
                                                <th scope="col">GBV</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(() => {
                                                const rows = [];

                                                if (this.props.position.Rapporto) {
                                                    for (let i = 0; i < this.props.position.Rapporto.length; i++) {
                                                        rows.push(
                                                            <tr>
                                                                <td>{this.props.position.Rapporto[i].id}</td>
                                                                <td>{this.props.position.Rapporto[i].type}</td>
                                                                <td>{this.props.position.Rapporto[i].category}</td>
                                                                <td>{this.props.position.Rapporto[i].gbv ? this.currencyFormatDE(this.props.position.Rapporto[i].gbv) + ' €' : ''}</td>
                                                            </tr>
                                                        );
                                                    }
                                                }
                                                return rows;
                                            })()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ScrollableAnchor>
                            :
                            <ScrollableAnchor id={'loans'}>
                                <div data-id='loans' className='contentAnchor'>
                                    <div className="d-flex justify-content-between">
                                        <div id="rapporti" className="p-2"><h2 id="stato">Loans</h2></div>
                                    </div>

                                    {(() => {
                                        const rows = [];
                                        if (this.props.position.Rapporto) {
                                            for (let i = 0; i < this.props.position.Rapporto.length; i++) {
                                                rows.push(
                                                    <div className="d-flex flex-row justify-content-around">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td scope="col">Id</td>
                                                                    <td>{this.props.position.Rapporto[i].id}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td scope="col">Secured/Unsecured</td>
                                                                    <td>{this.props.position.Rapporto[i].type}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td scope="col">Category</td>
                                                                    <td>{this.props.position.Rapporto[i].category}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td scope="col">GBV</td>
                                                                    <td>{this.props.position.Rapporto[i].gbv ? this.currencyFormatDE(this.props.position.Rapporto[i].gbv) + ' €' : ''}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );
                                            }
                                        }else{
                                            rows.push(
                                                <div className="d-flex flex-row justify-content-around">
                                                    <table className="table">
                                                        <tbody>
                                                        <tr>
                                                            <td scope="col">Id</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Secured/Unsecured</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Category</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">GBV</td>
                                                            <td></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            );
                                        }
                                        return rows;
                                    })()}

                                </div>
                            </ScrollableAnchor>
                        }
                        <div className="container-fluid">
                        </div>
                        {window.innerWidth > 1024 ?
                            <ScrollableAnchor id={'warranties'}>
                                <div data-id='warranties' className='contentAnchor'>
                                    <div className="d-flex justify-content-between">
                                        <div id="garanzie" className="p-2"><h2 id="stato">Warranties</h2></div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-around">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">Id</th>
                                                <th scope="col">Class</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Nominal Value</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Prevalent degree</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(() => {
                                                const rows = [];

                                                if (this.props.position.Garanzia) {
                                                    for (let i = 0; i < this.props.position.Garanzia.length; i++) {
                                                        rows.push(
                                                            <tr>
                                                                <td>{this.props.position.Garanzia[i].codiceOriginario}</td>
                                                                <td>{this.props.position.Garanzia[i].warrantyClass}</td>
                                                                <td>{this.props.position.Garanzia[i].warrantyType}</td>
                                                                <td>{this.props.position.Garanzia[i].valoreNominale ? this.currencyFormatDE(this.props.position.Garanzia[i].valoreNominale) + ' €' : ''}</td>
                                                                <td>{this.props.position.Garanzia[i].statoGaranzia}</td>
                                                                <td>{this.props.position.Garanzia[i].relevanceDegree}</td>
                                                            </tr>
                                                        );
                                                    }
                                                }
                                                return rows;
                                            })()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ScrollableAnchor>
                            :
                            <ScrollableAnchor id={'warranties'}>
                                <div data-id='warranties' className='contentAnchor'>
                                    <div className="d-flex justify-content-between">
                                        <div id="garanzie" className="p-2"><h2 id="stato">Warranties</h2></div>
                                    </div>
                                    {(() => {
                                        const rows = [];
                                        if (this.props.position.Garanzia) {
                                            for (let i = 0; i < this.props.position.Garanzia.length; i++) {
                                                rows.push(
                                                    <div className="d-flex flex-row justify-content-around">
                                                        <table className="table">
                                                            <tbody>
                                                            <tr>
                                                                <td scope="col">Id</td>
                                                                <td>{this.props.position.Garanzia[i].code}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Class</td>
                                                                <td>{this.props.position.Garanzia[i].warrantyClass}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Type</td>
                                                                <td>{this.props.position.Garanzia[i].warrantyType}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Nominal Value</td>
                                                                <td>{this.props.position.Garanzia[i].valoreNominale ? this.currencyFormatDE(this.props.position.Garanzia[i].valoreNominale) + ' €' : ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Status</td>
                                                                <td>{this.props.position.Garanzia[i].status}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Prevalent degree</td>
                                                                <td>{this.props.position.Garanzia[i].relevanceDegree}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );
                                            }
                                        }else{
                                            rows.push(
                                                <div className="d-flex flex-row justify-content-around">
                                                    <table className="table">
                                                        <tbody>
                                                        <tr>
                                                            <td scope="col">Id</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Class</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Type</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Nominal Value</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Status</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Prevalent degree</td>
                                                            <td></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            );
                                        }
                                        return rows;
                                    })()}
                                </div>
                            </ScrollableAnchor>
                        }
                        <div className="container-fluid">
                        </div>
                        {window.innerWidth > 1024 ?
                            <ScrollableAnchor id={'procedures'}>
                                <div data-id='procedures' className='contentAnchor'>
                                    <div className="d-flex justify-content-between">
                                        <div id="procedure" className="p-2"><h2 id="stato">Procedures</h2></div>
                                    </div>

                                    <div className="d-flex flex-row justify-content-around">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">Type Class</th>
                                                <th scope="col">Court</th>
                                                <th scope="col">Court ruling number</th>
                                                <th scope="col">Total claim approved by the court</th>
                                                <th scope="col">Last stage</th>
                                                <th scope="col">Date last stage</th>
                                                <th scope="col">Stage concluded</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(() => {
                                                const rows = [];
                                                if (this.props.position.ProceduraLegale) {
                                                    for (let i = 0; i < this.props.position.ProceduraLegale.length; i++) {
                                                        rows.push(
                                                            <tr>
                                                                <td>{this.props.position.ProceduraLegale[i].descrizione}</td>
                                                                <td>{this.props.position.ProceduraLegale[i].lawOffice}</td>
                                                                <td>{this.props.position.ProceduraLegale[i].numeroRuolo}</td>
                                                                <td>{this.props.position.ProceduraLegale[i].valoreCausa ? this.currencyFormatDE(this.props.position.ProceduraLegale[i].valoreCausa) + ' €' : ''}</td>
                                                                <td>{this.props.position.ProceduraLegale[i].lastPhase}</td>
                                                                <td>{this.props.position.ProceduraLegale[i].date}</td>
                                                                <td>{this.props.position.ProceduraLegale[i].isTerminated}</td>

                                                            </tr>
                                                        );
                                                    }
                                                }
                                                return rows;
                                            })()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ScrollableAnchor>
                            :
                            <ScrollableAnchor id={'procedures'}>
                                <div data-id='procedures' className='contentAnchor'>
                                    <div className="d-flex justify-content-between">
                                        <div id="procedure" className="p-2"><h2 id="stato">Procedures</h2></div>
                                    </div>
                                    {(() => {
                                        const rows = [];
                                        if (this.props.position.ProceduraLegale) {
                                            for (let i = 0; i < this.props.position.ProceduraLegale.length; i++) {
                                                rows.push(
                                                    <div className="d-flex flex-row justify-content-around">
                                                        <table className="table">
                                                            <tbody>
                                                            <tr>
                                                                <td scope="col">Type Class</td>
                                                                <td>{this.props.position.ProceduraLegale[i].descrizione}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Court</td>
                                                                <td>{this.props.position.ProceduraLegale[i].lawOffice}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Court ruling number</td>
                                                                <td>{this.props.position.ProceduraLegale[i].numeroRuolo}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Total claim approved by the court</td>
                                                                <td>{this.props.position.ProceduraLegale[i].valoreCausa ? this.currencyFormatDE(this.props.position.ProceduraLegale[i].valoreCausa) + ' €' : ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Last stage</td>
                                                                <td>{this.props.position.ProceduraLegale[i].lastPhase}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Date last stage</td>
                                                                <td>{this.props.position.ProceduraLegale[i].date}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Stage concluded</td>
                                                                <td>{this.props.position.ProceduraLegale[i].isTerminated}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );
                                            }
                                        }else{
                                            rows.push(
                                                <div className="d-flex flex-row justify-content-around">
                                                    <table className="table">
                                                        <tbody>
                                                        <tr>
                                                            <td scope="col">Type Class</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Court</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Court ruling number</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Total claim approved by the court</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Last stage</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Date last stage</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Stage concluded</td>
                                                            <td></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            );
                                        }
                                        return rows;
                                    })()}
                                </div>
                            </ScrollableAnchor>
                        }
                        <div className="container-fluid">
                        </div>
                        {window.innerWidth > 1024 ?
                            <ScrollableAnchor id={'realEstate'}>
                                <div data-id='realEstate' className='contentAnchor'>
                                    <div className="d-flex justify-content-between">
                                        <div id="immobili" className="p-2"><h2 id="stato">Real Estate</h2></div>
                                    </div>

                                    <div className="d-flex flex-row justify-content-around">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">Type Class</th>
                                                <th scope="col">Cadastral category</th>
                                                <th scope="col">Municipality</th>
                                                <th scope="col">Province</th>
                                                <th scope="col">Last Evaluation date</th>
                                                <th scope="col">Evaluation origin</th>
                                                <th scope="col">Total value</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(() => {
                                                const rows = [];
                                                if (this.props.position.Immobile) {
                                                    for (let i = 0; i < this.props.position.Immobile.length; i++) {
                                                        rows.push(
                                                            <tr>
                                                                <td>{this.props.position.Immobile[i].descrizione}</td>
                                                                <td>{this.props.position.Immobile[i].categoriaCatastale ? this.props.position.Immobile[i].categoriaCatastale.descrizione : ''}</td>
                                                                <td>{this.props.position.Immobile[i].municipality}</td>
                                                                <td>{this.props.position.Immobile[i].province}</td>
                                                                <td>{this.props.position.Immobile[i].date}</td>
                                                                <td>{this.props.position.Immobile[i].valueOrigin}</td>
                                                                <td>{this.props.position.Immobile[i].totalValue ? this.currencyFormatDE(this.props.position.Immobile[i].totalValue) + ' €' : '' }</td>
                                                            </tr>
                                                        );
                                                    }
                                                }
                                                return rows;
                                            })()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ScrollableAnchor>
                            :
                            <ScrollableAnchor id={'realEstate'}>
                                <div data-id='realEstate' className='contentAnchor'>
                                    <div className="d-flex justify-content-between">
                                        <div id="immobili" className="p-2"><h2 id="stato">Real Estate</h2></div>
                                    </div>
                                    {(() => {
                                        const rows = [];

                                        if (this.props.position.Immobile) {
                                            for (let i = 0; i < this.props.position.Immobile.length; i++) {
                                                rows.push(
                                                    <div className="d-flex flex-row justify-content-around">
                                                        <table className="table">
                                                            <tbody>
                                                            <tr>
                                                                <td scope="col">Type Class</td>
                                                                <td>{this.props.position.Immobile[i].descrizione}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Cadastral category</td>
                                                                <td>{this.props.position.Immobile[i].categoriaCatastale ? this.props.position.Immobile[i].categoriaCatastale.descrizione : ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Municipality</td>
                                                                <td>{this.props.position.Immobile[i].municipality}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Province</td>
                                                                <td>{this.props.position.Immobile[i].province}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Last Evaluation date</td>
                                                                <td>{this.props.position.Immobile[i].date}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Evaluation origin</td>
                                                                <td>{this.props.position.Immobile[i].valueOrigin}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Total value</td>
                                                                <td>{this.props.position.Immobile[i].totalValue ? this.currencyFormatDE(this.props.position.Immobile[i].totalValue) + ' €' : '' }</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );
                                            }
                                        }else{
                                            rows.push(
                                                <div className="d-flex flex-row justify-content-around">
                                                    <table className="table">
                                                        <tbody>
                                                        <tr>
                                                            <td scope="col">Type Class</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Cadastral category</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Municipality</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Province</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Last Evaluation date</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Evaluation origin</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Total value</td>
                                                            <td></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            );
                                        }
                                        return rows;
                                    })()}
                                </div>
                            </ScrollableAnchor>
                        }
                        <div className="container-fluid">
                        </div>
                        {window.innerWidth > 1024 ?
                            <ScrollableAnchor id={'documents'}>
                                <div data-id='documents' className='contentAnchor'>
                                    <div className="d-flex justify-content-between">
                                        <div id="documenti" className="p-2"><h2 id="stato">Documents</h2></div>
                                    </div>

                                    <div className="d-flex flex-row justify-content-around">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">Type</th>
                                                <th scope="col">Object</th>
                                                <th scope="col">File name</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(() => {
                                                const rows = [];

                                                if (this.props.position.Documento) {
                                                    for (let i = 0; i < this.props.position.Documento.length; i++) {
                                                        rows.push(
                                                            <tr>
                                                                <td>{this.props.position.Documento[i].tipoDocumento ? this.props.position.Documento[i].tipoDocumento.descrizione : ''}</td>
                                                                <td>{this.props.position.Documento[i].oggetto}</td>
                                                                <td>{this.props.position.Documento[i].nomeFile}</td>
                                                            </tr>
                                                        );
                                                    }
                                                }
                                                return rows;
                                            })()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ScrollableAnchor>
                            :
                            <ScrollableAnchor id={'documents'}>
                                <div data-id='documents' className='contentAnchor'>
                                    <div className="d-flex justify-content-between">
                                        <div id="documenti" className="p-2"><h2 id="stato">Documents</h2></div>
                                    </div>
                                    {(() => {
                                        const rows = [];

                                        if (this.props.position.Documento) {
                                            for (let i = 0; i < this.props.position.Documento.length; i++) {
                                                rows.push(
                                                    <div className="d-flex flex-row justify-content-around">
                                                        <table className="table">
                                                            <tbody>
                                                            <tr>
                                                                <td scope="col">Type</td>
                                                                <td>{this.props.position.Documento[i].tipoDocumento ? this.props.position.Documento[i].tipoDocumento.descrizione : ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">Object</td>
                                                                <td>{this.props.position.Documento[i].oggetto}</td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="col">File name</td>
                                                                <td>{this.props.position.Documento[i].nomeFile}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );
                                            }
                                        }else{
                                            rows.push(
                                                <div className="d-flex flex-row justify-content-around">
                                                    <table className="table">
                                                        <tbody>
                                                        <tr>
                                                            <td scope="col">Type</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">Object</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col">File name</td>
                                                            <td></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            );
                                        }
                                        return rows;
                                    })()}
                                </div>
                            </ScrollableAnchor>
                        }
                        <div className="container-fluid">
                        </div>
                    </div>
                </div>

            );
        } else {
            return <ResultLoader />;
        }
    }



}

function mapStateToProps(state) {
    return {
        position: state.positions.dato,
    }
}

const path = 'posizione.portafoglio.denominazione';

export default connect(mapStateToProps)(DettaglioPosizione)
