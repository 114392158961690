import React, {Component} from 'react';

import { ReactComponent as Icheck } from '../styles/svg/check-circle.svg';
import { ReactComponent as ITimes } from '../styles/svg/times-circle.svg';
import { ReactComponent as IClose } from '../styles/svg/window-close.svg';
import { Link } from "react-router-dom";

class Position extends Component{
    constructor(props){
        super(props);
    }

    handleCurrentProcedure(event){
        Array.from(document.getElementsByClassName('containerIcons')).forEach((el) => {
            el.classList.remove('show');
        });

        const parent = event.target.parentNode;
        parent.getElementsByClassName('containerIcons').item(0).classList.toggle('show');
    }

    handleCloseCurrentProcedure(){
        Array.from(document.getElementsByClassName('containerIcons')).forEach((el) => {
            el.classList.remove('show');
        });
    }

    setCurrentProcedure(){
        return(
        <div className="containerIcons">
                <div className='close' onClick={this.handleCloseCurrentProcedure}><IClose width='14px'/></div>
                <div className='iconProc'>
                    {this.props.data.attStragAttiva !== false ? <Icheck width='14px' fill='#59a669'/> :
                        <ITimes width='14px' fill='#e1e1e1'/>}
                    <h4> Active Out-of-court activity</h4>
                </div>
                <div className='iconProc'>
                    {this.props.data.procMonitAttivo !== false? <Icheck width='14px' fill='#59a669'/> : <ITimes width='14px' fill='#e1e1e1'/>}
                    <h4> Active Payment injuction</h4>
                </div>
                <div className='iconProc'>
                    {this.props.data.procEsecMobAttiva !== false ? <Icheck width='14px' fill='#59a669'/> :
                        <ITimes width='14px' fill='#e1e1e1'/>}
                    <h4> Active Personal Property Foreclosure</h4>
                </div>
                <div className='iconProc'>
                    {this.props.data.procEsecImmobAttiva !== false ? <Icheck width='14px' fill='#59a669'/> :
                            <ITimes width='14px' fill='#e1e1e1'/>}
                    <h4> Active Real Estate Foreclosure</h4>
                </div>
                <div className='iconProc'>
                    {this.props.data.procConcorAttiva !== false? <Icheck width='14px' fill='#59a669'/> : <ITimes width='14px' fill='#e1e1e1'/>}
                    <h4> Active Insolvency procedure</h4>
                </div>
                <div className='iconProc'>
                    {this.props.data.causeGudiziInCorso !== false? <Icheck width='14px' fill='#59a669'/> : <ITimes width='14px' fill='#e1e1e1'/>}
                    <h4>  Ongoing lawsuit</h4>
                </div>
            </div>


        )
    }

    setNoMatch(noMatch, label) {
        return noMatch.map((match, index) => {
            return(
                <div className='item'>{label}: {match}</div>
            )
        });
    }

    setSingleNoMatch(noMatch, label) {
        return(
            <div className='item'>{label}: {noMatch}</div>
        )
    }

    currencyFormatDE(num) {
        return (
            num
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    render() {
        var hasNotMached = ((this.props.notMatchedGbv !== undefined && this.props.notMatchedGbv !== null)
            || this.props.notMatchedDebtCategory.length > 0 || this.props.notMatchedBorrowerType.length > 0
            || this.props.notMatchedCaratteristiche.length > 0 || this.props.notMatchedProcedimenti.length > 0
            || this.props.notMatchedAssetLocation || this.props.notMatchedBorrowerLocation || this.props.notMatchedHashtag);
        if(window.innerWidth > 500){
            return (
                <Link to={`/position/${this.props.data.id}`} style={{textDecoration: 'none'}}>
                    <div className='row'>
                        <div className={`d-flex col-12 justify-content-between align-content-center bodyPos ${hasNotMached ? 'grigio' : ''}`}>
                            <div div className='colPos noBold small'>{this.props.data.id}</div>
                            <div div className='colPos small'>{this.props.data.debitoreNDG}</div>
                            <div div className='colPos medium'>{this.props.data.secUnsec}</div>
                            <div div className='colPos medium'>{this.props.data.corpRetail}</div>
                            <div div className='colPos small'>{this.props.data.provinciaPrevalente}</div>
                            <div div className='colPos large'>{this.props.data.prevCategoria}</div>
                            <div div className='colPos extraSmall'>{this.props.data.nr}</div>
                            <div div className='colPos small'>{this.props.data.ipoteGradoMin}</div>
                            <div div className='colPos proc small'>
                                {this.props.currentProceduresCount}
                                <div className="circle" onMouseOver={this.handleCurrentProcedure} onMouseOut={this.handleCloseCurrentProcedure}>...</div>
                                {this.setCurrentProcedure()}
                            </div>
                            <div div className='colPos big extraLarge'>{this.currencyFormatDE(this.props.data.gbv)}</div>
                            <div div className='colPos last'>
                                <div className='detPos'></div>
                            </div>
                            <div className='noMatch'>
                                {this.setNoMatch(this.props.notMatchedDebtCategory, 'Debt Category')}
                                {this.setNoMatch(this.props.notMatchedBorrowerType, 'Borrower Type')}
                                {this.setNoMatch(this.props.notMatchedCaratteristiche, 'Asset')}
                                {this.props.notMatchedHashtag ? this.setSingleNoMatch(this.props.notMatchedHashtag, 'Name Hashtag') : null}
                                {this.props.notMatchedAssetLocation ? this.setSingleNoMatch(this.props.notMatchedAssetLocation, 'Asset Location') : null}
                                {this.props.notMatchedBorrowerLocation ? this.setSingleNoMatch(this.props.notMatchedBorrowerLocation, 'Borrower Location') : null}
                                {this.setNoMatch(this.props.notMatchedProcedimenti, 'Procedure')}

                            </div>
                        </div>
                    </div>
                </Link>
            );
        }else{
            return(
                <div className='row mobile'>
                    <div className={`bodyPos ${hasNotMached ? 'grigio' : ''}`}>
                        <Link to={`/position/${this.props.data.id}`} style={{textDecoration: 'none'}}>
                            <div className='rowPos header'>
                                <div className='colPos '>Id <span>{this.props.data.id}</span></div>
                                <div className='colPos big'>{this.currencyFormatDE(this.props.data.gbv)}</div>
                            </div>
                            <div className='rowPos'>
                                <div className='colPos '>NDG</div>
                                <div className='colPos value'>{this.props.data.debitoreNDG}</div>
                            </div>
                            <div className='rowPos'>
                                <div className='colPos '>Debt Category</div>
                                <div className='colPos value'>{this.props.data.secUnsec}</div>
                            </div>
                            <div className='rowPos'>
                                <div className='colPos '>Borrower type</div>
                                <div className='colPos value'>{this.props.data.corpRetail}</div>
                            </div>
                            <div className='rowPos'>
                                <div className='colPos '>Borrower's province</div>
                                <div className='colPos value'>{this.props.data.provinciaPrevalente}</div>
                            </div>
                            <div className='rowPos'>
                                <div className='colPos '>Prevalent Loan Category</div>
                                <div className='colPos value'>{this.props.data.prevCategoria}</div>
                            </div>
                            <div className='rowPos'>
                                <div className='colPos '>Nr.Asset</div>
                                <div className='colPos value'>{this.props.data.nr}</div>
                            </div>
                            <div className='rowPos'>
                                <div className='colPos '>Prevalent mortages's degree</div>
                                <div className='colPos value'>{this.props.data.ipoteGradoMin}</div>
                            </div>
                        </Link>
                        <div className='rowPos'>
                            <div className='colPos '>Current procedures</div>
                            <div className='colPos proc'>
                                {this.props.currentProceduresCount}
                                <div className="circle" onClick={this.handleCurrentProcedure}>...</div>
                                {this.setCurrentProcedure()}
                             </div>
                        </div>
                        <div className='noMatch'>
                            {this.setNoMatch(this.props.notMatchedDebtCategory, 'Debt Category')}
                            {this.setNoMatch(this.props.notMatchedBorrowerType, 'Borrower Type')}
                            {this.setNoMatch(this.props.notMatchedCaratteristiche, 'Asset')}
                            {this.props.notMatchedHashtag ? this.setSingleNoMatch(this.props.notMatchedHashtag, 'Name Hashtag') : null}
                            {this.props.notMatchedAssetLocation ? this.setSingleNoMatch(this.props.notMatchedAssetLocation, 'Asset Location') : null}
                            {this.props.notMatchedBorrowerLocation ? this.setSingleNoMatch(this.props.notMatchedBorrowerLocation, 'Borrower Location') : null}
                            {this.setNoMatch(this.props.notMatchedProcedimenti, 'Procedure')}
                        </div>
                    </div>
                </div>
            );
        }

    }
}

export default Position
