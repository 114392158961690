import React from 'react';
import FilterBox from '../filter/FilterBox'
import { FilterStatic } from '../filter/FilterStatic'
import '../../styles/filter/filter.css'
import * as actions from "../../actions";
import {connect} from "react-redux";
import InputRangeBox from '../../shared/filter/InputRange';
import AutoCompileCustom from "../filter/AutoCompileCustom";

class FilterPos extends React.Component{

    constructor(props){
        super(props);
        this.autocompileLuogoRef = React.createRef();
        this.autocompileDebRef = React.createRef();
        this.gbvPosRef = React.createRef();
    }

    componentDidUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): void {
        if(this.autocompileLuogoRef.current !== null){
            this.autocompileLuogoRef.current.reset();
        }
        if(this.autocompileDebRef.current !== null) {
            this.autocompileDebRef.current.reset();
        }
        if(this.gbvPosRef.current !== null) {
            this.props.getFiltroRange(this.gbvPosRef.current)
        }
    }

    componentWillMount() {
        this.props.dispatch(actions.fetchFiltriPosValues(this.props.idPort));
    }

    initGBV(){
        if(this.props.filtriPosValues.gbvValues.length > 0){
            return <InputRangeBox ref={this.gbvPosRef} id="gbvValues" filtro={this.props.filtriPosValues.gbvValues} label="Borrower GBV" handleInputRange={this.props.handleInputRange}/>
        }
    }

    initTipoDiCredito(){
        if(this.props.filtriPosValues.tipoDiCreditoValues.length > 0){
            return <FilterBox id="tipoDiCreditoValues" filtro={this.props.filtriPosValues.tipoDiCreditoValues} label="Debt category" handleCheckElement={this.props.handleCheckElement} handleSoloElement={this.props.handleSoloElement}/>
        }
    }

    initControparte(){
        if(this.props.filtriPosValues.controparteValues.length > 0){
            return <FilterBox id="controparteValues" filtro={this.props.filtriPosValues.controparteValues} label="Borrower type" handleCheckElement={this.props.handleCheckElement} handleSoloElement={this.props.handleSoloElement}/>
        }
    }

    initCaratteristicheAssets(){
        if(this.props.filtriPosValues.caratteristicheAssets.length > 0){
            return <FilterBox id="caratteristicheAssetValues" filtro={this.props.filtriPosValues.caratteristicheAssets} label="Asset type" handleCheckElement={this.props.handleCheckElement} handleSoloElement={this.props.handleSoloElement}/>
        }
    }

    initLuogoAssets(){
        if(this.props.filtriPosValues.luogoAsset.length > 0){
            return <AutoCompileCustom ref={this.autocompileLuogoRef} label ='Asset location' id="luogoAsset" handleInputAutocompile={this.props.handleInputAutocompile} filtro={this.props.filtriPosValues.luogoAsset} />
        }
    }

    initResidenzaDebitori(){
        if(this.props.filtriPosValues.residenzaDebitori.length > 0){
            return <AutoCompileCustom ref={this.autocompileDebRef} label ='Borrower location' id="residenzaDebitori" handleInputAutocompile={this.props.handleInputAutocompile} filtro={this.props.filtriPosValues.residenzaDebitori}/>
        }
    }

    render(){
        if(this.props.filtriPosValues.length !== 0){
            return(
                <div id='filterContainer' className='col-3'>
                    <div className='headerFilter'>
                        <h4 className='countAll'>Filter borrowers</h4>
                        <h5 className='reset' onClick={this.props.resetAll.bind(this,this.props.idPort)}>Clear All</h5>
                        <span className='close' onClick={this.props.showFilter}></span>
                    </div>
                    <div className='container'>

                        {this.initGBV()}
                        {this.initTipoDiCredito()}
                        {this.initControparte()}
                        {this.initCaratteristicheAssets()}
                        {this.initLuogoAssets()}
                        {this.initResidenzaDebitori()}

                        <FilterStatic id="procedimentiInCorsoValues" label="Current procedures" handleCheckElement={this.props.handleCheckElement} handleSoloElement={this.props.handleSoloElement}/>
                    </div>
                </div>
            );
        }else{
            return false;
        }

    }

}

function mapStateToProps(state){
    return{
        filtriPosValues: state.filtriValues.dataPos
    }
}

export default connect(mapStateToProps)(FilterPos)
