import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RoutedTabs, NavTab } from 'react-router-tabs';
import PositionListSearched from './PositionListSerched';
import Map from './Map';
import Strats from './Strats';
import { FaList, FaMapMarkerAlt, FaSignal } from 'react-icons/fa';

export const TabsDetail = ({ props, handleChangeTab, countPostionsFilter, handleSortBy, handleSortOrder}) => {
    const match = props.match.params.id;
    return (
        <div id='lista'>
            <RoutedTabs startPathWith={'/portfolio-detail/'+match} className='tab d-flex justify-content-center align-items-center'>
                <NavTab to='/positions' className='flex-fill nav-tab'><FaList/> Borrowers</NavTab>
                <NavTab to='/map' className='flex-fill nav-tab'><FaMapMarkerAlt/> Map</NavTab>
                <NavTab to='/strats' className='flex-fill nav-tab'><FaSignal/> Strats</NavTab>

            </RoutedTabs>

            <Switch>
                <Route exact path={'/portfolio-detail/:id'} render={() => <Redirect replace to={'/portfolio-detail/'+match+'/positions'} />} />
                <Route path={'/portfolio-detail/:id/positions'} render={() => <PositionListSearched  match={match} handleChangeTab={handleChangeTab} countPostionsFilter={countPostionsFilter} handleSortBy={handleSortBy} handleSortOrder={handleSortOrder}/>}/>
                <Route path={'/portfolio-detail/:id/map'} component={() => <Map idPort={match} handleChangeTab={handleChangeTab} countPostionsFilter={countPostionsFilter}/>} />
                <Route path={'/portfolio-detail/:id/strats'} render={() => <Strats match={match} handleChangeTab={handleChangeTab} />}/>
            </Switch>



        </div>
    );
};
