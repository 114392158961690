import React, { Component } from 'react';
import {PackList} from "../packs/PackList";
import PackCarousel  from '../packs/PackCarousel';
import {connect} from "react-redux";
import * as actions from "../../actions";

class ViewPacks extends Component{

    initViewPacks(){
        if(this.props.packs.length === 0){
            return <section/>
        }else if(window.innerWidth < 500){
            if(this.props.packs.length > 1){
                return <PackCarousel all={this.props} bkg={this.props.bkg} toLink='/search/portfolios'/>
            }else{
                return <PackList all={this.props} bkg={this.props.bkg} toLink='/search/portfolios'/>
            }
        }else if(window.innerWidth >= 768 && window.innerWidth <= 1024){
            if(this.props.packs.length > 2){
                return <PackCarousel all={this.props} bkg={this.props.bkg} toLink='/search/portfolios'/>
            }else{
                return <PackList all={this.props} bkg={this.props.bkg} toLink='/search/portfolios'/>
            }
        }else if(this.props.packs.length <= 4){
            return <PackList all={this.props} bkg={this.props.bkg} toLink='/search/portfolios'/>
        }else if( this.props.forceList && window.innerWidth > 500) {
            return <PackList all={this.props} bkg={this.props.bkg} toLink='/search/portfolios'/>
        }else{
            return <PackCarousel all={this.props} bkg={this.props.bkg} toLink='/search/portfolios'/>
        }
    }

    componentWillMount() {
        this.props.dispatch(actions.fetchPacks());

    }


    render(){
        return(
            this.initViewPacks()
        );

    }
}


function mapStateToProps(state){
    return{
        packs: state.packs.data
    }
}

export default connect(mapStateToProps)(ViewPacks)