import React from 'react';
import {Link} from "react-router-dom";
import { ReactComponent as ILogin } from '../../styles/svg/external-link-square.svg';

import '../../styles/areaPubblica/buyer.css';
import { Footer } from './Footer';

class Buyer extends React.Component{

    componentDidMount() {
        document.getElementById('body').classList.add('big');
        if(window.innerWidth < 500){
            document.getElementById('body').classList.add('public');
        }
        document.getElementById('marketPlace').classList.add('hide');
    }
    componentWillUnmount() {
        document.getElementById('body').classList.remove('big');
        if(window.innerWidth < 500){
            document.getElementById('body').classList.remove('public');
        }
        document.getElementById('marketPlace').classList.remove('hide');
    }

    openMenu() {
        document.getElementById('openPublic').classList.toggle('open');
    }

    writeMail() {
        window.location.href = "mailto:"+process.env.REACT_APP_MAILTO;
    }

    render() {
        return(
            <div id='buyer'>
                <div className='fasciaHeader'>
                    <div id='headerPublic'>
                        {window.innerWidth > 500?
                            <div className='logo'>
                                <Link to='/'><img src={require('../../styles/images/header.png')}/></Link>
                            </div>
                            :
                            <div className='menuMobile'>
                                <div className='logo'>
                                    <Link to='/'><img src={require('../../styles/images/header.png')}/></Link>
                                </div>
                                <div className='btnMenu' onClick={this.openMenu}>
                                    <img src={require('../../styles/images/openMenu.png')}/>
                                </div>
                            </div>
                        }
                        {window.innerWidth > 500?
                            <div id='openPublic' className='linkHeader'>
                                <Link to='/for-buyer'><h5 className='active'>For Buyer</h5></Link>
                                <Link to='/for-seller'><h5>For Seller</h5></Link>
                                <Link to='/about'><h5>About Us</h5></Link>
                                {/*<Link to='/faq'><h5>FAQ</h5></Link>*/}
                                <a href={process.env.REACT_APP_CAS_LOGIN_URL + window.location.origin + '/home'}>
                                    <div className='login'>
                                        <ILogin width='14px' fill='#008996'/><h5>Log In</h5>
                                    </div>
                                </a>
                                <Link to='/signup'>
                                    <div className='signIn'>
                                        <h5>Sign Up</h5>
                                    </div>
                                </Link>
                            </div>
                            :
                            <div id='openPublic' className='linkHeader'>
                                <div className='linkMenu'>
                                    <Link to='/for-buyer'><h5 className='active'>For Buyer</h5></Link>
                                    <Link to='/for-seller'><h5>For Seller</h5></Link>
                                    <Link to='/about'><h5>About Us</h5></Link>
                                    {/*<Link to='/faq'><h5>FAQ</h5></Link>*/}
                                </div>
                                <div className='buttonMenu'>
                                    <Link to='/home'>
                                        <div className='login'>
                                            <ILogin width='14px' fill='#008996'/><h5>Log In</h5>
                                        </div>
                                    </Link>
                                    <Link to='/signup'>
                                        <div className='signIn'>
                                            <h5>Sign Up</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='description'>
                        <span className='type'>For Buyer</span>
                        <span className='title'>Discover the Cerved <b>data<br/> driven</b> marketplace platform.</span>
                        {
                        window.innerWidth>500?
                            <img src={require('../../styles/images/buyer.png')}/>
                            :
                            <img src={require('../../styles/images/buyer_mobile.png')}/>
                        }
                    </div>
                </div>
                <div className='fasciaSearch'>
                    <div className='img'>
                        <img src={require('../../styles/images/search.png')}/>
                    </div>
                    <div className='description'>
                        <div className='question'>
                            Look through <b>our portfolios..</b>
                        </div>
                        <div className='testo'>
                            Cerved Markagain will provide a wide <b>variety of selection</b> of portfolios, offering clustered portfolios or single-name basis, which will allow investors to find what they are looking for.
                        </div>
                        <div className='testo'>
                            Our online marketplace guarantees <b>complete and up-to-date information</b> that let investors to properly assess the portfolio they wish to purchase.
                        </div>
                    </div>
                </div>
                {window.innerWidth>500?
                    <div className='fasciaFast'>
                        <div className='description'>
                            <div className='question'>
                                <b>Fast</b> and <b>targeted filtering.</b>
                            </div>
                            <div className='testo'>
                                Investors can find the portfolio they want quickly and securely using advanced search filters that permit to carry out targeted searches that meet purchasing goals.
                            </div>
                        </div>
                        <div className='img'>
                            <img src={require('../../styles/images/fast.png')}/>
                        </div>
                    </div>
                    :
                    <div className='fasciaFast'>
                        <div className='img'>
                            <img src={require('../../styles/images/fast.png')}/>
                            <div className='gradient'></div>
                        </div>
                        <div className='description'>
                            <div className='question'>
                                <b>Fast</b> and <b>targeted filtering.</b>
                            </div>
                            <div className='testo'>
                                Investors can find the portfolio they want quickly and securely using advanced search filters that permit to carry out targeted searches that meet purchasing goals.
                            </div>
                        </div>
                    </div>
                }

                <div className='fasciaAdvisor'>
                    <div className='img'>
                        <img src={require('../../styles/images/advisor.png')}/>
                    </div>
                    <div className='description'>
                        <div className='question'>
                            Get in touch with our <b>Advisors.</b>
                        </div>
                        <div className='testo'>
                            Investors can review on line details of each single borrower of the portfolios. They can request data tape and review portfolios valuation analysis performed by Cerved team of specialists. Therefore each portfolio has its own Cerved reference that will support investors for any clarification request and will provide the necessary access to handle each stage of portfolio purchase and post-sale.
                        </div>
                    </div>
                </div>
                {/*
                    window.innerWidth>500?
                        <div className='fasciaQuestions'>
                            <div className='description'>
                                <div className='question'>
                                    <b>Frequently asked questions</b>
                                </div>
                                <div className='testo'>
                                    You didn’t find what you were looking for? <b className='highlight'>Read all our
                                    FAQ</b>
                                </div>
                            </div>
                            <div className='ask'>
                                <div className='item'>
                                    <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                    <div className='plus'>
                                        <IPlus fill='#008996' width='16px'/>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                    <div className='plus'>
                                        <IPlus fill='#008996' width='16px'/>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                    <div className='plus'>
                                        <IPlus fill='#008996' width='16px'/>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                    <div className='plus'>
                                        <IPlus fill='#008996' width='16px'/>
                                    </div>
                                </div>

                            </div>
                        </div>
                        :
                        ''
                */}

                <div className='fasciaReady'>
                    <div className='description'>
                        <span className='title'>Are you ready to start?</span>
                        <span className='subtitle'>Request your login credential, enter your personal area and start browsing over 12 portfolios. </span>
                        <Link to='/signup'><span className='getStart'>Get started!</span></Link>
                    </div>
                </div>
                <div className='fasciaHelp'>
                    <div className='description'>
                        <div className='question'>
                            Do you need help?
                        </div>
                        {window.innerWidth>500?
                            <div className='testo' onClick={this.writeMail}>
                                <span>Write Us an email</span>
                            </div>
                            :
                            <div className='testo' onClick={this.writeMail}>
                                <span>Write Us an email</span>
                            </div>
                        }
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Buyer
