import React from 'react'

class CheckBox extends React.Component{
    constructor(props){
        super(props)
    }

    toCamelCase(str) {
        return str.toLowerCase().replace(/(?:(^.)|(\s+.))/g, function(match) {
            return match.charAt(match.length-1).toUpperCase();
        });
    }

    componentDidMount() {
        let urlParams = new URLSearchParams(window.location.search);
        if(urlParams.has('filtroHome' )){
            const filtroValue = urlParams.get('filtroHome').split('-');
            if(filtroValue[0] === 'Mix'){
                if(this.refs.checkbox.value === 'Secured'){
                    this.refs.checkbox.click()
                }
                if(this.refs.checkbox.value === 'Unsecured' || this.refs.checkbox.value === 'UnSecured'){
                    this.refs.checkbox.click()
                }
            }
            if(this.refs.checkbox.value.toUpperCase() === filtroValue[0].toUpperCase()){
                this.refs.checkbox.click()
            }
        }
    }

    render() {
        return (

            <div className='d-flex check'>
                <input ref='checkbox' id={this.props.id} name={this.props.value} type='checkbox'  key={this.props.key} onChange={this.props.handleCheckElement} type="checkbox" value={this.props.value}/>
                <h6 className='flex-grow-1'>{this.props.labelCheck? this.props.labelCheck:this.props.value}</h6>
                <h6 id={this.props.id} data-filter={this.props.value} className='solo' onClick={this.props.handleSoloElement}>Solo</h6>
            </div>
        )
    }
}

export default CheckBox
