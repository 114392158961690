import React, {Component} from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import Header from './shared/components/Header';
import {FooterApp} from './shared/components/FooterApp';
import Home from './pages/home/Home';
import Guida from './pages/guida/Guida';
import {Faq} from './pages/faq/Faq';
import {Privacy} from './pages/privacy/Privacy';
import Search from './pages/search/Search';
import SearchDetail from './pages/dettaglio_portafoglio/SearchDetail';
import {Provider} from 'react-redux'
import './App.css';
import ViewCarrello from "./shared/views/ViewCarrello";
import ViewPrefsPage from "./shared/views/ViewPrefsPage";
import DettaglioPosizione from "./pages/posizione/DettaglioPosizione";
import ViewMessages from "./shared/views/ViewMessages";
import ViewConversations from "./shared/views/ViewConversations";
import Question from "./shared/components/Question";
import PublicHome from './pages/areaPubblica/Home';
import Buyer from './pages/areaPubblica/Buyer';
import Seller from './pages/areaPubblica/Seller';
import About from './pages/areaPubblica/About';
import FaqPublic from './pages/areaPubblica/Faq';
import Signup from "./pages/areaPubblica/Signup";
import {ErrorPage} from "./components/errorPage/ErrorPage"

const store = require('./reducers').init();

class App extends Component {

    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <div id='marketPlace'>
                        {window.innerWidth <=1024 ? <div id='over'></div> : ''}
                        <Header/>
                        <div id='body' className='container'>
                            {/*<Route exact path='/' component={PublicHome}/>*/}
                            <Route exact path='/' component={PublicHome}/>
                            <Route exact path='/signup' component={Signup}/>
                            <Route exact path='/for-buyer' component={Buyer}/>
                            <Route exact path='/for-seller' component={Seller}/>
                            <Route exact path='/about' component={About}/>
                            <Route exact path='/faq' component={FaqPublic}/>
                            <Route exact path='/home' component={Home}/>
                            <Route exact path='/site-guide' component={Guida}/>
                            <Route exact path='/messages' component={ViewMessages}/>
                            <Route exact path='/faq' component={Faq}/>
                            <Route exact path='/privacy' component={Privacy}/>
                            <Route exact path='/indications-of-interest' component={ViewCarrello}/>
                            <Route exact path='/favorites' component={ViewPrefsPage}/>
                            <Route exact path='/position/:id' component={DettaglioPosizione}/>
                            <Route path='/search' component={Search}/>
                            <Route path='/portfolio-detail/:id' component={SearchDetail}/>
                            <Route path="/messages/details/:id" component={ViewConversations}/>
                            <Route path="/errorPage" component={ErrorPage}/>
                            {window.innerWidth > 500 ? <Question/> : ''}
                        </div>
                        <FooterApp/>
                    </div>
                </BrowserRouter>
            </Provider>
        );
    }
}

export default App;
