import React ,{Component} from "react";
import {Link} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import {connect} from "react-redux";
import axios from 'axios';
import {FormErrors} from "./FormErrors";

class Registrazione extends Component{
    constructor(){
        super();
        this.state = {
            nome: '',
            cognome: '',
            azienda: '',
            email: '',
            formErrors: {email:''},
            emailValid: false,
            formValid: false
        }

    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        switch (fieldName) {
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : ' is invalid';
                break;
            default:
                break;
        }
    this.setState({formErrors: fieldValidationErrors, emailValid: emailValid},this.validateForm);
    }

    validateForm() {
        this.setState({formValid: this.state.emailValid});
    }

    onChange = (e) => {
        const name= e.target.name;
        const value = e.target.value;
        this.setState({[name]: value}, () => {this.validateField(name, value)});
    }

    handleSubmit= (e) => {
        e.preventDefault();
        this.confirm();
        const nome = this.state.nome;
        const cognome = this.state.cognome;
        const azienda = this.state.azienda;
        const email = this.state.email;

        const data = {
            nome, cognome, azienda, email
        }

        axios.post('/api-public/subscription/addUser', data)

    };
    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }

    hide = () => this.setState({ open: false, callback: null });

    confirm = () => {
        confirmAlert({

            title: 'Richiesta inviata correttamente',

            message: 'Hai inviato con successo i tuoi dati. Ti contatteremo al più presto (quanto tempo?). Se tutto è ok ti manderemo via e-mail le credenziali per accedere al Markagain.',
            buttons: [

                {
                    label: 'Chiudi',
                    onClick: () => this.hide()
                }
            ]
        });
    };
    submit = () => {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <form id ="form" className=" border border-light p-2" onSubmit={this.handleSubmit.bind(this)}>
                            <div className="text-center">
                                <p className="h4 mb-4">Registrati</p>
                                <p className="h7 mb-4">Lorem Ipsum</p>
                            </div>
                            <label htmlFor="nome">Nome*</label>
                            <input type="nome" name="nome" id="nome"  onChange={this.onChange} required className="form-control mb-4" />
                            <label htmlFor="cognome">Cognome*</label>
                            <input type="cognome" name="cognome" id="cognome"  onChange={this.onChange} required className="form-control mb-4"/>
                            <label htmlFor="azienda">Azienda*</label>
                            <input type="azienda" name="azienda"  id="azienda" onChange={this.onChange} required className="form-control mb-4"/>
                            <label htmlFor="email">Email*</label>
                            <div className={`form-group ${this.errorClass(this.state.formErrors.email)}`}>
                                <input type="email" name="email" id="email" onChange={this.onChange} required className="form-control mb-4"/>
                            </div>
                            <div className="carrelloheader">
                                <button id="buttonann" className="btn btn-light" onClick={onClose} >Annulla</button>
                                <button id="buttoncont" className="btn btn-info btn-block col-5" type="submit" >Conferma</button>
                            </div>
                        </form>
                    </div>
                );
            }
        });
    };


    access(){
        const redirectToLogin = () => {
            if (process.env.NODE_ENV !== 'development') {
                window.location.replace(
                    `${'https://cascoll.lince.it/cas/login?service=http://localhost:3000/home'}`
                )
            } else {
                window.location.replace(
                    `${'https://cascoll.lince.it/cas/login?service=https://collaudo-marketplace.cervedcredit.it/home'}`
                )
            }
        }
        redirectToLogin();
    }

    render() {

        return (
            <nav className='navbar navbar-expand-sm navbar-light bg-light'>
                <Link className='navbar-brand col-md-3 col-aut' to='/'> Cerved Markagain</Link>
                <button className='navbar-toggler' type='button' data-toggle='collapse'
                        data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                        aria-label='Toggle navigation'>
                    <span className='navbar-toggler-icon'></span>
                </button>

                <div className='collapse navbar-collapse col-md-9' id='navbarSupportedContent'>
                    <ul className='navbar-nav mx-auto'>
                        <li className='nav-item'>
                            <Link className='nav-link' to=''>Home</Link>
                        </li>
                        <li className='nav-item'>
                            <Link className='nav-link' to =''>About </Link>
                        </li>
                        <li className='nav-item'>
                            <Link className='nav-link' to='/site-guide'>A chi si rivolge</Link>
                        </li>
                        <li className='nav-item'>
                            <Link className='nav-link' to='/messaggi'>Come funziona</Link>
                        </li>
                        <li className='nav-item'>
                            <Link className='nav-link' to='/notifiche'>Vantaggi</Link>
                        </li>
                    </ul>
                    <ul className='navbar-nav ml-auto'>
                        <li className='nav-item'>
                            <button onClick={this.access} className='nav-link'>Accedi</button>
                        </li>
                        <li className='nav-item'>
                            <button onClick={this.submit} className='nav-link' >Richiedi credenziali</button>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}


export default (Registrazione)
