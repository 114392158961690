import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IChevron } from '../../styles/svg/chevron-right.svg';

class FiltroBoxHome extends React.Component{
    constructor(props){
        super(props)
    }
    initFascie(){

        if (this.props.fascia != null) {
            return this.props.fascia.map((filtro, index) => {
                return <div className={`filtroHome ${this.props.filtro}`}>
                    <Link className='nav-link' style={{textDecoration: 'none', color: 'black'}}
                          to={`/search/portfolios?filtroHome=${filtro.filterValue}-${this.props.filtro}`}>
                        <div className={`card containerFiltroHome ${filtro.filterValue}`}>
                            <h2>{filtro.count}</h2>
                            <h5>{filtro.description}</h5>
                            <span><IChevron/></span>
                        </div>
                    </Link>
                </div>
            });
        }
    }

    render() {
        return(
            <div className='row'>
                {this.initFascie()}
            </div>

        )
    }
}

export default FiltroBoxHome
