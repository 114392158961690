import {
    FETCH_ASSETS_SUCCESS,
    FETCH_ASSETSBYPORTID_SUCCESS,
    FETCH_ASSETSMAP_SUCCESS,
    FETCH_ASSETSMAPSBYPORTID_INIT,
    FETCH_ASSETSMAPSBYPORTID_SUCCESS,
    FETCH_CARRELLO_SUCCESS,
    FETCH_CONVERSAZIONE_SUCCESS,
    FETCH_COUNT_INDICATIONOF_INTEREST,
    FETCH_DATAFILTERED_SUCCESS,
    FETCH_FILTRIPORTVALUES_SUCCESS,
    FETCH_FILTRIPOSVALUES_SUCCESS,
    FETCH_IS_GESTORE,
    FETCH_MESSAGES_PRESENT,
    FETCH_FAVORITES_PRESENT,
    FETCH_MESSAGGIBYID_SUCCESS,
    FETCH_PACK_BY_ID_INIT,
    FETCH_PACK_BY_ID_SUCCESS,
    FETCH_PACK_SUCCESS,
    FETCH_PORTAFOGLIOAREAGEOGRAFICA_SUCCESSO,
    FETCH_PORTAFOGLIOCONTROPARTE_SUCCESS,
    FETCH_PORTAFOGLIODESTINAZIONEUSO_SUCCESSO,
    FETCH_PORTAFOGLIOSTRATS_SUCCESS,
    FETCH_PORTPOSITIONS_SUCCESS,
    FETCH_POSITIONS_SUCCESS,
    FETCH_POSIZIONE_BYID,
    FETCH_PREFERITI_SUCCESS,
    FETCH_SEARCH_SUCCESS,
    FETCH_VISUALIZZATI_SUCCESS, FETCH_POSITIONS, FETCH_ASSETSMAP_INIT, FETCH_ASSISTENZA_SUCCESS,
    ERASE_DATAFILTERED
} from '../actions/type'

const INITIAL_STATE = {
    dataPacks: {
        data: [],
        preferiti: [],
        visualizzati: [],
        carrello: [],
        fetching: false,
        counter: 0,
    },
    dataPos: {
        dataPort: {
            positions: [],
            positionCount: 0,
            fetching: false
        },
        dataDet: {
            positions: [],
            positionCount: 0
        },
        dato: {}
    },
    dataPack: {
        data: {}
    },
    conversazione: {
        data: [],
        messaggi: [],
        assistenza: [],
        isgestore: false
    },
    dataAssets: {
        data: [],
        dataMapPort: {},
        dataMapDet: {}
    },
    dataStrats: {
        data: [],
        datacorp: [],
        datadest: [],
        dataarea: []

    },
    dataFiltri: {
        dataPos: [],
        dataPort: []
    },

    dataHeader: {
        dataInterest: 0,
        dataMessages: null,
        dataFavorites: null
    }
}

export const packReducer = (state = INITIAL_STATE.dataPacks, action) => {
    switch (action.type) {
        case FETCH_PACK_SUCCESS:
            return {...state, data: action.packs, fetching: false};
        case FETCH_SEARCH_SUCCESS:
            return {...state, data: action.packs, fetching: false};
        case FETCH_PREFERITI_SUCCESS:
            return {...state, preferiti: action.preferiti};
        case FETCH_VISUALIZZATI_SUCCESS:
            return {...state, visualizzati: action.visualizzati};
        case FETCH_CARRELLO_SUCCESS:
            return {...state, carrello: action.carrello};
        case FETCH_DATAFILTERED_SUCCESS:
            return {...state, 
                data: action.counter === state.counter ? action.packs : state.data, 
                fetching: action.counter === state.counter ? action.false : state.fetching };
        case ERASE_DATAFILTERED:
            return {...state, data: [], fetching: true, counter: action.counter};
        default:
            return state;
    }
}

export const selectedPackReducer = (state = INITIAL_STATE.dataPack, action) => {
    switch (action.type) {
        case FETCH_PACK_BY_ID_INIT:
            return {...state, data: {}};
        case FETCH_PACK_BY_ID_SUCCESS:
            return {...state, data: action.pack};
        default:
            return state;
    }
}

export const positionReducer = (state = INITIAL_STATE.dataPos, action) => {
    switch (action.type) {
        case FETCH_POSITIONS:
            return {...state, dataPort: { ...state.dataPort, fetching: true }};
        case FETCH_POSITIONS_SUCCESS:
            return {...state, dataPort: { ...action.positions, fetching: false }};
        case  FETCH_PORTPOSITIONS_SUCCESS:
            return {...state, dataDet: action.positions};
        case FETCH_POSIZIONE_BYID:
            return {...state, dato: action.positions};
        default:
            return state;
    }
}
export const conversazioniReducer = (state = INITIAL_STATE.conversazione, action) => {
    switch (action.type) {
        case FETCH_CONVERSAZIONE_SUCCESS:
            return {...state, data: action.conversazione};
        case FETCH_MESSAGGIBYID_SUCCESS:
            return {...state, messaggi: action.messages};
        case FETCH_ASSISTENZA_SUCCESS:
            return {...state, assistenza: action.assistenza};
        case FETCH_IS_GESTORE:
            return {...state, isgestore: action.isgestore};
        default:
            return state;
    }
}

export const assetReducer = (state = INITIAL_STATE.dataAssets, action) => {
    switch (action.type) {
        case FETCH_ASSETSMAP_INIT:
            return {...state, dataMapPort: {}};
        case FETCH_ASSETSMAPSBYPORTID_INIT:
            return {...state, dataMapDet: {}};
        case FETCH_ASSETS_SUCCESS:
            return {...state, data: action.assets};
        case FETCH_ASSETSBYPORTID_SUCCESS:
            return {...state, data: action.assets};
        case FETCH_ASSETSMAP_SUCCESS:
            return {...state, dataMapPort: action.assets};
        case FETCH_ASSETSMAPSBYPORTID_SUCCESS:
            return {...state, dataMapDet: action.assets};
        default:
            return state;
    }
}

export const stratsReducer = (state = INITIAL_STATE.dataStrats, action) => {
    switch (action.type) {
        case FETCH_PORTAFOGLIOSTRATS_SUCCESS:
            return {...state, data: action.strats};
        case FETCH_PORTAFOGLIOCONTROPARTE_SUCCESS:
            return {...state, datacorp: action.corpstrat};
        case FETCH_PORTAFOGLIODESTINAZIONEUSO_SUCCESSO:
            return {...state, datadest: action.destinazione};
        case FETCH_PORTAFOGLIOAREAGEOGRAFICA_SUCCESSO:
            return {...state, dataarea: action.area};
        default:
            return state
    }
}

export const filtriReducer = (state = INITIAL_STATE.dataFiltri, action) => {
    switch (action.type) {
        case FETCH_FILTRIPORTVALUES_SUCCESS:
            return {...state, dataPort: action.filtriValues};
        case FETCH_FILTRIPOSVALUES_SUCCESS:
            return {...state, dataPos: action.filtriValues};
        default:
            return state
    }
}


export const headerReducer = (state = INITIAL_STATE.dataHeader, action) => {
    switch (action.type) {
        case FETCH_COUNT_INDICATIONOF_INTEREST:
            return {...state, dataInterest: action.count};
        case FETCH_MESSAGES_PRESENT:
            return {...state, dataMessages: action.present};
        case FETCH_FAVORITES_PRESENT:
            return {...state, dataFavorites: action.present};
        default:
            return state
    }
}
