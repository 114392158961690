import React from 'react';
import {Link} from "react-router-dom";
import { ReactComponent as ILogin } from '../../styles/svg/external-link-square.svg';

import '../../styles/areaPubblica/buyer.css';
import { Footer } from './Footer';

class Seller extends React.Component{

    componentDidMount() {
        document.getElementById('body').classList.add('big');
        if(window.innerWidth < 500){
            document.getElementById('body').classList.add('public');
        }
        document.getElementById('marketPlace').classList.add('hide');
    }
    componentWillUnmount() {
        document.getElementById('body').classList.remove('big');
        if(window.innerWidth < 500){
            document.getElementById('body').classList.remove('public');
        }
        document.getElementById('marketPlace').classList.remove('hide');
    }

    openMenu() {
        document.getElementById('openPublic').classList.toggle('open');
    }

    writeMail() {
        window.location.href = "mailto:"+process.env.REACT_APP_MAILTO;
    }

    render() {
        return(
            <div id='seller'>
                <div className='fasciaHeader'>
                    <div id='headerPublic'>
                        {window.innerWidth > 500?
                            <div className='logo'>
                                <Link to='/'><img src={require('../../styles/images/header.png')}/></Link>
                            </div>
                            :
                            <div className='menuMobile'>
                                <div className='logo'>
                                    <Link to='/'><img src={require('../../styles/images/header.png')}/></Link>
                                </div>
                                <div className='btnMenu' onClick={this.openMenu}>
                                    <img src={require('../../styles/images/openMenu.png')}/>
                                </div>
                            </div>
                        }
                        {window.innerWidth > 500?
                            <div id='openPublic' className='linkHeader'>
                                <Link to='/for-buyer'><h5>For Buyer</h5></Link>
                                <Link to='/for-seller'><h5 className='active'>For Seller</h5></Link>
                                <Link to='/about'><h5>About Us</h5></Link>
                                {/*<Link to='/faq'><h5>FAQ</h5></Link>*/}
                                <a href={process.env.REACT_APP_CAS_LOGIN_URL + window.location.origin + '/home'}>
                                    <div className='login'>
                                        <ILogin width='14px' fill='#008996'/><h5>Log In</h5>
                                    </div>
                                </a>
                                <Link to='/signup'>
                                    <div className='signIn'>
                                        <h5>Sign Up</h5>
                                    </div>
                                </Link>
                            </div>
                            :
                            <div id='openPublic' className='linkHeader'>
                                <div className='linkMenu'>
                                    <Link to='/for-buyer'><h5>For Buyer</h5></Link>
                                    <Link to='/for-seller'><h5 className='active'>For Seller</h5></Link>
                                    <Link to='/about'><h5>About Us</h5></Link>
                                    {/*<Link to='/faq'><h5>FAQ</h5></Link>*/}
                                </div>
                                <div className='buttonMenu'>
                                    <Link to='/home'>
                                        <div className='login'>
                                            <ILogin width='14px' fill='#008996'/><h5>Log In</h5>
                                        </div>
                                    </Link>
                                    <Link to='/signup'>
                                        <div className='signIn'>
                                            <h5>Sign Up</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='description'>
                        <span className='type'>For Seller</span>
                        <span className='title'><b>Maximize the performance</b><br/>of your debt portfolio.</span>
                        {window.innerWidth>500?
                            <img src={require('../../styles/images/seller.png')}/>
                            :
                            <img src={require('../../styles/images/seller_mobile.png')}/>
                        }
                    </div>
                </div>
                <div className='fasciaSearch'>
                    <div className='img'>
                        <img src={require('../../styles/images/tecnology.png')}/>
                    </div>
                    <div className='description'>
                        <div className='question'>
                            Expertise and technology.
                        </div>
                        <div className='testo'>
                            Cerved combines industry expertise with technology to provide you with what it takes to transact sales competitively and simply.
                        </div>
                    </div>
                </div>
                {window.innerWidth>500?
                    <div className='fasciaFast'>
                        <div className='description'>
                            <div className='question'>
                                Based on <b>data quality.</b>
                            </div>
                            <div className='testo'>
                                Being sure that credit quality is dependent on data quality, Cerved could carry out the Data remediation and maintenance of data tape provided by the seller in order to obtain a database containing complete and above all up-to-date information for the proper valuation of portfolios.
                            </div>
                            <div className='testo'>
                                <b>The Data Remediation process</b> is done by a team of specialists using their expertise and automatic systems to validate and enrich information.
                            </div>
                            <div className='testo'>
                                Enrichment is achieved extracting all the information available in the dossier documentation and in Cerved database by using big data technology and semantic engine.
                            </div>
                        </div>
                        <div className='img'>
                            <img src={require('../../styles/images/quality.png')}/>
                        </div>
                    </div>
                    :
                    <div className='fasciaFast'>
                        <div className='img'>
                            <img src={require('../../styles/images/quality.png')}/>
                        </div>
                        <div className='description'>
                            <div className='question'>
                                Based on <b>data quality.</b>
                            </div>
                            <div className='testo'>
                                Being sure that credit quality is dependent on data quality, Cerved could carry out the Data remediation and maintenance of data tape provided by the seller in order to obtain a database containing complete and above all up-to-date information for the proper valuation of portfolios.
                            </div>
                            <div className='testo'>
                                <b>The Data Remediation process</b> is done by a team of specialists using their expertise and automatic systems to validate and enrich information.
                            </div>
                            <div className='testo'>
                                Enrichment is achieved extracting all the information available in the dossier documentation and in Cerved database by using big data technology and semantic engine.
                            </div>
                        </div>
                    </div>
                }
                <div className='fasciaAdvisor'>
                    <div className='img'>
                        <img src={require('../../styles/images/mano.png')}/>
                    </div>
                    <div className='description'>
                        <div className='question'>
                            We <b>care</b> about our <b>users.</b>
                        </div>
                        <div className='testo'>
                            Once the Data Remediation process is complete, Cerved will publish borrowers in clustered portfolios or on a single-name basis.
                        </div>
                        <div className='testo'>
                            Investors can search portfolios and view details of each borrower to make their own assessment and submit indications of interest.
                        </div>
                    </div>
                </div>
                {/* window.innerWidth > 500 ?
                    <div className='fasciaQuestions'>
                        <div className='description'>
                            <div className='question'>
                                <b>Frequently asked questions</b>
                            </div>
                            <div className='testo'>
                                You didn’t find what you were looking for? <b className='highlight'>Read all our FAQ</b>
                            </div>
                        </div>
                        <div class='ask'>
                            <div className='item'>
                                <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                <div className='plus'>
                                    <IPlus fill='#008996' width='16px'/>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                <div className='plus'>
                                    <IPlus fill='#008996' width='16px'/>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                <div className='plus'>
                                    <IPlus fill='#008996' width='16px'/>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='testo'>Lorem ipsum dolor sit amet?</div>
                                <div className='plus'>
                                    <IPlus fill='#008996' width='16px'/>
                                </div>
                            </div>

                        </div>
                    </div> : ''
                */}
                <div className='fasciaReady'>
                    <div className='description'>
                        <span className='title'>Are you ready to start?</span>
                        <span className='subtitle'>Request your login credential, enter your personal area and start browsing over 12 portfolios. </span>
                        <Link to='/signup'><span className='getStart'>Get started!</span></Link>
                    </div>
                </div>
                <div className='fasciaHelp'>
                    <div className='description'>
                        <div className='question'>
                            Do you need help?
                        </div>
                        {window.innerWidth>500?
                            <div className='testo'onClick={this.writeMail}>
                                <span>Write Us an email</span>
                            </div>
                            :
                            <div className='testo' onClick={this.writeMail}>
                                <span>Write Us an email</span>
                            </div>
                        }
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Seller
