import React, { Component } from 'react';
import {ReactComponent as ISearch} from '../../styles/svg/search.svg';
import axios from "axios";

import '../../styles/components/autocopileHashtag.css';
import {Link} from "react-router-dom";

class HashTagModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hashtags: [],
            filterHashTag: []
        }
    }

    componentWillMount() {
        axios.get('/api-mkt/hashtags/findAllHashtags').then(res => {
                this.setState({
                    hashtags: res.data,
                    filterHashTag: res.data
                })
            });
    }

    handlerAutocompile = (e) => {
        e.preventDefault();
        if (e.target.value !== '') {
            let filter = this.state.hashtags.filter(hash => {
                return hash.descrizione.toLowerCase().includes(e.target.value.toLowerCase())
            });
            this.setState({filterHashTag: filter});
        } else {
            this.setState({filterHashTag: this.state.hashtags});
        }

    }

    handlerHashSelected = (e) => {
        e.preventDefault();
        document.getElementsByClassName('itemHash').forEach(item => {
            item.classList.remove('selected');
        });
        e.target.classList.add('selected')
    }

    capitalize = (str) => {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {
        return(
            <div className="autocopileHashtag">
                <div className="headerInput d-flex align-items-center">
                    <div className="input d-flex justify-content-between align-items-center">
                        <input type="text" placeholder="Search Hashtag" onChange={e => this.handlerAutocompile(e)}/>
                        <ISearch fill="#ddd" width="20px"/>
                    </div>
                </div>
                <div className="contentListHash">
                    <div className="listHashTag">
                        {
                            this.state.filterHashTag.map(hash => {
                                return (
                                    <div className="mr-4">
                                        <div onClick={e =>this.handlerHashSelected(e)} className="itemHash">
                                            <Link style={{textDecoration: 'none', color: '#363a40'}}
                                                  to={`/search/portfolios?filtroHome=hashtag-${hash.descrizione}`}>
                                            {this.capitalize(hash.descrizione)}
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default HashTagModal;
