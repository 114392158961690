import React from 'react'
import {confirmAlert} from "react-confirm-alert";
import {ReactComponent as Close} from "../../styles/svg/window-close.svg";
import TextareaAutosize from 'react-autosize-textarea';
import axios from "axios";
import {ReactComponent as Alert} from "../../styles/svg/exclamation-circle.svg";
import * as actions from "../../actions";
import {connect} from "react-redux";

class Question extends React.Component{
    constructor(){
        super();
        this.state={
            argument:'General',
            mess:''
        }
        this.smallAlert = false;
    }

    getScroll(){
        if(document.getElementById('footer')!==null){
            let marketPlace = document.getElementById('marketPlace');
            let question = document.getElementById('question');
            if((this.innerHeight+this.scrollY) > parseInt(document.getElementById('footer').offsetTop)){
                question.classList.remove('fixed');
                // question.style.right = '-50px';
            }else{
                question.classList.add('fixed');
                // question.style.right = (marketPlace.clientWidth -1300)/2+'px';
            }
        }
    }
    onChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState({[name]: value} , ()=> this.submit({[name]: value}));
    };

    hide = () => this.setState({ open: false, callback: null });


    handleSubmit = (e) => {
        e.preventDefault();
        this.confirm();
        axios.post("/api-mkt/messages/requestHelp" , {topic: this.state.argument, message: this.state.mess} );
        this.setState({argument : 'General'});
        this.setState({mess:''});

    };

    confirm = () => {
        confirmAlert({

            title: <div ><div id="image"></div> <br/> Thank you! Your request has been sent.</div>,
            message: 'Our portfolio specialist will take in charge your support request and recontact you as soon as possible. You will find all the information requests in the Messagges section',
            buttons: [

                {
                    label: 'Close',
                    onClick: () => {
                        this.props.dispatch(actions.fetchConversazioni());
                        this.props.dispatch(actions.isGestore());
                        this.props.dispatch(actions.fetchAssistenza());
                        this.hide()
                    }
                }
            ]
        });

    };


    submit = () => {
        this.smallAlert = document.getElementsByTagName('body').item(0).classList.contains('smallAlert')
        if(this.smallAlert){
            document.getElementsByTagName('body').item(0).classList.remove('smallAlert')
        }
        this.setState({argument : this.state.argument});
        confirmAlert({
            closeOnEscape: true,
            closeOnClickOutside: false,
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <p id="headerAlert" className="h4 mb-4">Do you need help? <span id="close" className="h4 mb-4"><Close id="xclose" onClick={onClose} width="14px"/></span></p>

                        <form id="form" onSubmit={this.handleSubmit.bind(this)}>
                            <div id="dataTHelp">
                                <span id="alertIcon"> <Alert fill="#008996" width="24px"/></span>
                                <p id="description">Our portfolio specialist will take in charge your support request and recontact you as soon as possible. You will find all the requests in the Messagges section.<br/> <a target="_blank" id="linkData" href='/site-guide'>Find out more</a></p>

                            </div>
                            <div className="question p-4">
                                <p className="text-center mb-2">Before sending us your request </p>
                                <p className="text-center"><a href='/site-guide'>Visit our Site Guide. </a></p>

                                <label>Topic *</label>
                                <select id="defaultContactFormName" name="argument" onChange={this.onChange} className="form-control mb-4">
                                    <option name="argument" value="General">General</option>
                                    <option name="argument" value="I want to update my profile">I want to update my profile</option>
                                    <option name="argument" value="The website doesn't work" >The website doesn't work</option>
                                </select>

                                <div className="form-group">
                                    <label>Your message *</label>
                                    <TextareaAutosize type="textarea" name="mess" id="defaultContactFormName" className="form-control" onChange={this.onChange} rows="3" placeholder="Start writing..."></TextareaAutosize>
                                </div>
                                <div className="carrelloheader">
                                    <button id="buttonann" className="btn btn-light" onClick={onClose}>Cancel</button>
                                    <button id="buttoncont" disabled={!this.state.mess} className="btn btn-info btn-block col-5" onClick={this.handleSubmit.bind(this)} type="submit">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            }
        });
    };

    componentDidMount() {
        window.addEventListener('scroll', this.getScroll, true);
    }

    componentWillUnmount(): void {
        if(this.smallAlert){
            document.getElementsByTagName('body').item(0).classList.add('smallAlert')
        }
    }

    render(){
        return (
            <div id='question' onClick={this.submit} className='fixed'><span id="exclamation">?</span></div>
        )
    }
}

export default connect() (Question)
